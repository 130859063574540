import { css, StyleSheet } from 'aphrodite'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import Layout from '../../core/components/Layout'
import { useQueryParams } from '../../core/hooks/queryString'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import InfiniteTable from '../../core/components/InfiniteTable'
import ParentsTabs from '../components/ParentsTabs'
import { useParents } from '../hooks/parents'
import ParentItem from '../components/ParentItem'
import ParentCreate from '../components/ParentCreate'
import ParentSearch from '../components/ParentSearch'
import { PermissionDenied } from '../../core/components/PermissionDenied'

export default function Parents() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const parents = useParents({ ...params })

    const [showCreateModal, hideCreateModal] = useModal(
        <ParentCreate onSuccess={(parent) => {
            hideCreateModal()
            parents.addItem(parent)
        }} onCancel={() => hideCreateModal()}/>,
    )

    function onSearch(search?: string) {
        if (!isEmpty(search)) {
            navigate(`?${queryString.stringify({ ...params, search })}`)
            return
        }
        navigate('/academy/parents')
    }

    if (!cp('academy.view_parent')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <ParentsTabs/>

            <div className="columns">
                <div className="column is-4 is-offset-8">
                    <ParentSearch onSearch={onSearch} className={css(styles.search)}/>
                </div>
            </div>

            {cp('academy.add_parent') ? (
                <Button
                    onClick={showCreateModal}
                    text="Создать родител"
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined"/>
            ) : null}
            <div className="is-clearfix"/>

            <br/>

            <InfiniteTable
                responsive
                columns={{
                    name: 'Имя',
                    phone: 'Номер телефона',
                    isRegistered: 'Telegram',
                    actions: '',
                }}
                query={parents}
                renderItem={(item) => (
                    <ParentItem
                        onUpdate={parents.updateItem}
                        onDelete={parents.deleteItem}
                        key={item.id}
                        item={item}/>
                )}/>
        </Layout>
    )
}

const styles = StyleSheet.create({
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
})
