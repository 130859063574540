import { Formik } from 'formik'
import find from 'lodash/find'
import map from 'lodash/map'
import Select from '../../core/components/Select'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import { FormSubmit } from '../../core/types'
import { CustomerType, GroupType, StudentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupsList } from '../hooks/group'

export type Props = {
    initValues?: StudentType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
    customer: CustomerType
    group?: GroupType
}

export type FormType = {
    price?: string
    group: number
}

type SetValue = FormikHelpers<FormType>['setFieldValue']

export default function CustomerInfoGroupsForm({ initValues, onSubmit, onCancel, customer, loading }: Props) {
    const initialValues = { price: '', ...initValues, group: initValues?.group?.id || '' }
    const groups = useGroupsList()
    const groupsIds = map(customer.students, 'group.id')
    const groupOptions = groups.data?.results
        .filter((g) => !groupsIds.includes(g.id) || (initValues?.group?.id === g.id)) || []
    
    function onGroupChange(groupId: string, values: FormType, setFieldValue: SetValue) {
        setFieldValue('group', groupId)
        if (values.price === '') {
            const group = find(groups.data?.results, { id: parseInt(groupId, 10) })
            setFieldValue('price', group?.price || '')
        }
    }

    return (
        <Formik initialValues={initialValues as FormType} enableReinitialize onSubmit={onSubmit}>
            {({ setFieldValue, values, handleSubmit }) => (
                <tr>
                    <td>
                        <Select
                            empty
                            name="group"
                            className="is-small"
                            options={groupOptions}
                            validate={required}
                            onChange={({ target }) => onGroupChange(target.value, values, setFieldValue)}
                            loading={groups.isLoading} />
                    </td>

                    <td>
                        <Input
                            name="price"
                            className="is-small"
                            placeholder="Стоимость месячного абонемента"
                            disabled={loading}
                            validate={required}
                            type="number" />
                    </td>

                    <td>
                        <Button
                            loading={loading}
                            onClick={() => handleSubmit()}
                            icon="ion-md-checkmark"
                            className="is-success is-small" /> &nbsp;

                        <Button
                            icon="ion-md-close"
                            disabled={loading}
                            onClick={onCancel}
                            className="is-small" />
                    </td>
                </tr>
            )}
        </Formik>
    )
}
