import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { GroupType } from '../types'
import { GROUPS_ARCHIVE, GROUPS_DETAIL, GROUPS_LIST, GROUPS_MERGE, GROUPS_START } from '../urls'
import { Pagination } from '../../core/types'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export type GroupParams = {
    search?: string
    stoppedAt?: boolean
}

export function useGroupsList() {
    return useLoad<Pagination<GroupType>>({ url: GROUPS_LIST })
}

export function useGroups({ search, stoppedAt }: GroupParams) {
    return useInfiniteFetch<GroupType>(
        ['groups', { search, stoppedAt }],
        ({ pageParam }) => request({ url: GROUPS_LIST, params: { page: pageParam, search, stoppedAt } }),
    )
}

export function useGroup(id: number | string) {
    return useLoad<GroupType>({ url: GROUPS_DETAIL.replace('{id}', String(id)) })
}

export function useGroupStart(id: number) {
    return usePutRequest<GroupType>({ url: GROUPS_START.replace('{id}', String(id)) })
}

export function useGroupUpdate(id: number) {
    const url = GROUPS_DETAIL.replace('{id}', String(id))
    return usePutRequest<GroupType>({ url })
}

export function useGroupCreate() {
    return usePostRequest<GroupType>({ url: GROUPS_LIST })
}

export function useGroupDelete(id: number) {
    return useDeleteRequest({ url: GROUPS_DETAIL.replace('{id}', String(id)) })
}

export function useGroupArchive(id: number) {
    return usePutRequest<GroupType>({ url: GROUPS_ARCHIVE.replace('{id}', String(id)) })
}

export function useGroupMerge() {
    return usePostRequest({ url: GROUPS_MERGE })
}
