import { cn } from '../../core/utils/classname'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useLocation } from 'react-router-dom'
import moment from 'moment'
import { checkPermission as cp } from '../../users/utils/auth'
import { GroupType } from '../types'

export type Props = {
    group: GroupType
}

export default function GroupTabs({ group }: Props) {
    const { pathname } = useLocation()

    return (
        <div className="tabs">
            <ul>
                <li>
                    <NavLink className="button is-white" to="/academy/group">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </NavLink>
                </li>

                <li className={cn({ 'is-active': pathname.endsWith('/students-list') })}>
                    <NavLink to={`/academy/group/${group.id}/students-list`}>
                        {/* <i className="icon ion-md-people" /> */}
                        Студенты "{group ? group.name : ''}"
                    </NavLink>
                </li>

                {cp('academy.view_visit') ? (
                    <li className={cn({ 'is-active': pathname.endsWith('/lessons') })}>
                        <NavLink to={`/academy/group/${group.id}/lessons`}>
                            {/* <i className="icon ion-md-walk" /> */}
                            Посещаемость
                        </NavLink>
                    </li>
                ) : null}

                <li className={cn(css(styles.date), 'has-text-bold')}>
                    {group && group.startsAt ? moment(group.startsAt).calendar() : ''}
                </li>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    date: {
        marginLeft: 'auto',
    },
})
