/* eslint-disable no-nested-ternary */
import { useMessage } from '../../core/hooks/message'
import { checkPermission as cp } from '../../users/utils/auth'
import { useModal } from '../../core/hooks/modal'
import Loader from '../../core/components/Loader'
import CourseUpdate from './CourseUpdate'
import { CourseType } from '../../academy/types'
import { useCourseDelete } from '../../academy/hooks/course'

export type Props = {
    course: CourseType
    onDelete: () => void
    onUpdate: () => void
}

export default function CourseItem({ course, onDelete, onUpdate }: Props) {
    const courseDelete = useCourseDelete(course.id)
    const [showMessage] = useMessage()

    async function deleteCourse() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await courseDelete.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <CourseUpdate course={course} onSuccess={async () => {
            await onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td style={{ color: course.color }}>
                {course.name}
            </td>

            <td>
                <div className="is-pulled-right">
                    &nbsp; &nbsp; &nbsp;
                    {cp('academy.delete_course') ? (
                        !courseDelete.isLoading ? (
                            <i onClick={deleteCourse} className="icon pointer ion-md-trash" />
                        ) : <Loader className="icon" />
                    ) : null}

                    {cp('academy.change_course') ? (
                        <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    ) : null}
                </div>
            </td>
        </tr>
    )
}
