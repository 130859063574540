import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import IconPicker from '../../core/components/IconPicker'
import { FormSubmit } from '../../core/types'
import { WalletType } from '../../finance/types'

export type Props = {
    values?: WalletType
    onSubmit: FormSubmit<WalletType>
    loading?: boolean
    onCancel: () => void
}

export default function WalletForm({ onCancel, onSubmit, values, loading }: Props) {
    return (
        <Formik initialValues={{ name: '', icon: '', ...values } as WalletType} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    label="Имя счёта"
                    validate={required}
                    placeholder="Введите имя счёта" />

                <IconPicker name="icon" validate={required} />
                <br />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
