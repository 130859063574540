import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import ServerError from '../../core/components/ServerError'
import { email, phone, required, validator } from '../../core/utils/validators'
import Password from '../../core/components/Password'
import LayoutAuth from '../components/LayoutAuth'
import logoBlue from '../static/logoBlue.svg'
import { SignUpFormType } from '../types'
import { useSignup } from '../hooks/users'

export default function SignUp() {
    const signup = useSignup()
    const navigate = useNavigate()

    async function onSubmit(data: SignUpFormType) {
        const { success } = await signup.mutate({ data })

        if (success) {
            navigate('/email-confirmation-message')
        }
    }

    return (
        <LayoutAuth sidebar={(
            <div>
                <h2 className="is-size-5 has-text-weight-bold">Уже есть аккаунт?</h2>
                <p>Вы уже зарегистрированы в CRM Neo? Войдите в систему и наслаждайтесь</p>

                <br />

                <NavLink to="" className="button is-link is-outlined is-inverted">
                    Войти в систему
                </NavLink>
            </div>
        )}>
            <img src={logoBlue} className={css(styles.img)} alt="Logo" />
            <h2 className="is-size-4 has-text-weight-bold">Зарегистрироваться</h2>
            <br />

            <Formik onSubmit={onSubmit}
                initialValues={{
                    password: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    companyName: '',
                    companyPhone: '',
                }}>
                <Form>
                    <ServerError error={signup.error} />

                    <div className="field is-horisontal">
                        <div className="field-body">
                            <Input name="firstName" label="Имя" validate={required} placeholder="Игорь" />
                            <Input name="lastName" label="Фамилия" validate={required} placeholder="Игорьев" />
                        </div>
                    </div>

                    <Input
                        name="email"
                        label="Электронная почта"
                        validate={validator(required, email)}
                        placeholder="monday@gmail.com" />

                    <div className="field is-horisontal">
                        <div className="field-body">
                            <Input
                                name="companyName"
                                label="Название компании"
                                validate={required}
                                placeholder="Apple Inc." />

                            <Input
                                name="companyPhone"
                                type="phone"
                                label="Телефон"
                                placeholder="998999999999"
                                validate={validator(phone, required)} />
                        </div>
                    </div>

                    <Password name="password" validate={required} placeholder="********" label="Пароль" />

                    <div className="field">
                        <p className="control">
                            <Button
                                loading={signup.isLoading}
                                className="is-link is-medium is-fullwidth"
                                text="Зарегистрироваться"
                                type="submit" />
                        </p>
                    </div>

                    <div className={css(styles.onlyMobile)}>
                        <br />
                        Уже есть аккаунт?
                        <NavLink to="" className={css(styles.isRegistered)}> Войти</NavLink>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}

const styles = StyleSheet.create({
    img: {
        width: '10rem',
    },
    onlyMobile: {
        fontSize: '.9rem',
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    isRegistered: {
        color: '#999',
        ':hover': {
            color: '#0062ff',
        },
    },
})
