import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { RoomType } from '../../academy/types'
import { BulmaColors } from '../../core/utils/colors'

export type Props = {
    initialValues?: RoomType
    onSubmit: FormSubmit<RoomType>
    loading?: boolean
    onCancel: () => void
}

export default function RoomForm({ initialValues, onSubmit, loading, onCancel }: Props) {
    return (
        <Formik initialValues={{ name: '', color: '', ...initialValues } as RoomType} onSubmit={onSubmit}>
            <Form>
                <Input
                    label="Имя"
                    name="name"
                    disabled={loading}
                    placeholder="Введите имя кабинета"
                    validate={required} />

                <Select
                    name="color"
                    optionValue="key"
                    options={BulmaColors}
                    label="Цвет"
                    empty />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
