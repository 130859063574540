import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { CourseType } from '../types'
import { COURSE_DETAIL, COURSES_LIST } from '../urls'

export type CoursesParams = {
    withCustomers?: boolean
}

export function useCourses({ withCustomers = false }: CoursesParams = {}, manualLoad = false) {
    return useLoad<Pagination<CourseType>>({
        url: COURSES_LIST,
        params: { withCustomers },
    }, [withCustomers], { manualLoad })
}

export function useCourseDelete(id: number) {
    return useDeleteRequest({ url: COURSE_DETAIL.replace('{id}', String(id)) })
}

export function useCourseUpdate(id: number) {
    return usePutRequest({ url: COURSE_DETAIL.replace('{id}', String(id)) })
}

export function useCourseCreate() {
    return usePostRequest({ url: COURSES_LIST })
}
