import { Fragment, useState } from 'react'
import CustomerInfoGroupsForm, { FormType } from './CustomerInfoGroupsForm'
import CustomerInfoGroupsItem from './CustomerInfoGroupsItem'
import { CustomerType } from '../types'
import { useStudentCreate } from '../hooks/student'

export type Props = {
    customer: CustomerType
    onDelete: (customerId: number, customer: CustomerType) => void
    onUpdate: (customerId: number, customer: CustomerType) => void
}

export default function CustomerInfoGroups({ customer, onUpdate, onDelete }: Props) {
    const [showForm, setShowForm] = useState(false)
    const createStudent = useStudentCreate()

    async function onCreate(values: FormType) {
        const { data, success } = await createStudent.mutate({ data: { ...values, customer: customer.id } })
        if (success && data) {
            customer.students?.push(data)
            onUpdate(customer.id, customer)
        }
    }

    return (
        <Fragment>
            <span className="is-size-5">Группы</span>

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr />

                    {customer.students?.map((item) => (
                        <CustomerInfoGroupsItem
                            item={item}
                            key={item.id}
                            customer={customer}
                            onUpdate={onUpdate}
                            onDelete={onDelete} />
                    ))}

                    {showForm ? (
                        <CustomerInfoGroupsForm
                            loading={createStudent.isLoading}
                            customer={customer}
                            onCancel={() => setShowForm(false)}
                            onSubmit={onCreate} />
                    ) : (
                        <tr>
                            <td colSpan={3}>
                                <a onClick={() => setShowForm(true)}>
                                    <i className="icon ion-md-add" />
                                    Добавить
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    )
}
