/* eslint-disable no-nested-ternary */
import moment from 'moment'
import { checkPermission as cp } from '../../users/utils/auth'
import { useModal } from '../../core/hooks/modal'
import Loader from '../../core/components/Loader'
import { format } from '../../core/utils/number'
import TransactionUpdate from './TransactionUpdate'
import { TransactionType } from '../types'
import { useTransactionDelete } from '../hooks/transaction'

export type Props = {
    walletId: string | number
    transaction: TransactionType
    onDelete: (id: number) => void
    onUpdate: (item: TransactionType) => void
}

export default function TransactionItem({ transaction, walletId, onDelete, onUpdate }: Props) {
    const transactionDelete = useTransactionDelete(transaction.id)

    async function deleteTransaction() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await transactionDelete.mutate()
            onDelete(transaction.id)
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TransactionUpdate
            walletId={walletId}
            transaction={transaction}
            onCancel={() => hideUpdateModal()}
            onSuccess={(item) => {
                onUpdate(item)
                hideUpdateModal()
            }} />,
    )

    return (
        <tr>
            <td data-label="№">{transaction.id}</td>
            <td data-label="Описание">
                {transaction.description || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td data-label="Сумма">
                <b className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                    {format(transaction.amount) || <span className="is-italic has-text-grey">не указан</span>}
                </b>
            </td>

            <td data-label="Кем создана">
                {transaction.createdBy?.firstName
                    || transaction.createdBy?.email
                    || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td data-label="Дата">
                {moment(transaction.createdAt).calendar()
                || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td data-label="Действия">
                {cp('finance.delete_transaction') ? (
                    !transactionDelete.isLoading ? (
                        <i onClick={() => deleteTransaction()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_transaction') ? (
                    <i onClick={() => showUpdateModal()} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
