import { LoadReturns,
    useDeleteRequest,
    useLoad,
    usePostRequest,
    usePutRequest } from '../../core/hooks/request.legacy'
import { ParentTelegramInvitationType, ParentType, SimpleStudentType } from '../types'
import { PARENT_STUDENT_DETAIL, PARENT_DETAIL, PARENT_LIST, PARENT_STUDENT_LIST, STUDENT_LIST } from '../urls'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'
import { TELEGRAM_INVITATION } from '../../staff/urls'

export type ParentParams = {
    search?: string
}

export function useParents({ search }: ParentParams) {
    return useInfiniteFetch<ParentType>(
        ['parent', { search }],
        ({ pageParam }) => request({ url: PARENT_LIST, params: { page: pageParam, search } }),
    )
}

export function useParentCreate() {
    return usePostRequest<ParentType>({ url: PARENT_LIST })
}

export function useParentUpdate(id: number) {
    const url = PARENT_DETAIL.replace('{id}', String(id))
    return usePutRequest<ParentType>({ url })
}

export function useParentDelete(id: number) {
    return useDeleteRequest({ url: PARENT_DETAIL.replace('{id}', String(id)) })
}

export function useParentDetail<R>(id: number):LoadReturns<R> {
    return useLoad({ url: PARENT_DETAIL.replace('{id}', String(id)) })
}

export function useParentAddChild() {
    return usePostRequest<SimpleStudentType>({ url: PARENT_STUDENT_LIST })
}

export function useParentChildDelete(id: number) {
    return useDeleteRequest({ url: PARENT_STUDENT_DETAIL.replace('{id}', String(id)) })
}

export function useParentTelegramInvite(id: number) {
    return usePostRequest<ParentTelegramInvitationType>({
        url: TELEGRAM_INVITATION.replace('{id}', String(id)),
    })
}

export function loadChildren(search: string) {
    return request({
        url: STUDENT_LIST,
        params: { search },
    })
}
