import ProtectedRoute from '../core/components/ProtectedRoute'
import { Route, Routes } from 'react-router-dom'
import Wallet from './pages/Wallet'
import Transaction from './pages/Transaction'
import NotFound from '../core/pages/NotFound'

export default function FinanceRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="wallet" element={<Wallet/>} />
                <Route path="wallet/:walletId/transaction" element={<Transaction/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
