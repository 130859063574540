import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import { FormSubmit } from '../../core/types'

export type Props = {
    onSubmit: FormSubmit<FormType>
    loading?: boolean
}

export type FormType = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
}

export default function UserForm({ onSubmit, loading }: Props) {
    return (
        <Formik initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="oldPassword"
                    type="password"
                    label="Старый пароль"
                    placeholder="Введите старый пароль"
                    disabled={loading}
                    validate={required} />

                <Input
                    name="newPassword"
                    type="password"
                    label="Новый пароль"
                    placeholder="Введите новый пароль"
                    disabled={loading}
                    validate={required} />

                <Input
                    name="confirmPassword"
                    type="password"
                    label="Новый пароль ещё раз"
                    placeholder="Введите новый пароль ещё раз"
                    disabled={loading}
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
