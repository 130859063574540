import LessonForm, { FormType } from './LessonForm'
import ServerError from '../../core/components/ServerError'
import { GroupType, StudentType } from '../types'
import { useLessonCreate } from '../hooks/lesson'

export type Props = {
    group: GroupType
    students: StudentType[]
    onSuccess: () => void
}

export default function LessonCreate({ group, students, onSuccess }: Props) {
    const lessonCreate = useLessonCreate()

    async function onSubmit(data: FormType, activeStudents: StudentType[]) {
        const visits = activeStudents.map((item) => ({
            student: item.id,
            isVisited: data.visits.includes(String(item.id)),
        }))

        const { success } = await lessonCreate.mutate({ data: { ...data, group: group.id, visits } })
        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Создать урок</b><br /><br />
            <ServerError error={lessonCreate.error} />
            <LessonForm students={students} group={group} onSubmit={onSubmit} loading={lessonCreate.isLoading} />
        </div>
    )
}
