import { cn } from '../../core/utils/classname'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import { useModal } from '../../core/hooks/modal'
import CustomerActions from './CustomerActions'
import CustomerUpdate from './CustomerUpdate'
import CustomerInfo from './CustomerInfo'
import { tags } from './CustomerType'
import CustomerGroups from './CustomerGroups'
import { CustomerType } from '../types'

export type Props = {
    index: number
    customer: CustomerType
    onDelete: (customerId: number) => void
    onUpdate: (customerId: number, customer: CustomerType) => void
    showLabels: boolean
}

export default function CustomerBoardCard({
    customer,
    index,
    onDelete,
    onUpdate,
    showLabels,
}: Props) {
    const [showUpdateModal, hideUpdateModal] = useModal(
        <CustomerUpdate
            customer={customer}
            onCancel={() => hideUpdateModal()}
            onSuccess={(values) => {
                onUpdate(customer.id, values)
                hideUpdateModal()
            }} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <CustomerInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            customer={customer}
            onUpdate={(newCustomerId, newCustomer) => {
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }}
            onDelete={(newCustomerId, newCustomer) => {
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }} />,
    )

    return (
        <Draggable draggableId={String(customer.id)} index={index}>
            {(provider, snap) => (
                <div
                    onClick={showInfoModal}
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    className={cn('box', css(styles.card, snap.isDragging && styles.active))}
                    style={provider.draggableProps.style}>
                    <p className={css(styles.nameCustomer)}>
                        {customer.name}
                    </p>

                    <CustomerActions
                        customer={customer}
                        onClickUpdate={showUpdateModal}
                        className={cn('is-pulled-right', css(styles.actions))}
                        onTypeChanged={onUpdate}
                        onDelete={onDelete} />

                    {customer.course && customer.course.name ? (
                        <p className={cn('tag mb-1', css(styles.course))}
                            style={{ backgroundColor: customer.course.color }}>
                            {customer.course.name}
                        </p>
                    ) : null}

                    <CustomerGroups customer={customer} />
                    {showLabels ? tags[customer.type] : null}
                </div>
            )}
        </Draggable>
    )
}

const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
    },
    nameCustomer: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    actions: {
        marginTop: '-2rem',
    },
    active: {
        // drugging styles goes here
    },
    course: {
        color: 'white',
    },
})
