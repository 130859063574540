import { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Formik, Form } from 'formik'
import { required, email } from '../../core/utils/validators'
import { checkPermission as cp } from '../../users/utils/auth'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import Select from '../../core/components/Select'
import Phone from '../../core/components/Phone'
import { FormSubmit } from '../../core/types'
import { FormikHelpers } from 'formik/dist/types'
import { useSalesChannel } from '../hooks/salesChannel'
import { useEmployeesSimple } from '../../staff/hooks/employee'
import { useCourses } from '../hooks/course'

export type Props = {
    values?: FormType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}


export type FormType = {
    name: string
    phone: string
    phoneSecond: string
    advert: string | number
    course: string | number
    birthday?: string | null
    seller: string | number
    email: string
    description: string
}


export default function CustomerForm({ loading, onSubmit, onCancel, values }: Props) {
    const [advanced, setAdvanced] = useState(false)
    const salesChannel = useSalesChannel()
    const employee = useEmployeesSimple()
    const course = useCourses()

    const description = values && values.description ? values.description : ''
    const seller = values && values.seller ? values.seller : ''
    const initialValues = {
        name: '',
        phone: '',
        phoneSecond: '',
        advert: '',
        course: '',
        birthday: '',
        ...values,
        email: values && values.email ? values.email : '',
        seller,
        description,
    } as FormType

    function handleSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        return onSubmit({ ...data, birthday: data.birthday || null }, actions)
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            <Form>
                <Input name="name" label="Имя" validate={required} autoFocus autoComplete="off" />

                <Select
                    empty
                    name="advert"
                    options={salesChannel.data?.results || []}
                    label="Канал продаж"
                    help="Добавить канал продаж можно в настройках"
                    loading={salesChannel.isLoading}
                    validate={required} />

                <Phone />

                <Input
                    optional
                    name="email"
                    type="email"
                    label="Емайл адрес"
                    validate={email} />

                <Input name="description" component="textarea" label="Примечание" optional />

                <Select
                    name="course"
                    options={course.data?.results || []}
                    label="Курс"
                    help="Добавить курс можно в настройках"
                    loading={course.isLoading}
                    empty={!(values && values.course)} />

                <Input
                    optional
                    name="birthday"
                    type="date"
                    label="День рождения" />

                <a onClick={() => setAdvanced(!advanced)}>Расширенная форма</a><br />

                {advanced ? (
                    <div className={css(styles.advanced)}>
                        {cp('academy.add_customer_seller') ? (
                            <Select
                                name="seller"
                                options={employee.data ? employee.data.results : []}
                                label="Менеджер продаж"
                                help="Вы можете добавить нового менеджер продаж в меню персонал"
                                empty={Boolean(values?.seller)}
                                loading={employee.isLoading} />
                        ) : null}
                    </div>
                ) : null}<br />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    advanced: {
        background: 'rgba(251, 248, 248, 1)',
        borderRadius: '.3rem',
        padding: '1rem',
    },
})
