import { cn } from '../../core/utils/classname'
import { NavLink, useLocation } from 'react-router-dom'
import { checkPermission as cp } from '../../users/utils/auth'

export default function ParentsTabs() {
    const { pathname } = useLocation()

    return (
        <div className="tabs">
            <ul>
                {cp('academy.view_parent') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/parents') })}>
                        <NavLink to="/academy/parents">Родители</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
