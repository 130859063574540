import { Form, Formik } from 'formik'
import moment from 'moment'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import { formatDate } from '../../core/utils/date'
import { GroupType } from '../types'
import { useGroupStart } from '../hooks/group'

export type Props = {
    onSuccess: (startsAt: string) => void
    onCancel: () => void
    group: GroupType
}

export default function GroupStartForm({ group, onCancel, onSuccess }: Props) {
    const groupStart = useGroupStart(group.id)
    const values = { ...group, startsAt: group.startsAt || moment(new Date()).format('YYYY-MM-DD') }

    async function onSubmit(data: GroupType) {
        const message = `Вы точно хотите запустит группу (${formatDate(data.startsAt)})?`
        if (!global.confirm(message)) return
        if (!global.confirm(`Ты же не читал предыдущее сообщение 😐! Там было написано "${message}"`)) return
        if (!global.confirm('Убедись что ввел дату правильно, иначе потом'
            + ' ты не сможещь изменить, придется беспокоить Саидравшан ака😄!!')) return

        const response = await groupStart.mutate({ data })

        if (response.success) {
            onSuccess(response.data?.startsAt)
        }
    }

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="startsAt"
                    label="Дата начала"
                    help="Выберити день начала группы"
                    validate={required}
                    type="date" />

                <Button
                    loading={groupStart.isLoading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
