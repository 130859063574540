import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { AdvertType } from '../types'
import { SALES_CHANNEL_DETAIL, SALES_CHANNEL_LIST } from '../urls'

export type SalesChannelParams = {
    isArchive?: boolean
}

export function useSalesChannel({ isArchive }: SalesChannelParams  = {}, manual = false) {
    return useLoad<Pagination<AdvertType>>({
        url: SALES_CHANNEL_LIST,
        params: { isArchive },
    }, [isArchive], { manualLoad: manual })
}

export function useSalesChannelDelete(id: number) {
    return useDeleteRequest({ url: SALES_CHANNEL_DETAIL.replace('{id}', String(id)) })
}

export function useSalesChannelCreate() {
    return usePostRequest({ url: SALES_CHANNEL_LIST })
}

export function useSalesChannelUpdate(id: number) {
    return usePutRequest({ url: SALES_CHANNEL_DETAIL.replace('{id}', String(id)) })
}

export function useSalesChannelMainUpdate() {
    return usePutRequest()
}

