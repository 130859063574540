import { css, StyleSheet } from 'aphrodite'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import Layout from '../../core/components/Layout'
import { useQueryParams } from '../../core/hooks/queryString'
import GroupItem from '../components/GroupItem'
import Button from '../../core/components/Button'
import GroupCreate from '../components/GroupCreate'
import { useModal } from '../../core/hooks/modal'
import AcademyTabs from '../components/AcademyTabs'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import GroupSearch from '../components/GroupSearch'
import { useGroups } from '../hooks/group'
import InfiniteTable from '../../core/components/InfiniteTable'

export default function Group() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const groups = useGroups({ ...params, stoppedAt: false })

    const [showCreateModal, hideCreateModal] = useModal(
        <GroupCreate onSuccess={(group) => {
            hideCreateModal()
            groups.addItem(group)
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('academy.view_group')) {
        return <PermissionDenied />
    }

    function onSearch(search?: string) {
        if (!isEmpty(search)) {
            navigate(`?${queryString.stringify({ ...params, search })}`)
            return
        }
        navigate('/academy/group')
    }

    return (
        <Layout>
            <AcademyTabs />

            <div className="columns">
                <div className="column is-4 is-offset-8">
                    <GroupSearch onSearch={onSearch} className={css(styles.search)} />
                </div>
            </div>

            {cp('academy.add_group') ? (
                <Button
                    onClick={showCreateModal}
                    text="Создать Группу"
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined" />
            ) : null}
            <div className="is-clearfix" />

            <br />

            <InfiniteTable
                responsive
                columns={{
                    name: 'Название',
                    timelines: 'Расписание занятий',
                    acceptedStudentsCount: 'Студенты',
                    teacher: 'Учитель',
                    room: 'Кабинет',
                    actions: '',
                }}
                query={groups}
                renderItem={(item) => (
                    <GroupItem
                        key={item.id}
                        onMerge={groups.refetch}
                        onUpdate={groups.updateItem}
                        onDelete={groups.deleteItem}
                        item={item} />
                )} />
        </Layout>
    )
}

const styles = StyleSheet.create({
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
})
