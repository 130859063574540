import ProtectedRoute from '../core/components/ProtectedRoute'
import NotFound from '../core/pages/NotFound'
import { Route, Routes } from 'react-router-dom'
import Employee from './pages/Employee'
import EmployeeDetail from './pages/EmployeeDetail'

export default function StaffRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="employee" element={<Employee/>} />
                <Route path="employee/:employeeId" element={<EmployeeDetail/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
