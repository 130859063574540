import { useLoad } from '../../core/hooks/request.legacy'
import { TransactionCategoryType } from '../../finance/types'
import { AdvertType, StatusType } from '../../academy/types'
import { FinanceStatisticsType, StudentStatisticsType } from '../types'
import { STATISTICS_COST_ADVERT,
    STATISTICS_ENDS_STUDENT, STATISTICS_FINANCE,
    STATISTICS_OUTCOME,
    STATISTICS_SALES_CHANNEL,
    STATISTICS_SALES_FUNNEL } from '../urls'

export function useFinanceStats() {
    return useLoad<FinanceStatisticsType[]>({ url: STATISTICS_FINANCE })
}

export function useOutcomeStats(startDate: string, endDate: string) {
    return useLoad<TransactionCategoryType[]>({
        url: STATISTICS_OUTCOME,
        params: { startDate, endDate },
    }, [startDate, endDate])
}

export function useCostAdvertStats(startDate: string, endDate: string) {
    return useLoad<AdvertType[]>({
        url: STATISTICS_COST_ADVERT,
        params: { startDate, endDate },
    }, [startDate, endDate])
}

export function useSalesChannelStats(startDate: string, endDate: string) {
    return useLoad<AdvertType[]>({
        url: STATISTICS_SALES_CHANNEL,
        params: { startDate, endDate },
    }, [startDate, endDate])
}

export function useSalesFunnelStats(startDate: string, endDate: string) {
    return useLoad<StatusType[]>({
        url: STATISTICS_SALES_FUNNEL,
        params: { startDate, endDate },
    }, [startDate, endDate])
}

export function useEndStudentsStats(startDate: string, endDate: string) {
    return useLoad<StudentStatisticsType[]>({
        url: STATISTICS_ENDS_STUDENT,
        params: { startDate, endDate },
    }, [startDate, endDate])
}
