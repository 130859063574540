import { ParentType, SimpleStudentType } from '../types'
import { checkPermission as cp } from '../../users/utils/auth'
import { LoadReturns } from '../../core/hooks/request.legacy'
import { useMessage } from '../../core/hooks/message'
import { useParentChildDelete } from '../hooks/parents'

export type Props = {
    item: SimpleStudentType
    parentDetail: LoadReturns<ParentType>
}

export default function ParentAddChildItem({ item, parentDetail }: Props) {
    const [showMessage] = useMessage()
    const parentChildrenDelete = useParentChildDelete(item.id)

    async function deleteParentChildren() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await parentChildrenDelete.mutate()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        await parentDetail.refetch()
    }

    return (
        <tr>
            <td>{item.student.customer.name}</td>
            <td>{item.student.group === null ? 'Не добавлен в Группу' : item.student.group.name}</td>
            {cp('academy.delete_parentstudent') ? (
                <td>
                    <i className="icon pointer ion-md-trash" onClick={deleteParentChildren}/>
                </td>
            ) : null}
        </tr>
    )
}
