import map from 'lodash/map'
import { getMonth } from '../../core/utils/date'
import Chart from '../../core/components/Chart'
import { StudentStatisticsType } from '../types'

export type Props = {
    data: StudentStatisticsType[]
    loading: boolean
}

export default function StatisticsAcademyStudentLine({ data, loading }: Props) {
    return (
        <div>
            <Chart
                loading={loading}
                type="bar"
                height="25rem"
                data={{
                    labels: data ? data.map((item) => getMonth(item.month)) : [],
                    datasets: [{
                        data: data ? map(data, 'finishCount') : [],
                        backgroundColor: '#48c774',
                        fill: false,
                        borderColor: '#48c774',
                        label: 'Закончили',
                    }, {
                        data: data ? map(data, 'leaveCount') : [],
                        backgroundColor: '#f14668',
                        fill: false,
                        borderColor: '#f14668',
                        label: 'Ушли',
                    }],
                }}
                options={{
                    legend: { position: 'bottom' },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            font: { weight: 'bold' },
                        },
                    },
                }} />
        </div>
    )
}
