import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { GroupType } from '../types'
import { useEmployeesSimple } from '../../staff/hooks/employee'
import { useRooms } from '../hooks/room'

export type Props = {
    initialValues?: FormType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}

export type FormType = GroupType & {
    teacher: string | number
    room: string | number
}

export default function GroupForm({ onSubmit, onCancel, loading, initialValues }: Props) {
    const employee = useEmployeesSimple({ position: 'teacher' })
    const room = useRooms()

    const values = {
        name: '',
        price: '',
        teacher: '',
        paymentDay: '',
        teacherShare: 20,
        ...initialValues,
    } as FormType

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    disabled={loading}
                    validate={required}
                    label="Название"
                    placeholder="Введите имя группы" />

                <Select
                    name="teacher"
                    options={employee.data ? employee.data.results : []}
                    label="Учитель"
                    loading={employee.isLoading}
                    help="Вы можете добавить нового учителя в меню персонал"
                    validate={required} />

                <Select
                    name="room"
                    options={room.data ? room.data.results : []}
                    label="Кабинет"
                    loading={room.isLoading}
                    help="Вы можете добавить новый кабинет в настройках"
                    validate={required} />

                <Input
                    name="price"
                    disabled={loading}
                    validate={required}
                    type="number"
                    label="Стоимость абонемента"
                    placeholder="Введите сумму оплаты" />

                <Input
                    name="teacherShare"
                    disabled={loading}
                    validate={required}
                    type="number"
                    label="Доля учителя"
                    placeholder="20" />

                <Input
                    name="paymentDay"
                    label="День оплаты"
                    help="Выберити день оплаты студента"
                    validate={required}
                    type="date" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
