import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { CashbackType } from '../types'
import { useWallets } from '../../finance/hooks/wallet'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { checkPermission, checkPermission as cp  } from '../../users/utils/auth'

export type Props = {
    initialValues?: FormType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
    cashback: CashbackType
}

export type FormType = CashbackType & {
    cashback: string | number
    wallet: string | number
}

export default function CashbackForm({ onCancel, onSubmit, loading, initialValues, cashback }: Props) {
    const wallets = useWallets()

    if (!checkPermission('finance.view_transaction')) {
        return <PermissionDenied />
    }

    const values = {
        outcomeCashback: cashback.id,
        wallet: '',
        ...initialValues,
    } as FormType

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                {cp('finance.view_wallet') ? (
                    <Select
                        name="wallet"
                        options={wallets.data ? wallets.data.results : []}
                        label="Счета"
                        loading={wallets.isLoading}
                        help="Выберите счет"
                        validate={required} />
                ) : null}   

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
