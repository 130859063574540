import { cn } from '../../core/utils/classname'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useQueryParams } from '../../core/hooks/queryString'

export default function TaskFilter() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const params = useQueryParams()

    async function setUrl(key: string, value: string) {
        const val = params[key] !== String(value) ? value : undefined
        navigate(`?${queryString.stringify({ ...params, [key]: val })}`)
    }

    return (
        <div>
            <span className="tag is-info is-rounded pointer" onClick={() => navigate('/task')}>
                {isEmpty(params) ? <i className={cn('icon ion-md-checkmark', css(styles.checkmark))} /> : null}
                Все Задачи
            </span>&nbsp;

            <span className="tag is-primary is-rounded pointer" onClick={() => setUrl('assigned', user.id)}>
                {Number(params.assigned) === user.id ? (
                    <i className={cn('icon ion-md-checkmark', css(styles.checkmark))} />
                ) : null}

                Мои задачи
            </span>&nbsp;

            <span className="tag is-success is-rounded pointer"
                onClick={() => setUrl('dueTime', moment().format(moment.HTML5_FMT.DATE))}>
                {params.dueTime === moment().format(moment.HTML5_FMT.DATE) ? (
                    <i className={cn('icon ion-md-checkmark', css(styles.checkmark))} />
                ) : null}

                Сегодняшние задачи
            </span>&nbsp;

            <span className="tag is-success is-rounded pointer" onClick={() => setUrl('status', 'complete')}>
                {params.status === 'complete' ? (
                    <i className={cn('icon ion-md-checkmark', css(styles.checkmark))} />
                ) : null}

                Выполненные
            </span>&nbsp;

            <span className="tag is-warning is-rounded pointer" onClick={() => setUrl('status', 'waiting')}>
                {params.status === 'waiting' ? (
                    <i className={cn('icon ion-md-checkmark', css(styles.checkmark))} />
                ) : null}

                Невыполненные
            </span>
        </div>
    )
}

const styles = StyleSheet.create({
    checkmark: {
        marginRight: '3px',
    },
})
