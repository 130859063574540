import ParentForm from './ParentForm'
import { FormikHelpers } from 'formik/dist/types'
import { useParentCreate } from '../hooks/parents'
import { ParentType } from '../types'
import { useMessage } from '../../core/hooks/message'

export type Props = {
    onSuccess: (parent: ParentType) => void
    onCancel: () => void
}

export default function ParentCreate({ onCancel, onSuccess }: Props) {
    const parentCreate = useParentCreate()
    const [showMessage] = useMessage()

    async function onSubmit(data: ParentType, actions: FormikHelpers<ParentType>) {
        if (data.phone[0] === '+') data.phone = data.phone.slice(data.phone.lastIndexOf('+') + 1)
        const response = await parentCreate.mutate({ data })
        actions.resetForm()

        if (response.success) {
            onSuccess(response.data)
        }
        if (response.error) {
            for (let err in response.error.data) {
                showMessage(response.error.data[err], 'is-danger')
            }
        }
    }

    return (
        <div>
            <b>Создать Родителя</b><br /><br />
            <ParentForm onSubmit={onSubmit} onCancel={onCancel} loading={parentCreate.isLoading} />
        </div>
    )
}
