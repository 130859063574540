import { ReactNode, useEffect, Fragment } from 'react'
import { createPortal } from 'react-dom'
import { cn } from '../utils/classname'

export type Props = {
    isActive?: boolean
    children: ReactNode
    onClose: () => void
}

export default function Modal({ isActive, children, onClose }: Props) {
    const root = document.querySelector('#modals-root')

    useEffect(() => {
        const handleKeyboard = (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyboard)

        return () => {
            window.removeEventListener('keydown', handleKeyboard)
        }
    }, [onClose])

    if (!root) {
        return <Fragment />
    }

    return createPortal((
        <div className={cn('modal', { 'is-active': isActive })}>
            <div className="modal-background" onClick={onClose} />

            <div className="modal-card">
                <div className="modal-card-body">
                    {children}
                </div>
            </div>

            <button className="modal-close is-large" onClick={onClose} />
        </div>
    ), root)
}
