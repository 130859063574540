/* eslint-disable no-nested-ternary */
import { cn } from '../../core/utils/classname'
import { useState } from 'react'
import { checkPermission as cp } from '../../users/utils/auth'
import { useModal } from '../../core/hooks/modal'
import { ROOM_DETAIL, ROOM_CHANGE_MAIN } from '../../academy/urls'
import { useMessage } from '../../core/hooks/message'
import RoomUpdate from './RoomUpdate'
import Loader from '../../core/components/Loader'
import { RoomType } from '../../academy/types'
import { useChangeMainRoom, useRoomDelete } from '../../academy/hooks/room'

export type Props = {
    room: RoomType
    onDelete: () => void
    onUpdate: () => void
}

export default function RoomItem({ room, onUpdate, onDelete }: Props) {
    const deleteRoom = useRoomDelete()
    const [showMessage] = useMessage()
    const changeMainRoom = useChangeMainRoom()
    const [showDropdown, setShowDropdown] = useState(false)

    async function changeMain() {
        await changeMainRoom.mutate({ url: ROOM_CHANGE_MAIN.replace('{id}', String(room.id)) })
        onUpdate()
    }

    async function deleteColumn() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await deleteRoom.mutate({ url: ROOM_DETAIL.replace('{id}', String(room.id)) })

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <RoomUpdate room={room} onSuccess={async () => {
            await onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td>
                <span className={`tag ${room.color}`}>{room.name}</span>
                <span className="is-size-7 has-text-success">{room.isMain ? ' (основной)' : ''}</span>
            </td>

            <td>
                <div className="is-pulled-right">
                    {cp('academy.delete_room') ? (
                        !deleteRoom.isLoading ? (
                            <i onClick={deleteColumn} className="icon pointer ion-md-trash" />
                        ) : <Loader className="icon" />
                    ) : null}

                    {cp('academy.change_room') ? (
                        <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    ) : null}
                    <div
                        className={cn('dropdown', { 'is-active': showDropdown })}
                        onMouseLeave={() => setShowDropdown(false)}>
                        <div className="dropdown-trigger">
                            <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                        </div>

                        <div className="dropdown-menu" id="dropdown-menu">
                            <div className="dropdown-content">
                                {cp('finance.change_wallet') && !room.isMain ? (
                                    <a className="dropdown-item" onClick={changeMain}>
                                        <i className="icon ion-md-checkmark" />
                                        Сделать главным
                                    </a>
                                ) : (
                                    <a className="dropdown-item">
                                        <i className="icon ion-md-checkmark" />
                                        Главный
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
