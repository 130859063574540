import { cn } from '../../core/utils/classname'
import { useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import isEmpty from 'lodash/isEmpty'
import Button from '../../core/components/Button'
import Loader from '../../core/components/Loader'
import ServerError from '../../core/components/ServerError'
import successImg from '../static/success.png'
import { signin } from '../utils/auth'
import { useParams } from '../../core/hooks/queryString'
import { useConfirm } from '../hooks/users'

export default function ConfirmEmail() {
    const navigate = useNavigate()
    const { confirmationCode } = useParams()
    const confirm = useConfirm(confirmationCode as string)

    if (confirm.isLoading) {
        return <Loader large center padded />
    }

    return (
        <div className="column">
            <div className="container">
                {!isEmpty(confirm.error) || !confirm.data ? (
                    <ServerError error={confirm.error} />
                ) : (
                    <div>
                        <img className={cn('columns', css(styles.img))} src={successImg} alt="success" />

                        <Button
                            text="Войти в систему"
                            onClick={() => signin(confirm.data!, navigate)}
                            type="submit"
                            icon="ion-md-checkmark"
                            className={cn('columns', css(styles.button))} />
                    </div>
                )}
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    img: {
        width: '650px',
        margin: '0 auto',
    },
    button: {
        margin: '0 auto',
        background: 'hsl(217, 71%, 53%)',
        color: 'white',
    },
})
