import WalletForm from './WalletForm'
import { WalletType } from '../../finance/types'
import { FormikHelpers } from 'formik/dist/types'
import { useWalletCreate } from '../../finance/hooks/wallet'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function WalletCreate({ onCancel, onSuccess }: Props) {
    const walletsCreate = useWalletCreate()

    async function onSubmit(data: WalletType, actions: FormikHelpers<WalletType>) {
        await walletsCreate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Создавать счёт</b><br /><br />

            <WalletForm
                loading={walletsCreate.isLoading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
