import GroupMergeForm, { FormType } from './GroupMergeForm'
import { GroupType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupMerge } from '../hooks/group'

export type Props = {
    group: GroupType
    onCancel: () => void
    onSuccess: () => void
}

export default function GroupMerge({ group, onCancel, onSuccess }: Props) {
    const groupMerge = useGroupMerge()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        await groupMerge.mutate({ data: { ...data, firstGroup: group.id } })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Объединить группы</b><br /><br />

            <GroupMergeForm
                group={group}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={groupMerge.isLoading} />
        </div>
    )
}
