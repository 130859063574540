import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { TransactionCategoryType } from '../types'
import { TRANSACTION_CATEGORY_DETAIL, TRANSACTION_CATEGORY_LIST } from '../urls'

export type TransactionCategoryParams = {
    type?: 'income' | 'outcome'
    size?: number
    page?: number
}

export function useTransactionCategories({ type, size = 100, page = 0 }: TransactionCategoryParams) {
    return useLoad<Pagination<TransactionCategoryType>>({
        url: TRANSACTION_CATEGORY_LIST,
        params: { type, size, page },
    }, [type, size, page])
}

export function useTransactionCategoryCreate() {
    return usePostRequest({ url: TRANSACTION_CATEGORY_LIST })
}

export function useTransactionCategoryUpdate(id: number) {
    return usePutRequest({ url:TRANSACTION_CATEGORY_DETAIL.replace('{id}', String(id)) })
}

export function useTransactionCategoryDelete(id: number) {
    return useDeleteRequest({ url:TRANSACTION_CATEGORY_DETAIL.replace('{id}', String(id)) })
}

export function useCategoryChangeMainCreate() {
    return usePutRequest()
}
