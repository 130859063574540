import map from 'lodash/map'
import Chart from '../../core/components/Chart'
import { AdvertType } from '../../academy/types'

export type Props = {
    data: AdvertType[]
    loading: boolean
}

export default function StatisticsAcademyCostAdvertBar({ data, loading }: Props) {
    return (
        <div>
            <Chart
                loading={loading}
                type="horizontalBar"
                height="25rem"
                data={{
                    labels: data ? (
                        data.filter((item) => item.leadAmount > 0 || item.outcome === 0).map((item) => item.name)
                    ) : [],
                    datasets: [
                        {
                            data: data ? map(data.filter(
                                (item) => item.leadAmount > 0 || item.outcome === 0,
                            ), 'leadAmount') : [],
                            backgroundColor: 'rgb(255, 221, 87)',
                            fill: false,
                            borderColor: 'rgb(255, 221, 87)',
                            label: 'CPL',
                        },
                        {
                            data: data ? map(data.filter(
                                (item) => item.leadAmount > 0 || item.outcome === 0,
                            ), 'acceptedAmount') : [],
                            backgroundColor: 'rgb(72, 199, 116)',
                            fill: false,
                            borderColor: 'rgb(72, 199, 116)',
                            label: 'CPA',
                        },
                    ],
                }}
                options={{
                    legend: { position: 'bottom' },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            font: { weight: 'bold' },
                        },
                    },
                }} />

            {map(data, (item) => (item.outcome > 0 && item.leadAmount === 0 ? (
                <div key={item.name} className="has-text-danger">
                    Вы вложили {item.outcome} сум на рекламу в канале "{item.name}",
                    но этот канал не принес вам ни одного клиента
                    <br />
                </div>
            ) : null))}
        </div>
    )
}
