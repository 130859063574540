import { cn } from '../../core/utils/classname'
import { useNavigate } from 'react-router-dom'
import Layout from '../../core/components/Layout'
import AcademyTabs from '../components/AcademyTabs'
import TimelineItem from '../components/TimelineItem'
import { useQueryParams } from '../../core/hooks/queryString'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { TimelineParams, useTimeline } from '../hooks/timeline'
import { useRooms } from '../hooks/room'
import InfiniteTable, { Columns } from '../../core/components/InfiniteTable'
import { EmployeeTimelineType } from '../types'

export default function Timeline() {
    const params = useQueryParams<TimelineParams>()
    const rooms = useRooms()
    const employee = useTimeline(params)
    const navigate = useNavigate()

    if (!cp('academy.view_timeline')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <AcademyTabs />

            <div className="column">
                <div className="tags">
                    <span
                        onClick={() => navigate('?')}
                        className={cn('tag pointer is-success is-light', { 'is-medium': !params.room })}>
                        Все
                    </span>

                    {rooms.data?.results.map((item) => (
                        <span
                            key={item.id}
                            onClick={() => navigate(`?room=${item.id}`)}
                            className={cn(
                                'tag pointer',
                                item.color,
                                params.room === item.id.toString() ? 'is-medium' : '',
                            )}>
                            {item.name}
                        </span>
                    ))}
                </div>
            </div>

            <InfiniteTable
                responsive
                query={employee}
                columns={{
                    teacher: 'Учитель',
                    '1_monday': 'Понедельник',
                    '2_tuesday': 'Вторник',
                    '3_wednesday': 'Среда',
                    '4_thursday': 'Четверг',
                    '5_friday': 'Пятница',
                    '6_saturday': 'Суббота',
                    '7_sunday': 'Воскресенье',
                } as Columns<EmployeeTimelineType>}
                className="table is-bordered is-fullwidth"
                renderItem={(item) => (
                    <TimelineItem key={item.id} item={item} />
                )} />
        </Layout>
    )
}
