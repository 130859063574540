import Button from '../../core/components/Button'
import { ParentType } from '../types'
import ParentAddChild from './ParentAddChild'
import { useParentDetail } from '../hooks/parents'

export type Props = {
    parent: ParentType
    onCancel: () => void
}

export default function ParentInfo({ parent, onCancel }: Props) {
    const parentDetail = useParentDetail<ParentType>(parent.id)

    return (
        <div>
            <b>
                Информация о родитель: {parent.name} &nbsp;
            </b>

            <br/><br/>

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Ф.И.О:</td>
                        <td>{parent.name}</td>
                    </tr>

                    <tr>
                        <td>Номер телефона:</td>
                        <td>+{parent.phone}</td>
                    </tr>
                </tbody>
            </table>

            <ParentAddChild parentDetail={parentDetail} />

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger"/> &nbsp;
        </div>
    )
}
