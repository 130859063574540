import Layout from '../../core/components/Layout'
import WalletItem from '../components/WalletItem'
import Loader from '../../core/components/Loader'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { useWallets } from '../hooks/wallet'

export default function Wallet() {
    const wallets = useWallets()

    if (!cp('finance.view_wallet')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            <span className="is-size-4">Счета</span><br /><br />

            <div className="columns is-multiline">
                { !wallets.isLoading && wallets.data ? wallets.data.results.map((wallet) => (
                    <WalletItem key={wallet.id} wallet={wallet} />
                )) : null}

                <Loader show={wallets.isLoading} large center />
            </div>
        </Layout>
    )
}
