import { css, StyleSheet } from 'aphrodite'
import { cn } from '../../core/utils/classname'
import { useNavigate } from 'react-router-dom'
import { getMonth } from '../../core/utils/date'
import Money from '../../core/components/Money'
import { WalletType } from '../types'

export type Props = {
    wallet: WalletType
}

export default function WalletItem({ wallet }: Props) {
    const navigate = useNavigate()
    return (
        <div
            onClick={() => navigate(`/finance/wallet/${wallet.id}/transaction`)}
            className="column is-4-widescreen is-6-desktop is-12-tablet pointer">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div className="column is-narrow">
                        <div className={cn('icon has-text-grey-light', wallet.icon, css(styles.icon))} />
                    </div>

                    <div className="column">
                        <b>{wallet.name || <span className="is-italic has-text-grey">не указан</span>}</b>

                        <p>
                            Баланс: &nbsp;
                            <Money value={wallet.balance || 0} className="has-text-info" curr={wallet.currency} />
                            &nbsp;
                        </p>

                        <p>
                            Приход ({getMonth()}): &nbsp;
                            <Money value={wallet.income || 0} className="has-text-success" curr={wallet.currency} />
                        </p>

                        <p>
                            Расход ({getMonth()}): &nbsp;
                            <Money value={wallet.outcome || 0} className="has-text-danger" curr={wallet.currency} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    icon: {
        fontSize: '3em',
        margin: '1rem 0.5rem',
    },
    height: {
        height: '100%',
    },
})
