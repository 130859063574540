import { integersOnly } from '../../core/utils/number'
import CustomerForm, { FormType } from './CustomerForm'
import { CustomerType } from '../types'
import { useCustomerUpdate } from '../hooks/customer'

export type Props = {
    onSuccess: (data: CustomerType) => void
    onCancel: () => void
    customer: CustomerType
}

export default function CustomerUpdate({ onCancel, onSuccess, customer }: Props) {
    const customerUpdate = useCustomerUpdate(customer.id)

    async function onSubmit(values: FormType) {
        const { data, success } = await customerUpdate.mutate({ data: {
            ...values,
            phone: integersOnly(values.phone),
        } })

        if (success && data) {
            onSuccess(data)
        }
    }

    return (
        <div>
            <b>Изменить Студента</b><br /><br />

            <CustomerForm
                values={{
                    ...customer,
                    advert: customer.advert.id,
                    seller: customer.seller.id,
                    course: customer.course ? customer.course.id : '',
                } as FormType}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={customerUpdate.isLoading} />
        </div>
    )
}
