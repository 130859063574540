import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import ServerError from '../../core/components/ServerError'
import { useMessage } from '../../core/hooks/message'
import { required } from '../../core/utils/validators'
import { signin } from '../utils/auth'
import { useParams } from '../../core/hooks/queryString'
import { useResetPassword } from '../hooks/users'

export default function ResetPassword() {
    const resetPassword = useResetPassword()
    const [showMessage] = useMessage()
    const { key } = useParams()
    const navigate = useNavigate()

    async function onSubmit(values: { password: string, confirmPassword: string }) {
        if (values.password === values.confirmPassword) {
            const { data, success } = await resetPassword.mutate({ data: { password: values.password, key } })
            if (success) {
                showMessage('Ваш пароль быль успешно изменён', 'is-success')
                signin(data, navigate)
            }
        } else {
            showMessage('Введенные пароли не совпадают', 'is-danger')
        }
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{ password: '', confirmPassword: '' }}>
            <Form className={css(styles.container)}>
                <div className="columns">
                    <h1 className="title column">Изменить пароль</h1>
                </div>

                <ServerError error={resetPassword.error} />

                <Input
                    name="password"
                    label="Новый Пароль"
                    validate={required}
                    placeholder="********"
                    type="password" />

                <Input
                    name="confirmPassword"
                    type="password"
                    label="Повторите пароль"
                    placeholder="********"
                    validate={required} />

                <div className="field">
                    <p className="control">
                        <Button
                            loading={resetPassword.isLoading}
                            className="is-link"
                            text="Изменить и Войти"
                            type="submit" />
                    </p>
                </div>
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: '10rem auto',
        width: '30rem',
    },
})
