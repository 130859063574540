import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { LessonType } from '../types'
import { LESSON_DETAIL, LESSONS_LIST } from '../urls'

export type LessonsParams = {
    group?: string | number
    page?: string | number
}

export function useLessons({ group, page }: LessonsParams) {
    return useLoad<Pagination<LessonType>>({
        url: LESSONS_LIST,
        params: { group, page },
    }, [group, page])
}

export function useLessonCreate() {
    return usePostRequest({ url: LESSONS_LIST })
}

export function useLessonDelete(id: number) {
    return useDeleteRequest({ url: LESSON_DETAIL.replace('{id}', String(id)) })
}

export function useLessonUpdate(id: number) {
    return usePutRequest({ url: LESSON_DETAIL.replace('{id}', String(id)) })
}
