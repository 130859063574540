import StatisticsAcademySalesChannelChart from './StatisticsAcademySalesChannelChart'
import Card from '../../core/components/Card'
import { useSalesChannelStats } from '../hooks/stats'

export type Props = {
    startDate: string
    endDate: string
}

export default function StatisticsAcademySalesChannel({ startDate, endDate }: Props) {
    const salesChannel = useSalesChannelStats(startDate, endDate)

    return (
        <div className="columns">
            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика лидов по каналам продаж
                    </div>

                    <StatisticsAcademySalesChannelChart
                        data={salesChannel.data ? salesChannel.data : []}
                        loading={salesChannel.isLoading}
                        field="leadsCount" />
                </Card>
            </div>

            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика продаж по каналам
                    </div>

                    <StatisticsAcademySalesChannelChart
                        data={salesChannel.data ? salesChannel.data : []}
                        loading={salesChannel.isLoading}
                        field="acceptedCount" />
                </Card>
            </div>
        </div>
    )
}
