import { ChangeEvent, InputHTMLAttributes, useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { cn } from '../../core/utils/classname'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from '../../core/hooks/queryString'

export type Props = InputHTMLAttributes<HTMLInputElement>

export default function CustomersFilter({ className, ...attributes }: Props) {
    const params = useQueryParams()
    const [value, setValue] = useState(params.search || '')
    const navigate = useNavigate()

    function onSearch({ target }: ChangeEvent<HTMLInputElement>) {
        const search = !isEmpty(target.value) ? target.value : undefined
        navigate(`?${queryString.stringify({ ...params, search })}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChange = useCallback(debounce(onSearch, 500), [])

    return (
        <div className={cn('field', className)}>
            <p className="control has-icons-left is-expanded">
                <input
                    type="search"
                    value={value}
                    autoFocus={value !== ''}
                    onChange={(event) => {
                        setValue(event.target.value)
                        event.persist()
                        onChange(event)
                    }}
                    className="input is-small"
                    placeholder="Поиск..."
                    {...attributes} />

                <span className="icon is-small is-left">
                    <i className="icon ion-md-search" />
                </span>
            </p>
        </div>
    )
}
