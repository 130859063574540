import moment from 'moment'
import { DATE_FORMAT } from '../utils/date'
import { ChangeEvent } from 'react'

export type Props = {
    startDate: string
    endDate: string
    onDatesChange: (dates: { startDate: string, endDate: string }) => void
}

export default function DateRange({ startDate, endDate, onDatesChange }: Props) {
    function onChange({ target }: ChangeEvent<HTMLInputElement>) {
        onDatesChange({
            startDate: moment(target.name === 'startDate' ? target.value : startDate).format(DATE_FORMAT),
            endDate: moment(target.name === 'endDate' ? target.value : endDate).format(DATE_FORMAT),
        })
    }

    return (
        <div className="columns is-tablet">
            <div className="column">
                <label htmlFor="startDate">Начало</label>
                <input
                    name="startDate"
                    type="date"
                    value={startDate}
                    className="input"
                    onChange={onChange} />
            </div>

            <div className="column">
                <label htmlFor="startDate">Конец</label>
                <input
                    name="endDate"
                    type="date"
                    value={endDate}
                    className="input"
                    onChange={onChange} />
            </div>
        </div>
    )
}
