import { CUSTOMER_BIRTHDAYS, CUSTOMER_DETAIL, CUSTOMER_LIST } from '../urls'
import { useDeleteRequest, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { BirthdayCustomerType, CustomerType } from '../types'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export function useCustomerCreate() {
    return usePostRequest<CustomerType>({ url: CUSTOMER_LIST })
}

export function useCustomerDelete(id: number) {
    const url = CUSTOMER_DETAIL.replace('{id}', String(id))
    return useDeleteRequest({ url })
}

export function useCustomerUpdate(id: number) {
    const url = CUSTOMER_DETAIL.replace('{id}', String(id))
    return usePutRequest<CustomerType>({ url })
}

export function useBirthdays() {
    return useInfiniteFetch<BirthdayCustomerType>(
        ['birthdays'],
        ({ pageParam }) => request({ url: CUSTOMER_BIRTHDAYS, params: { page: pageParam } }),
    )
}

export function useCustomerMove() {
    return usePutRequest()
}
