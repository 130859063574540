import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { StatusType } from '../types'
import { STATUS_DETAIL, STATUS_LIST } from '../urls'

export function useStatuses() {
    return useLoad<Pagination<StatusType>>({ url: STATUS_LIST })
}

export function useStatuDelete() {
    return useDeleteRequest()
}

export function useStatusCreate() {
    return usePostRequest({ url: STATUS_LIST })
}

export function useStatusUpdate(id: number) {
    return usePutRequest({ url: STATUS_DETAIL.replace('{id}', String(id)) })
}

export function useStatusMove() {
    return usePutRequest()
}
