import moment from 'moment'
import LessonForm, { FormType } from './LessonForm'
import { DATETIME_LOCAL_FORMAT } from '../../core/utils/date'
import Loader from '../../core/components/Loader'
import { GroupType, LessonType, StudentType } from '../types'
import { useLessonUpdate } from '../hooks/lesson'
import { useVisits } from '../hooks/visit'

export type Props = {
    group: GroupType
    lesson: LessonType
    students: StudentType[]
    onSuccess: () => void
}

export default function LessonUpdate({ group, lesson, students, onSuccess }: Props) {
    const lessonUpdate = useLessonUpdate(lesson.id)
    const visits = useVisits(lesson.id)
    const visitsList = visits.data?.results
        .filter((visit) => visit.isVisited)
        .map((visit) => String(visit.student.id)) || []

    if (visits.isLoading) {
        return <Loader large center />
    }

    const initialValues = {
        ...lesson,
        visits: visitsList,
        date: moment(lesson.date).format(DATETIME_LOCAL_FORMAT),
    }


    async function onSubmit(data: FormType, activeStudents: StudentType[]) {
        const newVisits = activeStudents.map((item) => ({
            student: item.id,
            isVisited: data.visits.includes(String(item.id)),
        }))

        await lessonUpdate.mutate({ data: { ...data, group: group.id, visits: newVisits } })
        onSuccess()
    }

    return (
        <div>
            <b>Редактировать урок</b><br /><br />
            <LessonForm
                group={group}
                students={students}
                onSubmit={onSubmit}
                initialValues={initialValues}
                loading={lessonUpdate.isLoading} />
        </div>
    )
}
