import Card from '../../core/components/Card'
import { css, StyleSheet } from 'aphrodite'
import SkeletonLine from '../../core/components/SkeletonLine'
import random from '../../core/utils/number'
import Progress from '../../core/components/Progress'
import range from 'lodash/range'

export type Props = {}

export default function DebtorMothsSkeleton({}: Props) {
    return (
        <div className="columns">
            {range(0, 4).map((i) => (
                <div className="column is-3" key={i}>
                    <Card className={css(styles.skeletonCard)}>
                        <div className="is-size-4">
                            <SkeletonLine width="40%" height="1.7rem" background="#908F8FFF" />
                        </div>

                        <div><SkeletonLine width={`${random(50, 80)}%`} /></div>

                        <div><SkeletonLine width={`${random(30, 60)}%`} /></div>

                        <br />

                        <Progress value={random(1, 10) * 10} max={100} background="#d0d0d0" />
                    </Card>
                </div>
            ))}
        </div>

    )
}


const styles = StyleSheet.create({
    skeletonCard: {
        height: '11rem',
    },
})
