import TaskForm, { FormType } from './TaskForm'
import { FormikHelpers } from 'formik/dist/types'
import { useTaskCreate } from '../hooks/tasks'
import { TaskType } from '../types'

export type Props = {
    onSuccess: (newItem: TaskType) => void
    onCancel: () => void
}

export default function TaskCreate({ onCancel, onSuccess }: Props) {
    const taskCreate = useTaskCreate()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const response = await taskCreate.mutateAsync({ ...data, dueTime: data.dueTime ? data.dueTime : null })
        actions.resetForm()
        onSuccess(response)
    }

    return (
        <div>
            <b>Добавить новую задачу</b><br /><br />

            <TaskForm
                loading={taskCreate.isLoading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
