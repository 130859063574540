import { FormikHelpers } from 'formik/dist/types'
import { CashbackType } from '../types'
import CashbackForm from './CashbackForm'
import { useCloseCashback } from '../hooks/cashback'

export type Props = {
    onSuccess: (cashback: CashbackType) => void
    onCancel: () => void
    cashback: CashbackType
}

export type FormType = CashbackType & {
    cashback: string | number
    wallet: string | number
}

export default function CashbackClose({ onCancel, onSuccess, cashback }: Props) {
    const cashbackClose = useCloseCashback()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const response = await cashbackClose.mutate({ data })

        if (response.success) {
            onSuccess(response.data)
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Закрыть Кешбек</b><br /><br />
            <CashbackForm 
                onSubmit={onSubmit} 
                onCancel={onCancel} 
                loading={cashbackClose.isLoading} 
                cashback={cashback} />
        </div>
    )
}
