import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import ServerError from '../../core/components/ServerError'
import { email, required, validator } from '../../core/utils/validators'
import Password from '../../core/components/Password'
import { signin, isAuthenticated } from '../utils/auth'
import LayoutAuth from '../components/LayoutAuth'
import logo from '../static/logo.png'
import { useSignin } from '../hooks/users'
import { cn } from '../../core/utils/classname'

export default function Login() {
    const navigate = useNavigate()
    const signIn = useSignin()

    if (isAuthenticated()) {
        return <Navigate to="/academy/customers" replace />
    }

    async function onSubmit(values: { email: string, password: string }) {
        const { data, success } = await signIn.mutate({ data: values })

        if (success) {
            signin(data, navigate)
        }
    }

    return (
        <LayoutAuth sidebar={(
            <div>
                <h2 className="is-size-5 has-text-weight-bold">Еще нет аккаунта?</h2>
                <p>Если вы еще не зарегистрировались переходите по ссылке и создайте аккаунт</p>

                <br />

                <NavLink to="/sign-up" className="button is-link is-outlined is-inverted">
                    Создать аккаунт
                </NavLink>
            </div>
        )}>
            <img src={logo} className={css(styles.img)} alt="Logo" />
            <h2 className="is-size-4 has-text-weight-bold">Войдите в систему</h2>
            <br />

            <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input name="email" icon="ion-md-mail" validate={validator(required, email)} placeholder="Email" />
                    <Password name="password" icon="ion-md-key" validate={required} placeholder="Password" />

                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.isLoading}
                                className={cn('button is-medium is-fullwidth', css(styles.isOrange))}
                                text="Войти"
                                type="submit" />
                        </div>
                    </div>
                    <div className={css(styles.onlyMobile)}>
                        <NavLink to="/sign-up">Зарегистрироваться</NavLink>
                        <br />
                        <br />
                    </div>

                    <div className="mt-5 forgot-password">
                        <div className="has-text-centered">
                            <NavLink to="/reset-link" className={css(styles.forgotPassword)}>Забыли пароль?</NavLink>
                        </div>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
    },
    img: {
        width: '10rem',
        marginBottom: '1rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#cd451f',
        },
    },
    isOrange: {
        backgroundColor: '#ff6038',
        color: '#fff',
        borderColor: '#fff',

        ':focus': {
            boxShadow: '0 0 0 0.125em #ff7049',
            borderColor: '#fff',
        },
    },
    button: {
        width: '100%',
        backgroundColor: '#cd451f',
        color: '#fff',
        borderColor: '#dbdbdb',
        borderWidth: '1px',
        cursor: 'pointer',
        justifyContent: 'center',
        paddingBottom: 'calc(0.5em - 1px)',
        paddingLeft: '1em',
        paddingRight: '1em',
        paddingTop: 'calc(0.5em - 1px)',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
})
