import { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import Input from '../../core/components/Input'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Table from '../../core/components/Table'
import { formatDate } from '../../core/utils/date'
import { DebtorDelayType, DebtorType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useDelayCreate, useDelays } from '../hooks/debtor'

export type Props = {
    debtor: DebtorType
    onSuccess: (expiresAt: string) => void
}

export default function DebtorDelays({ debtor, onSuccess }: Props) {
    const tomorrow = moment({ hour: 12 }).add(1, 'day').format(moment.HTML5_FMT.DATE)
    const today = moment().format(moment.HTML5_FMT.DATE)
    const nextMonth = moment().add(1, 'month').format(moment.HTML5_FMT.DATE)
    const [page, setPage] = useState(1)

    const delayCreate = useDelayCreate(debtor.id)
    const delays = useDelays(debtor.id)

    async function onSubmit(data: DebtorDelayType, actions: FormikHelpers<DebtorDelayType>) {
        await delayCreate.mutate({ data })
        actions.resetForm()
        onSuccess(data.expiresAt)
    }

    return (
        <Fragment>
            <Formik onSubmit={onSubmit} initialValues={{ expiresAt: tomorrow, description: '' } as DebtorDelayType}>
                <Form>
                    <b>Отсрочки студента &nbsp; {debtor.student?.customer?.name }</b><br /><br />

                    <div className="columns">
                        <div className="column">
                            <Input
                                name="expiresAt"
                                type="date"
                                min={today}
                                max={nextMonth}
                                placeholder="Срок"
                                validate={required} />
                        </div>

                        <div className="column">
                            <Input
                                name="description"
                                type="text"
                                placeholder="Причина"
                                validate={required} />
                        </div>

                        <div className="column is-narrow">
                            <Button
                                loading={delayCreate.isLoading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info" />
                        </div>
                    </div>
                </Form>
            </Formik>

            <Table
                totalCount={delays.data ? delays.data.count : 0}
                columns={{
                    expiresAt: 'Срок',
                    reason: 'Причина',
                }}
                loading={delays.isLoading}
                items={delays.data ? delays.data.results : []}
                onPageChange={setPage}
                activePage={page}
                renderItem={(delay) => (
                    <tr key={delay.id}>
                        <td>{formatDate(delay.expiresAt)}</td>
                        <td>{delay.description}</td>
                    </tr>
                )} />
        </Fragment>
    )
}
