import Loader from '../../core/components/Loader'
import Progress from '../../core/components/Progress'
import { StatusType } from '../../academy/types'

export type Props = {
    salesFunnel: StatusType[]
    loading: boolean
}

export default function StatisticSalesFunnelProgress({ salesFunnel, loading }: Props) {
    return (
        <div>
            {!loading ? salesFunnel.map((item) => (
                <div key={item.title} className="columns">
                    <span className="column">{item.title} - {item.count}: </span>
                    <span className="column is-four-fifths">
                        <Progress
                            value={item.count}
                            text={item.count}
                            background={item.color}
                            max={salesFunnel[0].count} />
                    </span>
                </div>
            )) : <Loader large />}
        </div>
    )
}
