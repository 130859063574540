import Layout from '../../core/components/Layout'
import SettingsTabs from '../components/SettingsTabs'
import WalletCreate from '../components/WalletCreate'
import { useModal } from '../../core/hooks/modal'
import Button from '../../core/components/Button'
import { checkPermission as cp } from '../../users/utils/auth'
import Table from '../../core/components/Table'
import WalletSettingsItem from '../components/WalletSettingsItem'
import Card from '../../core/components/Card'
import { useQueryParams } from '../../core/hooks/queryString'
import TransactionCategoryCreate from '../components/TransactionCategoryCreate'
import TransactionCategoryItem from '../components/TransactionCategoryItem'
import SettingsTaxesForm from '../components/SettingsTaxesForm'
import { useWallets } from '../../finance/hooks/wallet'
import { useTransactionCategories } from '../../finance/hooks/transactionCategories'

export default function SettingsFinance() {
    const params = useQueryParams()
    const wallets = useWallets()
    const transactionCategories = useTransactionCategories(params)

    const [showCreteWallet, hideCreateWallet] = useModal(
        <WalletCreate onSuccess={() => {
            void wallets.refetch()
            hideCreateWallet()
        }} onCancel={() => hideCreateWallet()} />,
    )


    const [showCreateTransactionCategory, hideCreateTransactionCategory] = useModal(
        <TransactionCategoryCreate onCancel={() => hideCreateTransactionCategory()} onSuccess={async () => {
            await transactionCategories.refetch()
            hideCreateTransactionCategory()
        }} />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    {cp('finance.view_wallet') ? (
                        <Card>
                            <div className="columns">
                                <div className="is-size-4 column">Счета</div><br />

                                <div className="buttons has-addons is-right">
                                    {cp('finance.add_wallet') ? (
                                        <Button
                                            onClick={showCreteWallet}
                                            text="Создать счёт"
                                            icon="ion-md-add"
                                            className="is-centered is-link is-outlined" />
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                totalCount={wallets.data?.count || 0}
                                columns={{ id: '№', name: 'Имя', icon: 'Иконка', actions: '' }}
                                loading={wallets.isLoading}
                                items={wallets.data ? wallets.data.results : []}
                                renderItem={(item) => (
                                    <WalletSettingsItem
                                        item={item}
                                        key={item.id}
                                        onUpdate={wallets.refetch}
                                        onDelete={wallets.refetch}/>
                                )} />
                        </Card>
                    ) : null}

                    <br />
                    {cp('core.change_company') ? (
                        <SettingsTaxesForm />
                    ) : null}
                </div>

                {cp('finance.view_transactioncategory') ? (
                    <div className="column">
                        <Card>
                            <div className="columns">
                                <div className="is-size-4 column">Категория транзакций</div><br />

                                <div className="buttons has-addons is-right">
                                    {cp('finance.add_transactioncategory') ? (
                                        <Button
                                            onClick={showCreateTransactionCategory}
                                            text="Добавить категорию"
                                            icon="ion-md-add"
                                            className="is-centered is-link is-outlined" />
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                loading={transactionCategories.isLoading}
                                totalCount={transactionCategories.data?.count || 0}
                                columns={{ name: 'Название', type: 'Тип', actions: '' }}
                                items={transactionCategories.data?.results}
                                renderItem={(category) => (
                                    <TransactionCategoryItem
                                        category={category}
                                        onUpdate={transactionCategories.refetch}
                                        onDelete={transactionCategories.refetch}
                                        key={category.id} />
                                )} />
                        </Card>
                    </div>
                ) : null}
            </div>
        </Layout>
    )
}
