import { isValidElement, ReactElement, useContext } from 'react'
import { Context } from '../components/BaseContext'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = any  // I am sorry :)
type ComponentFunction = ((props?: Props) => ReactElement) | ReactElement
type Returns = [(props?: Props) => void, () => void]

export function useModal(component: ComponentFunction): Returns {
    const { setModalComponent } = useContext(Context)

    function showModal(props?: Props) {
        const content = isValidElement(component) ? component : component(props)
        setModalComponent(content)
    }

    function hideModal() {
        setModalComponent(undefined)
    }

    return [showModal, hideModal]
}
