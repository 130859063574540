import { Fragment, useState } from 'react'
import { ParentType } from '../types'
import ParentAddChildItem from './ParentAddChildItem'
import ParentAddChildForm from './ParentAddChildForm'
import { useParentAddChild } from '../hooks/parents'
import { FormType } from './ParentAddChildForm'
import NotFound from '../../core/pages/NotFound'
import { LoadReturns } from '../../core/hooks/request.legacy'
import Loader from '../../core/components/Loader'
import { useMessage } from '../../core/hooks/message'
import { checkPermission as cp } from '../../users/utils/auth'

export type Props = {
    parentDetail: LoadReturns<ParentType>
}

export default function ParentAddChild({ parentDetail }: Props) {
    const [showForm, setShowForm] = useState(false)
    const [showMessage] = useMessage()
    const addChild = useParentAddChild()

    async function onCreate(values: FormType) {
        if (!values.student) {
            showMessage('Please choose one of options to add child!', 'is-warning')
            return
        }

        const { data, success, error } = await addChild.mutate({ data: { ...values, parent: parentDetail?.data?.id } })

        if (error) {
            showMessage('Something went wrong while adding child!', 'is-danger')
            setShowForm(false)
        }

        if (success && data) {
            parentDetail?.data?.parentStudents.push(data)
            setShowForm(false)
        }
    }

    if (parentDetail.error) {
        return <NotFound/>
    }

    if (parentDetail.isLoading) {
        return (
            <div className="columns is-centered my-6">
                <Loader/>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="is-flex is-justify-content-space-between">
                <p className="is-size-5 m-2">Дети</p>

                {cp('academy.add_parentstudent') ? (
                    <p className="is-size-6 m-2 has-text-link is-clickable" onClick={() => setShowForm(true)}>
                        <i className="icon ion-md-add"/>
                    Добавить
                    </p>
                ) : null }
            </div>

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr/>

                    {parentDetail.data?.parentStudents === null && !showForm ? (
                        <tr>
                            <td>Пусто</td>
                            <td />
                            <td />
                        </tr>
                    ) : (
                        parentDetail.data?.parentStudents?.map((item) => (
                            <ParentAddChildItem
                                key={item.id}
                                parentDetail={parentDetail}
                                item={item}/>
                        )))
                    }

                    {showForm && (
                        <ParentAddChildForm
                            onSubmit={onCreate}
                            onCancel={() => setShowForm(false)}
                            students={parentDetail.data?.parentStudents}/>
                    )}
                </tbody>
            </table>
        </Fragment>
    )
}
