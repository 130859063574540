import Button from '../../core/components/Button'
import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import { useStudentUpdate } from '../hooks/student'

export type Props = {
    studentId: number
    price: number
    customerId?: number
    onCancel: () => void
    onSuccess: () => void
}

export type FormType = {
    price: string
}


export default function StudentUpdatePrice({ studentId, customerId, price, onCancel, onSuccess }: Props) {
    const { isLoading, mutate } = useStudentUpdate(studentId)

    async function handleUpdate(values: FormType) {
        const { data, success } = await mutate({ data: { ...values, customer: customerId } })

        if (success && data) {
            onSuccess()
        }
    }
    return (
        <Formik initialValues={{ price: price.toString() }} onSubmit={handleUpdate}>
            <Form>
                <Input
                    name="price"
                    label="Стоимость"
                    placeholder="Стоимость месячного абонемента"
                    disabled={isLoading}
                    validate={required}
                    type="number" />

                <Button
                    loading={isLoading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;
                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Закрыть"
                    className="is-danger" /> &nbsp;
            </Form>
        </Formik>
    )
}
