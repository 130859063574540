import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { TransactionCategoryType } from '../../finance/types'
import { types } from '../../finance/utils/constants'

export type Props = {
    initialValues?: TransactionCategoryType
    onSubmit: FormSubmit<TransactionCategoryType>
    loading?: boolean
    onCancel: () => void
}

export default function TransactionCategoryForm({ onSubmit, onCancel, loading, initialValues }: Props) {
    const defaultAmount = initialValues?.defaultAmount || ''
    const descriptionTemplate = initialValues?.descriptionTemplate || ''

    const values = { name: '', type: '', ...initialValues, defaultAmount, descriptionTemplate }

    return (
        <Formik initialValues={values as TransactionCategoryType} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    label="Название"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите имя" />

                <Input
                    optional
                    name="defaultAmount"
                    type="number"
                    label="Сумма по умолчанию"
                    disabled={loading}
                    placeholder="Введите сумму" />

                <Input
                    optional
                    name="descriptionTemplate"
                    label="Описание по умолчанию"
                    disabled={loading}
                    placeholder="Введите текст" />

                <Select
                    label="Тип"
                    optionValue="value"
                    optionLabel="label"
                    name="type"
                    options={types}
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
