import { DependencyList, Dispatch, LegacyRef, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import uniqBy from 'lodash/uniqBy'
import { auth, signOut } from '../../users/utils/auth'
import baseAxios from '../utils/request'

import { useMessage } from './message'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Pagination } from '../types'
import { useNoInitEffect } from './effect'
import { useQueryClient } from 'react-query'

export type ServerErrorType = Record<string, string>

export type SuccessResponse<R> = {
    success: true
    error: undefined
    data: R
}

export type ErrorResponse = {
    success: false
    error: AxiosResponse<ServerErrorType>
    data: undefined
}

export type Response<R> = SuccessResponse<R> | ErrorResponse

export type RequestReturns<R> = {
    isLoading: boolean
    mutate: (overrideOptions?:AxiosRequestConfig, sync?: boolean) => Promise<Response<R>>
    error?: AxiosResponse<ServerErrorType>
    data?: R
    setData: Dispatch<SetStateAction<R | undefined>>
}

export type LoadReturns<R> = Omit<RequestReturns<R>, 'mutate'> & {
    refetch: RequestReturns<R>['mutate']
}

export type InfiniteScrollReturns<R, E> = RequestReturns<R> & {
    ref: LegacyRef<E>
    hasMore: boolean
    reload: () => Promise<void>
    reset: () => void
}

export type ExtraOptions<R> = {
    initData?: R
    defaultLoading?: boolean
    onSuccess?: (response: R) => void
    manualLoad?: boolean
}

export function usePostRequest<R>(options: AxiosRequestConfig = {}): RequestReturns<R> {
    return useRequest({ method: 'POST', ...options }, {})
}

export function usePutRequest<R>(options: AxiosRequestConfig = {}): RequestReturns<R> {
    return useRequest({ method: 'PUT', ...options }, {})
}

export function useGetRequest<R>(options: AxiosRequestConfig = {}): RequestReturns<R> {
    return useRequest({ method: 'GET', ...options }, {})
}

export function useDeleteRequest<R>(options: AxiosRequestConfig = {}): RequestReturns<R> {
    return useRequest({ method: 'DELETE', ...options }, {})
}

export function useRequest<R>(options: AxiosRequestConfig = {}, extra: ExtraOptions<R> = {}): RequestReturns<R> {
    const initRef = useRef({ isInit: Boolean(extra.initData) })
    const [response, setResponse] = useState<R>()
    const [isLoading, setIsLoading] = useState(extra.defaultLoading || false)
    const [error, setError] = useState<AxiosResponse<ServerErrorType> | undefined>()
    const [showMessage] = useMessage()
    const client = useQueryClient()
    const navigate = useNavigate()

    async function request(overrideOptions = {}, sync = false): Promise<Response<R>> {
        if (extra.initData && initRef.current.isInit) {
            if (!sync) setResponse(extra.initData)
            initRef.current.isInit = false
            return { data: extra.initData, success: true } as Response<R>
        }

        setIsLoading(true)

        try {
            const { data } = await baseAxios({ ...auth(), ...options, ...overrideOptions })
            if (!sync) setResponse(data)
            if (extra.onSuccess) extra.onSuccess(data)
            return { data: data, success: true } as Response<R>
        } catch (e) {
            if (!axios.isAxiosError(e)) {
                throw e
            }

            setError(e.response as AxiosResponse<ServerErrorType>)
            if (e.response === undefined || e.response.status === 0) {
                showMessage('Проверьте интернет соединение', 'is-danger')
            } else if (e.response.status >= 500) {
                showMessage('Ошибка сервера.', 'is-danger')
            } else if (e.response.status === 401) {
                signOut(navigate, client.invalidateQueries)
            }

            return { error: e.response, success: false } as Response<R>
        } finally {
            if (!sync) setIsLoading(false)
        }
    }

    return { isLoading, mutate: request, error, data: response, setData: setResponse }
}

export function useLoad<R>(
    options: AxiosRequestConfig,
    dependencies: DependencyList = [],
    extra: ExtraOptions<R> = {},
): LoadReturns<R> {
    const request = useRequest<R>({ method: 'GET', ...options }, {
        defaultLoading: !extra?.manualLoad,
        ...extra,
    })

    useEffect(() => {
        if (extra?.manualLoad) return
        request.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)

    return {
        isLoading: request.isLoading,
        refetch: request.mutate,
        error: request.error,
        data: request.data,
        setData: request.setData,
    }
}

export function useInfiniteScroll<R, E extends Element>(
    options: AxiosRequestConfig,
    dependencies: DependencyList = [],
): InfiniteScrollReturns<Pagination<R>, E> {
    const [page, setPage] = useState(1)
    const items = useGetRequest<Pagination<R>>({ ...options, params: { ...options.params, page } })
    const [hasMore, setHasMore] = useState(false)
    const observer = useRef<IntersectionObserver>()

    useEffect(() => {
        loadItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useNoInitEffect(() => {
        reload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)

    async function loadItems() {
        const { data } = await items.mutate()
        const oldItems = items.data ? items.data.results : []
        const newItems = data ? data.results : []

        if (!data) return

        items.setData({ count: data ? data.count : 0, results: uniqBy([...oldItems, ...newItems], 'id') })
        setHasMore(oldItems.length + newItems.length !== data.count)
    }

    function reset() {
        items.setData({ count: 0, results: [] })
        setPage(1)
    }

    async function reload() {
        reset()
        await items.mutate({ params: { ...options.params, page: 1 } })
    }

    const ref = useCallback((node: E) => {
        if (items.isLoading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(page + 1)
            }
        })
        if (node) observer.current.observe(node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMore, items.isLoading, page]) as LegacyRef<E>

    return {
        ref, ...items, hasMore, reload, reset,
    }
}
