import { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { cn } from '../../core/utils/classname'
import { position, positionClass } from '../utils/position'
import { PositionsType } from '../types'

export type Props = {
    positions?: PositionsType[]
}

export default function EmployeePositions({ positions }: Props) {
    return (
        <Fragment>
            {positions?.map((item) => (
                <span key={item} className={cn('tag', positionClass(item), css(styles.tag))}>
                    {position(item)}
                </span>
            ))}
        </Fragment>
    )
}

const styles = StyleSheet.create({
    tag: {
        marginRight: '3px',
    },
})
