import reject from 'lodash/reject'
import { Form, Formik } from 'formik'
import { required, validator, maxNumber } from '../../core/utils/validators'

import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { TransactionType, WalletType } from '../types'
import { useTransfer } from '../hooks/transaction'
import { useWalletsSimple } from '../hooks/wallet'

export type Props = {
    wallet: WalletType
    onCancel: () => void
    onSuccess: (transaction: TransactionType) => void
}

export type FormType = {
    amount: string
    wallet: string
}

export default function TransactionTransfer({ onCancel, onSuccess, wallet }: Props) {
    const initialValues = { amount: '', wallet: '', walletId: wallet.id }
    const transactionTransfer = useTransfer(wallet.id)
    const wallets = useWalletsSimple()
    const walletsList = reject(wallets.data || [], { id: wallet.id })

    async function onSubmit(data: FormType) {
        const response = await transactionTransfer.mutate({ data })
        if (response.success) {
            onSuccess(response.data)
        }
    }

    return (
        <div>
            <b>Перевести средства</b><br /><br />
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <Input
                        name="amount"
                        type="number"
                        placeholder="Сумма"
                        validate={validator(required, maxNumber(wallet.balance || 0))} />

                    <Select
                        empty
                        name="wallet"
                        help="Вы можете добавить новый счёт в настройках"
                        options={walletsList}
                        loading={wallets.isLoading}
                        validate={required} />

                    <Button
                        loading={transactionTransfer.isLoading}
                        text="Перевести"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            </Formik>
        </div>
    )
}
