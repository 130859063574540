import RoomForm from './RoomForm'
import { RoomType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useRoomCreate } from '../../academy/hooks/room'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function RoomCreate({ onSuccess, onCancel }: Props) {
    const roomCreate = useRoomCreate()

    async function onSubmit(data: RoomType, actions: FormikHelpers<RoomType>) {
        await roomCreate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить нового кабинет</b><br /><br />
            <RoomForm loading={roomCreate.isLoading} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
