import map from 'lodash/map'
import EmployeeForm, { EmployeeFormType } from './EmployeeForm'
import ServerError from '../../core/components/ServerError'
import { FormikHelpers } from 'formik/dist/types'
import { useEmployeeCreate } from '../hooks/employee'
import { EmployeeType } from '../types'

export type Props = {
    onSuccess: (employee: EmployeeType) => void
    onCancel: () => void
}

export default function EmployeeCreate({ onCancel, onSuccess }: Props) {
    const employeeCreate = useEmployeeCreate()

    async function onSubmit(data: EmployeeFormType, actions: FormikHelpers<EmployeeFormType>) {
        const response = await employeeCreate.mutate({ data: {
            ...data, positions: map(data.positions, 'value'),
        } })

        if (response.success) {
            actions.resetForm()
            onSuccess(response.data)
        }
    }

    return (
        <div>
            <b>Добавить нового сотрудника</b><br /><br />
            <ServerError error={employeeCreate.error} />
            <EmployeeForm onSubmit={onSubmit} onCancel={onCancel} loading={employeeCreate.isLoading} />
        </div>
    )
}
