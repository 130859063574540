import { integersOnly } from '../../core/utils/number'
import CustomerForm, { FormType } from './CustomerForm'
import { CustomerType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useCustomerCreate } from '../hooks/customer'

export type Props = {
    state?: number
    onCancel: () => void
    onSuccess: (data: CustomerType) => void
}

export default function CustomerCreate({ state, onCancel, onSuccess }: Props) {
    const customerCreate = useCustomerCreate()

    async function onSubmit(values: FormType, actions: FormikHelpers<FormType>) {
        let phoneSecond = values.phoneSecond ? integersOnly(values.phoneSecond) : null
        phoneSecond = phoneSecond === integersOnly(values.phone) ? null : phoneSecond
        const { data, success } = await customerCreate.mutate({
            data: { ...values, state, phone: integersOnly(values.phone), phoneSecond },
        })

        if (success && data) {
            actions.resetForm()
            onSuccess(data)
        }
    }

    return (
        <div>
            <b>Добавить нового студента</b><br /><br />

            <CustomerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={customerCreate.isLoading} />
        </div>
    )
}
