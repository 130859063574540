import ProtectedRoute from '../core/components/ProtectedRoute'
import NotFound from '../core/pages/NotFound'
import { Route, Routes } from 'react-router-dom'
import Task from './pages/Task'

export default function TaskRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="" element={<Task/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
