import { ParentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useParentUpdate } from '../hooks/parents'
import ParentForm from './ParentForm'

export type Props = {
    onUpdate: (parent: ParentType) => void
    onCancel: () => void
    parent: ParentType
}

export default function ParentUpdate({ onCancel, onUpdate, parent }: Props) {
    const groupUpdate = useParentUpdate(parent.id)
    const values = { ...parent } as ParentType

    async function onSubmit(data: ParentType, actions:FormikHelpers<ParentType>) {
        const response = await groupUpdate.mutate({ data })
        actions.resetForm()
        if (response.success) {
            onUpdate(response.data)
        }
    }

    return (
        <div>
            <b>Изменить родителя</b><br /><br />

            <ParentForm
                initialValues={values}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={groupUpdate.isLoading} />
        </div>
    )
}
