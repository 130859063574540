import ColumnForm from './ColumnForm'
import { StatusType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useStatusUpdate } from '../../academy/hooks/status'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    status: StatusType
}

export default function ColumnUpdate({ onSuccess, onCancel, status }: Props) {
    const statusUpdate = useStatusUpdate(status.id)

    async function onSubmit(data: StatusType, actions: FormikHelpers<StatusType>) {
        await statusUpdate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Изменить этап</b><br /><br />

            <ColumnForm
                loading={statusUpdate.isLoading}
                onCancel={onCancel}
                initialValues={status}
                onSubmit={onSubmit} />
        </div>
    )
}
