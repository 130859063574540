import { Formik, Form } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import { DebtorType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useDebtorDiscount } from '../hooks/debtor'

export type Props = {
    debtor: DebtorType
    onSuccess: (data: DebtorType) => void
}

export default function DebtorDiscount({ debtor, onSuccess }: Props) {
    const discount = useDebtorDiscount(debtor.id)

    async function onSubmit(data: DebtorType, actions: FormikHelpers<DebtorType>) {
        await discount.mutate({ data })
        actions.resetForm()
        onSuccess(data)
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={debtor}>
            <Form>
                <b>Скидка студенту &nbsp; {debtor.student?.customer?.name }</b><br /><br />

                <Input
                    name="discount"
                    type="number"
                    label="Сумма скидки"
                    placeholder="Скидка"
                    validate={required} />

                <Input
                    name="discountReason"
                    type="text"
                    label="Причина"
                    placeholder="Причина"
                    validate={required} />

                <Button
                    loading={discount.isLoading}
                    text="Добавить"
                    type="submit"
                    icon="ion-md-add"
                    className="is-info" />
            </Form>
        </Formik>
    )
}
