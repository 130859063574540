import { format } from '../../core/utils/number'
import { getMonth } from '../../core/utils/date'
import { useModal } from '../../core/hooks/modal'
import EmployeeHistorySalary from './EmployeeHistorySalary'
import { EmployeeSalaryType, EmployeeType } from '../types'

export type Props = {
    item: EmployeeSalaryType
    employee: EmployeeType
}

export default function EmployeeSalaryItem({ item, employee }: Props) {
    const [showSalaryModal, hideSalaryModal] = useModal(
        <EmployeeHistorySalary
            employeeSalary={item}
            employeeDetail={employee}
            onCancel={() => hideSalaryModal()} />,
    )

    return (
        <tr>
            <td data-label="Месяц">
                {getMonth(item.month, true) || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td data-label="Зарплата">
                <span className="has-text-success">{format(item.profit || 0)} сум </span> /&nbsp;
                <span className="has-text-link">{format(item.payed)} сум </span>
            </td>

            <td data-label="Действия">
                <span onClick={showSalaryModal}><i className="icon ion-md-eye" /></span>
            </td>
        </tr>
    )
}
