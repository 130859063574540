import SalesChannelForm from './SalesChannelForm'
import { AdvertType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useSalesChannelCreate } from '../../academy/hooks/salesChannel'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function SalesChannelCreate({ onCancel, onSuccess }: Props) {
    const salesChannelCreate = useSalesChannelCreate()

    async function onSubmit(data: AdvertType, actions: FormikHelpers<AdvertType>) {
        await salesChannelCreate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Создать канал</b><br /><br />
            <SalesChannelForm onSubmit={onSubmit} onCancel={onCancel} loading={salesChannelCreate.isLoading} />
        </div>
    )
}
