import { useDeleteRequest, useLoad, usePostRequest } from '../../core/hooks/request.legacy'
import { FINE_DETAIL, FINE_LIST } from '../urls'
import { Pagination } from '../../core/types'
import { FineType } from '../types'

export function useFineCreate() {
    return usePostRequest({ url: FINE_LIST })
}

export function useFines(employeeId: number) {
    return useLoad<Pagination<FineType>>({ url: FINE_LIST, params: { employee: employeeId } }, [])
}

export function useFineDelete(id: number) {
    return useDeleteRequest({ url: FINE_DETAIL.replace('{id}', String(id)) })
}
