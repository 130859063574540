/* eslint-disable no-nested-ternary */
import { cn } from '../../core/utils/classname'
import { useState } from 'react'
import { checkPermission as cp } from '../../users/utils/auth'
import { useModal } from '../../core/hooks/modal'
import { useMessage } from '../../core/hooks/message'
import WalletUpdate from './WalletUpdate'
import Loader from '../../core/components/Loader'
import { WalletType } from '../../finance/types'
import { WALLET_CHANGE_MAIN } from '../../finance/urls'
import { useWalletDelete, useWalletMainUpdate } from '../../finance/hooks/wallet'

export type Props = {
    item: WalletType
    onDelete: () => void
    onUpdate: () => void
}

export default function WalletSettingsItem({ item, onDelete, onUpdate }: Props) {
    const [showMessage] = useMessage()
    const walletDelete = useWalletDelete(item.id)
    const walletChangeMain = useWalletMainUpdate()
    const [showDropdown, setShowDropdown] = useState(false)

    async function changeMain() {
        await walletChangeMain.mutate({ url: WALLET_CHANGE_MAIN.replace('{id}', String(item.id)) })
        onUpdate()
    }

    async function deleteWallet() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error, success } = await walletDelete.mutate()

            if (error) {
                return showMessage(error.data.detail, 'is-danger')
            }

            if (success) {
                onDelete()
            }
        }
    }

    const [showUpdateWallet, hideUpdateWallet] = useModal(
        <WalletUpdate
            wallet={item}
            onCancel={() => hideUpdateWallet()}
            onSuccess={() => {
                onUpdate()
                hideUpdateWallet()
            }} />,
    )

    return (
        <tr>
            <td>{item.id}</td>

            <td>
                {item.name}<span className="is-size-7 has-text-success">{item.isMain ? ' (основной)' : ''}</span>
            </td>

            <td><i className={`icon ${item.icon}`} /></td>
            <td>
                {cp('finance.delete_wallet') ? (
                    !walletDelete.isLoading ? (
                        <i onClick={() => deleteWallet()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_wallet') ? (
                    <i onClick={showUpdateWallet} className="icon pointer ion-md-create" />
                ) : null}

                <div
                    className={cn('dropdown', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <div className="dropdown-trigger">
                        <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                    </div>

                    <div className="dropdown-menu">
                        <div className="dropdown-content">
                            {cp('finance.change_wallet') && !item.isMain ? (
                                <a className="dropdown-item" onClick={changeMain}>
                                    <i className="icon ion-md-checkmark" />
                                    Сделать главным
                                </a>
                            ) : (
                                <a className="dropdown-item">
                                    <i className="icon ion-md-checkmark" />
                                    Главный
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
