/* eslint-disable no-nested-ternary */
import { Fragment, useState, MouseEvent } from 'react'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useMessage } from '../../core/hooks/message'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../core/utils/date'
import VisitItem from './VisitItem'
import { checkPermission as cp } from '../../users/utils/auth'
import Loader from '../../core/components/Loader'
import { useModal } from '../../core/hooks/modal'
import LessonUpdate from './LessonUpdate'
import { GroupType, LessonType, StudentType, VisitType } from '../types'
import { useVisits } from '../hooks/visit'
import { useLessonDelete } from '../hooks/lesson'

export type Props = {
    index: number
    item: LessonType
    group?: GroupType
    students: StudentType[]
    onDelete: () => void
}

export default function LessonItem({ item, students, group, index, onDelete }: Props) {
    const visits = useVisits(item.id, true)
    const deleteLesson = useLessonDelete(item.id)
    const [showMessage] = useMessage()
    const [showInfo, setShowInfo] = useState(false)
    const isEditable = cp('academy.change_lesson')
            && moment(item.date).format(DATE_FORMAT) === moment().format(DATE_FORMAT)

    const [showLessonUpdateModal, hideLessonUpdateModal] = useModal(group ? (
        <LessonUpdate
            group={group}
            lesson={item}
            students={students}
            onSuccess={() => hideLessonUpdateModal()} />
    ) : <Fragment />)

    function handleShowInfo() {
        setShowInfo(!showInfo)
        if (!visits.data) visits.refetch()
    }

    async function lessonDelete(event: MouseEvent) {
        event.stopPropagation()
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await deleteLesson.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete()
    }

    function lessonEdit(event: MouseEvent) {
        event.stopPropagation()
        showLessonUpdateModal()
    }

    function handleChange(id: number, newVisit: VisitType) {
        if (!visits.data) return
        const results = visits.data?.results.map((v) => v.id === id ? newVisit : v)
        visits.setData({ ...visits.data, results })
    }

    return (
        <Fragment>
            <tr className="pointer" onClick={handleShowInfo}>
                <td data-label="№" className="is-narrow">
                    {visits.isLoading ? (<Loader />) : (index + 1)}
                </td>

                <td data-label="Тема">{item.topic}</td>
                <td data-label="Дата">{moment(item.date).utc().format(DATETIME_FORMAT)}</td>

                <td data-label="Действия">
                    {isEditable ? (
                        <Fragment>
                            <i className="icon ion-md-create" onClick={lessonEdit} />
                            <i className="icon ion-md-trash" onClick={lessonDelete} />
                        </Fragment>
                    ) : null}
                </td>
            </tr>

            {showInfo && visits.data && !isEmpty(visits.data.results) ? (
                <tr>
                    <td colSpan={100}>
                        <div className="columns is-multiline mb-0">
                            {visits.data.results.map((visit) => (
                                <VisitItem
                                    key={visit.id}
                                    item={visit}
                                    onChange={(newVisit) => handleChange(visit.id, newVisit)}
                                    isEditable={isEditable} />
                            ))}
                        </div>
                    </td>
                </tr>
            ) : null}
        </Fragment>
    )
}
