import { formatDate, getDateTime } from '../../core/utils/date'
import Button from '../../core/components/Button'
import CustomerInfoGroups from './CustomerInfoGroups'
import { CustomerType } from '../types'

export type Props = {
    customer: CustomerType
    onCancel: () => void
    onChange: () => void
    onDelete: (customerId: number, customer: CustomerType) => void
    onUpdate: (customerId: number, customer: CustomerType) => void
}

export default function CustomerInfo({ customer, onCancel, onUpdate, onDelete, onChange }: Props) {

    return (
        <div>
            <b>Информация о студенте: {customer.name}</b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Ф.И.О:</td>
                        <td>{customer.name}</td>
                    </tr>

                    <tr>
                        <td>Номер телефона:</td>
                        <td>{customer.phone} {customer.phoneSecond ? <span>, {customer.phoneSecond}</span> : null}</td>
                    </tr>

                    <tr>
                        <td>Канал продаж:</td>
                        <td>{customer.advert.name}</td>
                    </tr>

                    <tr>
                        <td>Менеджер продаж:</td>
                        <td>{customer.seller.name}</td>
                    </tr>

                    <tr>
                        <td>Дата добавления:</td>
                        <td>{getDateTime(customer.createdAt)}</td>
                    </tr>

                    <tr>
                        <td>Примечание:</td>
                        <td>{customer.description}</td>
                    </tr>

                    <tr>
                        <td>День рождения:</td>
                        <td>{formatDate(customer.birthday as string)}</td>
                    </tr>
                </tbody>
            </table>

            <CustomerInfoGroups onUpdate={onUpdate} onDelete={onDelete} customer={customer} />

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;

            {onChange ? (
                <Button
                    text="Изменить"
                    onClick={onChange}
                    icon="ion-md-create"
                    className="is-primary" />
            ) : null}
        </div>
    )
}
