import { cn } from '../utils/classname'
import { format } from '../utils/number'
import { usePersistState } from '../hooks/state'
import { currency } from '../utils/currency'
import { MouseEvent } from 'react'

export type Props = {
    value: string | number
    className?: string
    curr?: 'sum' | 'dollar'
    toggle?: boolean
}

export default function Money({ value, className, toggle = true, curr = 'sum' }: Props) {
    const currencyName = currency(curr)
    const [visible, setVisible] = usePersistState('visibleMoney', true)

    function handleClick(e: MouseEvent) {
        if (!toggle) return
        setVisible(!visible)
        e.stopPropagation()
    }

    return (
        <span onClick={handleClick} className={cn({ pointer: toggle }, className)}>
            {visible || !toggle ? format(value) : '*** ***'} {currencyName}
        </span>
    )
}
