import { Form, Formik } from 'formik'
import moment from 'moment'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import { Radio, RadioGroup } from '../../core/components/Radio'
import { CustomerType } from '../types'
import { useTaskCreate } from '../../tasks/hooks/tasks'

export type Props = {
    onSuccess: () => void
    customer: CustomerType
}

type FormType = {
    title: string
    action: 'meet' | 'call'
    dueTime: string
}

export default function CustomerMeeting({ onSuccess, customer }: Props) {
    // Tomorrow as 12:00
    const dueTime = moment({ hour: 12 }).add(1, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)
    const initialValues = { title: '', action: 'call', dueTime } as FormType
    const taskCreate = useTaskCreate()

    async function onSubmit(data: FormType) {
        const user = JSON.parse(localStorage.getItem('user') || '{}')

        const title = data.action === 'call'
            ? `Позвонить клиенту "${customer.name}" (${customer.phone})`
            : `Встретится с клиентом "${customer.name}" (${customer.phone})`

        await taskCreate.mutate({ customer: customer.id, assigned: user.id, title })
        onSuccess()
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                <div className="columns">
                    <div className="column is-narrow">
                        <b>Назначить встречу со студентом {customer.name}</b>
                    </div>
                </div>

                <Input
                    name="dueTime"
                    type="datetime-local"
                    placeholder="Дата"
                    validate={required} />

                <RadioGroup name="action">
                    <Radio label="Позвонить" value="call" />
                    <Radio label="Встретиться" value="meet" />
                </RadioGroup>

                <Button
                    loading={taskCreate.isLoading}
                    text="Добавить"
                    type="submit"
                    icon="ion-md-add"
                    className="is-info" />
            </Form>
        </Formik>
    )
}
