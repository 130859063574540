import GroupForm, { FormType } from './GroupForm'
import { GroupType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupUpdate } from '../hooks/group'

export type Props = {
    onUpdate: (group: GroupType) => void
    onCancel: () => void
    group: GroupType
}

export default function GroupUpdate({ onCancel, onUpdate, group }: Props) {
    const groupUpdate = useGroupUpdate(group.id)
    const values = { ...group, teacher: group.teacher.id, room: group.room.id } as FormType

    async function onSubmit(data: FormType, actions:FormikHelpers<FormType>) {
        const response = await groupUpdate.mutate({ data })
        actions.resetForm()
        if (response.success) {
            onUpdate(response.data)
        }
    }

    return (
        <div>
            <b>Изменить группу</b><br /><br />

            <GroupForm
                initialValues={values}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={groupUpdate.isLoading} />
        </div>
    )
}
