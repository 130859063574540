import { css, StyleSheet } from 'aphrodite'
import { cn } from '../../core/utils/classname'
import { useState } from 'react'
import { useModal } from '../../core/hooks/modal'
import { useMessage } from '../../core/hooks/message'
import { checkPermission as cp } from '../../users/utils/auth'
import CustomerMeeting from './CustomerMeeting'
import StudentPayment from './StudentPayment'
import Loader from '../../core/components/Loader'
import { CustomerType } from '../types'
import { useCustomerDelete, useCustomerUpdate } from '../hooks/customer'

export type Props = {
    customer: CustomerType
    onDelete: (customerId: number) => void
    onTypeChanged: (customerId: number, data: CustomerType) => void
    onClickUpdate: () => void
    className?: string
}

export default function CustomerActions({
    customer,
    onDelete,
    onTypeChanged,
    onClickUpdate,
    className,
}: Props) {
    const [showDropdown, setShowDropdown] = useState(false)
    const customerDelete = useCustomerDelete(customer.id)
    const customerUpdate = useCustomerUpdate(customer.id)
    const [showMessage] = useMessage()

    const [showMeetingModal, hideMeetingModal] = useModal(
        <CustomerMeeting onSuccess={() => hideMeetingModal()} customer={customer} />,
    )

    const [showPaymentModal, hidePaymentModal] = useModal(
        <StudentPayment onSuccess={() => hidePaymentModal()} customer={customer} />,
    )

    async function deleteCustomer() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await customerDelete.mutate()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }

            onDelete(customer.id)
        }
    }

    async function changeType(type: string) {
        if (global.confirm('Вы действительно хотите изменить статус клиента?')) {
            const { data, error } = await customerUpdate.mutate({ data: {
                ...customer,
                advert: customer.advert.id,
                seller: customer.seller.id,
                type,
                course: customer.course ? customer.course.id : null,
            } })

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }

            onTypeChanged(customer.id, data)
            setShowDropdown(false)
        }
    }

    return (
        <div onMouseLeave={() => setShowDropdown(false)}
            onClick={(event) => event.stopPropagation()}
            className={cn(
                'dropdown is-right',
                { 'is-active': showDropdown },
                css(styles.more),
                className,
            )}>

            <div className="dropdown-trigger">
                <button className={cn('button is-white', css(styles.button))}
                    onClick={() => setShowDropdown(!showDropdown)}>
                    <i className="icon ion-md-more" />
                </button>
            </div>

            <div className="dropdown-menu">
                <div className="dropdown-content">
                    {cp('academy.change_customer') ? (
                        <a className="dropdown-item" onClick={onClickUpdate}>
                            <i className="icon ion-md-create" />
                            Изменить
                        </a>
                    ) : null}

                    {cp('academy.delete_customer') ? (
                        <a className="dropdown-item" onClick={deleteCustomer}>
                            <i className="icon ion-md-trash" />
                            Удалить
                        </a>
                    ) : null}

                    {cp('task.add_task') ? (
                        <a className="dropdown-item" onClick={showMeetingModal}>
                            <i className="icon ion-md-alarm" />
                            Встреча / Звонок
                        </a>
                    ) : null}

                    {cp('finance.add_student_payment') ? (
                        <a className="dropdown-item" onClick={showPaymentModal}>
                            <i className="icon ion-md-cash" />
                            Оплата
                        </a>
                    ) : null}

                    <a href={`tel:+${customer.phone}`} className="dropdown-item is-white">
                        <i className="icon ion-md-call" />
                        Позвонить
                    </a>

                    <hr className="dropdown-divider" />

                    {cp('academy.change_customer') && customer.type === 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('archived')}>
                            {customerUpdate.isLoading ? <Loader /> : <i className="icon ion-md-filing" />}
                            Архивировать
                        </a>
                    ) : null}

                    {cp('academy.change_customer') && customer.type === 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('rejected')}>
                            {customerUpdate.isLoading ? <Loader /> : <i className="icon ion-md-warning" />}
                            Отказал
                        </a>
                    ) : null}

                    {cp('academy.change_customer') && customer.type !== 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('active')}>
                            {customerUpdate.isLoading ? <Loader /> : <i className="icon ion-md-undo" />}
                            {customer.type === 'rejected' ? 'Активировать' : 'Воcстановить'}
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: 'transparent',
        ':hover': { backgroundColor: '#fcfcfc' },
    },
    more: {
        bottom: '0',
        left: '1rem',
    },
})
