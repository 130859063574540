import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import { format } from '../../core/utils/number'
import { useMessage } from '../../core/hooks/message'
import EmployeeUpdate from './EmployeeUpdate'
import EmployeePositions from './EmployeePositions'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import Button from '../../core/components/Button'
import Loader from '../../core/components/Loader'
import EmployeeFine from './EmployeeFine'
import { EmployeeType } from '../types'
import { useEmployeeDelete, useEmployeeFire } from '../hooks/employee'

export type Props = {
    item: EmployeeType
    onDelete: (id: number) => void
    onUpdate: (item: EmployeeType) => void
}


export default function EmployeeItem({ item, onDelete, onUpdate }: Props) {
    const employeeDelete = useEmployeeDelete(item.id)
    const employeeFire = useEmployeeFire(item.id)
    const [showMessage] = useMessage()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <EmployeeUpdate employee={item} onUpdate={(employee) => {
            onUpdate(employee)
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showFineModal, hideFineModal] = useModal(
        <EmployeeFine employee={item} onSuccess={() => {
            // onUpdate()
            hideFineModal()
        }} />,
    )

    async function deleteEmployee() {
        if (!global.confirm('Вы действительно хотите удалить?')) return
        const { error } = await employeeDelete.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete(item.id)
    }

    async function fire() {
        if (!global.confirm('Вы действительно хотите уволить этот сотрудник?')) return
        const { data } = await employeeFire.mutate()
        if (data && !data.isActive) {
            onDelete(item.id)
        }
    }

    return (
        <tr>
            <td data-label="Имя">
                <NavLink to={`/staff/employee/${item.id}`} className="has-text-dark">
                    {item.name}
                </NavLink>
            </td>

            <td data-label="День рождения">
                {item.birthday ? (
                    new Date(item.birthday).toLocaleDateString()
                ) : (
                    <span className="is-italic has-text-grey">не указан</span>
                )}
            </td>

            <td data-label="Зарплата">
                {format(item.salary)} сум
            </td>

            <td data-label="Должность">
                <EmployeePositions positions={item.positions} />
            </td>

            <td data-label="Действия">
                <Dropdown right trigger={
                    <Button icon="ion-md-more" />
                }>
                    {cp('staff.delete_employee') ? (
                        <Fragment>
                            {!employeeDelete.isLoading ? (
                                <DropdownItem icon="ion-md-trash pointer" onClick={deleteEmployee} text="Удалить" />
                            ) : <Loader className="icon" />}
                        </Fragment>
                    ) : null}

                    {cp('staff.change_employee') ? (
                        <DropdownItem icon="ion-md-create pointer" onClick={showUpdateModal} text="Изменить" />
                    ) : null}

                    {cp('staff.change_employee') && item.isActive ? (
                        <DropdownItem icon="ion-md-exit pointer" text="Уволить" onClick={fire} />
                    ) : null}

                    {cp('staff.add_fine') && item.isActive ? (
                        <DropdownItem icon="ion-md-cash pointer" text="Оштрафовать" onClick={showFineModal} />
                    ) : null}
                </Dropdown>
            </td>
        </tr>
    )
}
