/* eslint-disable no-nested-ternary */
import { cn } from '../../core/utils/classname'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import { useMessage } from '../../core/hooks/message'
import { day } from '../../core/utils/date'
import Loader from '../../core/components/Loader'
import GroupUpdate from './GroupUpdate'
import GroupMerge from './GroupMerge'
import GroupTimelineSync from './GroupTimelineSync'
import GroupStartForm from './GroupStartForm'
import { GroupType } from '../types'
import { useGroupArchive, useGroupDelete } from '../hooks/group'

export type Props = {
    item: GroupType
    onMerge: () => void
    onDelete: (id: number) => void
    onUpdate: (group: GroupType) => void
}

export default function GroupItem({ item, onDelete, onUpdate, onMerge }: Props) {
    const groupDelete = useGroupDelete(item.id)
    const changeArchiveGroup = useGroupArchive(item.id)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showMessage] = useMessage()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <GroupUpdate group={item} onUpdate={(group) => {
            onUpdate(group)
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showTimelineModal, hideTimelineModal] = useModal(
        <GroupTimelineSync group={item} onSuccess={(timelines) => {
            onUpdate({ ...item, timelines })
            hideTimelineModal()
        }} onCancel={() => {
            hideTimelineModal()
        }} />,
    )

    const [showMergeModal, hideMergeModal] = useModal(
        <GroupMerge group={item} onSuccess={() => {
            onMerge()
            hideMergeModal()
        }} onCancel={() => {
            hideMergeModal()
        }} />,
    )

    const [showStartModal, hideStartModal] = useModal(
        <GroupStartForm group={item} onSuccess={(startsAt) => {
            onUpdate({ ...item, startsAt })
            hideStartModal()
        }} onCancel={() => {
            hideStartModal()
        }} />,
    )

    async function archiveGroup() {
        if (!global.confirm('Вы действительно хотите архивировать группу?')) return

        const response = await changeArchiveGroup.mutate()

        if (response.success) {
            onUpdate({ ...item, stoppedAt: response.data.stoppedAt })
        }
    }

    async function deleteGroup() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await groupDelete.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete(item.id)
    }

    return (
        <tr className={item.stoppedAt ? 'has-text-grey' : 'has-text-dark'}>
            <td data-label="Название">
                <NavLink
                    to={`/academy/group/${item.id}/students-list`}
                    className={item.stoppedAt ? 'has-text-grey' : ''}>
                    {item.name} &nbsp;
                </NavLink>
                {!item.startsAt ? <span className="tag is-success is-light">набирается</span> : null}
            </td>

            <td data-label="Расписание">
                {item.timelines?.map((timeline) => (
                    <span key={timeline.id}>{day(timeline.day).shortTitle} &nbsp;</span>
                ))}

                {item.timelines?.[0] ? `в ${item.timelines[0].startTime.slice(0, 5)}` : 'Добавить расписание занятий'}
                <i className="icon ion-md-create pointer" onClick={showTimelineModal} />
            </td>

            <td data-label="Студенты">
                <span className="has-text-success">{item.acceptedStudentsCount}</span>
            </td>

            <td data-label="Учитель">{item.teacher ? item.teacher.name : ''}</td>
            <td data-label="Кабинет">{item.room ? item.room.name : ''}</td>

            <td data-label="Действия">
                {cp('academy.delete_group') ? (
                    !groupDelete.isLoading ? (
                        <i onClick={() => deleteGroup()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('academy.change_group') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}

                {cp('academy.change_group') ? (
                    <div
                        className={cn('dropdown is-right', { 'is-active': showDropdown })}
                        onMouseLeave={() => setShowDropdown(false)}>

                        <div className="dropdown-trigger">
                            <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                        </div>

                        <div className="dropdown-menu" id="dropdown-menu">
                            <div className="dropdown-content">
                                {item.startsAt && !item.stoppedAt ? (
                                    <a className="dropdown-item" onClick={archiveGroup}>
                                        <i className="icon ion-md-checkmark" />
                                        Отправить в архив
                                    </a>
                                ) : null}

                                {item.startsAt && item.stoppedAt ? (
                                    <a className="dropdown-item" onClick={archiveGroup}>
                                        <i className="icon ion-md-checkmark" />
                                        Вытащит из архива
                                    </a>
                                ) : null}

                                {!item.startsAt ? (
                                    <a className="dropdown-item" onClick={showStartModal}>
                                        <i className="icon ion-md-play" />
                                        Активировать группу (Старт)
                                    </a>
                                ) : null}

                                {!item.stoppedAt ? (
                                    <a className="dropdown-item" onClick={showMergeModal}>
                                        <i className="icon ion-md-git-pull-request" />
                                        Объединить с
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </td>
        </tr>
    )
}
