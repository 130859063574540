import StatisticFinanceOutcomeChart from './StatisticsFinanceOutcomeChart'
import Card from '../../core/components/Card'
import { useOutcomeStats } from '../hooks/stats'

export type Props = {
    startDate: string
    endDate: string
}

export default function StatisticsFinanceOutcome({ startDate, endDate }: Props) {
    const outcome = useOutcomeStats(startDate, endDate)

    return (
        <div className="columns">
            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика Расходов
                    </div>

                    <StatisticFinanceOutcomeChart
                        data={outcome.data || []}
                        loading={outcome.isLoading}
                        field="outcomeAmount" />
                </Card>
            </div>
        </div>
    )
}
