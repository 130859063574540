import ProtectedRoute from '../core/components/ProtectedRoute'
import NotFound from '../core/pages/NotFound'
import { Route, Routes } from 'react-router-dom'
import SettingsUser from './pages/SettingsUser'
import SettingsAcademy from './pages/SettingsAcademy'
import SettingsFinance from './pages/SettingsFinance'

export default function SettingsRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="user" element={<SettingsUser/>} />
                <Route path="academy" element={<SettingsAcademy/>} />
                <Route path="finance" element={<SettingsFinance/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
