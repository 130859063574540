export const PAYMENT_STUDENT = '/finance/student_payment'

export const GROUPS_MERGE = '/academy/group/merge'
export const GROUPS_LIST = '/academy/group/'
export const GROUPS_DETAIL = '/academy/group/{id}'
export const GROUPS_ARCHIVE = '/academy/group/archive/{id}'
export const GROUPS_START = '/academy/group/start/{id}'

export const SALES_CHANNEL_LIST = 'academy/sales_channel/'
export const SALES_CHANNEL_DETAIL = 'academy/sales_channel/{id}'
export const SALES_CHANNEL_CHANGE_MAIN = '/academy/sales_channel/change_main/{id}'

export const ROOM_LIST = '/academy/room/'
export const ROOM_DETAIL = '/academy/room/{id}'
export const ROOM_CHANGE_MAIN = '/academy/room/change_main/{id}'

export const STUDENT_LIST = '/academy/student/'
export const STUDENT_DETAIL = '/academy/student/{id}'
export const STUDENT_STOP = '/academy/student/{id}/stop'

export const CUSTOMER_LIST = '/academy/customer/'
export const CUSTOMER_BIRTHDAYS = '/academy/customer/birthdays/'
export const CUSTOMER_DETAIL = '/academy/customer/{id}'
export const CUSTOMER_MOVE = '/academy/customer/{id}/move'

export const STATUS_LIST = '/academy/status/'
export const STATUS_DETAIL = '/academy/status/{id}'
export const STATUS_MOVE = '/academy/status/{id}/move'

export const COURSES_LIST = '/academy/course/'
export const COURSE_DETAIL = '/academy/course/{id}'

export const LESSONS_LIST = '/academy/lesson/'
export const LESSON_DETAIL = '/academy/lesson/{id}'

export const VISITS_LIST = '/academy/visit/'
export const VISIT_DETAIL = '/academy/visit/{id}'

export const DEBTORS_GENERAL = '/academy/debtors/general'
export const DEBTORS_LIST = '/academy/debtors/'
export const DEBTORS_DETAIL = '/academy/debtors/{id}'
export const DEBTORS_DISCOUNT = '/academy/debtors/{id}/discount'
export const DEBTORS_DEACTIVATION = '/academy/debtors/{id}/deactivation'
export const DEBTOR_DELAYS_LIST = '/academy/debtors/{id}/delays'

export const CASHBACK_LIST = '/finance/cashback/'

export const TIMELINE_SYNC = '/academy/group-sync/'
export const TIMELINE = '/staff/employee-timeline'

export const PARENT_LIST = '/academy/parent/'

export const PARENT_DETAIL = '/academy/parent/{id}'
export const PARENT_STUDENT_LIST = '/academy/parent-student/'
export const PARENT_STUDENT_DETAIL = '/academy/parent-student/{id}'
