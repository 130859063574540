import { Fragment } from 'react'
import range from 'lodash/range'
import random from '../utils/number'
import SkeletonLine from './SkeletonLine'

export type Props = {
    columns: number
    rows: number
}


export default function TableSkeleton({ columns, rows }: Props) {
    return (
        <Fragment>
            {range(rows).map((r) => (
                <tr key={r}>
                    {range(columns).map((c) => (
                        <td key={c} className="skeleton">
                            <SkeletonLine width={`${random(5, 9)}0%`}/>
                        </td>
                    ))}
                </tr>
            ))}
        </Fragment>
    )
}
