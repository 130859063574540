import { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import Select from '../../core/components/Select'
import Input from '../../core/components/Input'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Table from '../../core/components/Table'
import { causes } from '../utils/cause'
import { useMessage } from '../../core/hooks/message'
import EmployeeFineItem from './EmployeeFineItem'
import { EmployeeType, FineType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useFineCreate, useFines } from '../hooks/fine'

export type Props = {
    employee: EmployeeType
    onSuccess: () => void
}

type FormType = Omit<FineType, 'id' | 'createdAt'> & {
    employee: number
}

export default function EmployeeFine({ employee, onSuccess }: Props) {
    const [page, setPage] = useState(1)
    const fineCreate = useFineCreate()
    const fines = useFines(employee.id)
    const [showMessage] = useMessage()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const { error } = await fineCreate.mutate({ data })

        if (error) {
            showMessage(error.data.details, 'is-danger')
            return
        }

        actions.resetForm()
        onSuccess()
    }

    return (
        <Fragment>
            <Formik onSubmit={onSubmit}
                initialValues={{ cause: 'lateness', amount: 0, employee: employee.id } as FormType}>

                <Form>
                    <b>Штраф сотрудника &nbsp; {employee.name}</b><br /><br />

                    <div className="columns">
                        <div className="column">
                            <Select
                                name="cause"
                                options={Object.entries(causes).map(([key, value]) => ({ id: key, name: value }))}
                                validate={required} />
                        </div>

                        <div className="column">
                            <Input
                                name="amount"
                                type="number"
                                placeholder="Сумма"
                                validate={required} />
                        </div>

                        <div className="column is-narrow">
                            <Button
                                loading={fineCreate.isLoading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info" />
                        </div>
                    </div>
                </Form>
            </Formik>

            <Table
                totalCount={fines.data ? fines.data.count : 0}
                columns={{
                    cause: 'Причина',
                    amount: 'Сумма',
                    createdAt: 'Дата создания',
                    action: '',
                }}
                loading={fines.isLoading}
                items={fines.data ? fines.data.results : []}
                onPageChange={setPage}
                activePage={page}
                renderItem={(fine) => <EmployeeFineItem key={fine.id} item={fine} onDelete={fines.refetch} />} />
        </Fragment>
    )
}
