import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react'
import Message from './Message'
import Modal from './Modal'

export type ContextType = {
    setText: Dispatch<SetStateAction<string | undefined>>
    setClassName: Dispatch<SetStateAction<string>>
    setModalComponent: Dispatch<SetStateAction<ReactElement | undefined>>
}

export const Context = createContext<ContextType>({} as ContextType)

export type Props = {
    children?: ReactNode
}

export default function BaseContextWrapper({ children }: Props) {
    const [text, setText] = useState<string | undefined>()
    const [className, setClassName] = useState<string>('')
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined>()

    return (
        <Context.Provider value={{ setText, setClassName, setModalComponent }}>
            {children}

            {text ? (
                <Message
                    text={text}
                    className={className}
                    closeMessage={() => setText(undefined)} />
            ) : null}

            {modalComponent ? (
                <Modal
                    isActive
                    onClose={() => setModalComponent(undefined)}>
                    {modalComponent}
                </Modal>
            ) : null}
        </Context.Provider>
    )
}
