
import { MouseEventHandler, ReactNode, useState } from 'react'
import { cn } from '../utils/classname'

export type Props = {
    trigger: ReactNode
    children: ReactNode
    right?: boolean
}

export default function Dropdown({ trigger, children, right }: Props) {
    const [active, setActive] = useState(false)

    return (
        <div onMouseLeave={() => setActive(false)}
            className={cn('dropdown', { 'is-active': active, 'is-right': right })}>
            <div className="dropdown-trigger pointer" onClick={() => setActive(!active)}>
                {trigger}
            </div>

            {active ? (
                <div className="dropdown-menu">
                    <div className="dropdown-content">{children}</div>
                </div>
            ) : null}
        </div>
    )
}

export type ItemProps = {
    text?: ReactNode
    icon?: string
    badge?: string | number
    active?: boolean
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

export function DropdownItem({ text, icon, active, onClick, badge, ...props }: ItemProps) {

    return (
        <a {...props} className={cn('dropdown-item pr-3', { 'is-active': active })} onClick={onClick}>
            {icon ? <span className={cn('icon', icon)} /> : null}
            {text}
        </a>
    )
}
