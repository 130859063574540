import { ButtonHTMLAttributes } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { cn } from '../utils/classname'

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    text?: string
    icon?: string
    loading?: boolean
    className?: string
}

export default function Button({ text, onClick, loading, className, icon, type = 'button', ...rest }: Props) {
    return (
        <button
            onClick={onClick}
            type={type}
            className={cn('button', className, { 'is-loading': loading })}
            {...rest}>

            {icon ? <i className={cn('icon', text ? css(styles.icon) : '', icon)} /> : null }
            {text}
        </button>
    )
}


const styles = StyleSheet.create({
    icon: {
        marginRight: '0.2rem',
    },
})
