import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { GroupType } from '../types'
import { useEmployeesSimple } from '../../staff/hooks/employee'
import { useGroupsList } from '../hooks/group'

export type Props = {
    group: GroupType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}

export type FormType = {
    name: string
    price: number
    teacher: string
    secondGroup: string
    paymentDay: string
}

export default function GroupMergeForm({ onSubmit, onCancel, loading, group }: Props) {
    const groups = useGroupsList()
    const employee = useEmployeesSimple({ position: 'teacher' })
    const groupOptions = groups.data?.results.filter((i) => !i.stoppedAt && i.id !== group.id) || []

    const values = {
        name: '',
        price: 0,
        teacher: '',
        secondGroup: '',
        paymentDay: group.paymentDay,
    } as FormType

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Select
                    empty
                    label="Группа"
                    name="secondGroup"
                    options={groupOptions}
                    loading={groups.isLoading}
                    help="Вы можете добавить нового группу в меню Академия / Группы"
                    validate={required} />

                <Input
                    name="name"
                    disabled={loading}
                    validate={required}
                    label="Название"
                    placeholder="Введите имя группы" />

                <Select
                    name="teacher"
                    options={employee.data ? employee.data.results : []}
                    label="Учитель"
                    empty
                    loading={employee.isLoading}
                    help="Вы можете добавить нового учителя в меню персонал"
                    validate={required} />

                <Input
                    name="paymentDay"
                    label="День оплаты"
                    help="Выберити день оплаты студента"
                    validate={required}
                    type="date" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
