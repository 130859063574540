import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { cn } from '../../core/utils/classname'
import moment from 'moment'
import Layout from '../../core/components/Layout'
import Table from '../../core/components/Table'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { checkPermission as cp } from '../../users/utils/auth'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import EmployeeSalaryItem from '../components/EmployeeSalaryItem'
import { format } from '../../core/utils/number'
import EmployeePositions from '../components/EmployeePositions'
import Loader from '../../core/components/Loader'
import EmployeeInvite from '../components/EmployeeInvite'
import { useParams } from '../../core/hooks/queryString'
import { useEmployee, useEmployeeSalary, useEmployeeSalaryList } from '../hooks/employee'
import InfiniteTable from '../../core/components/InfiniteTable'

export default function EmployeeDetail() {
    const { employeeId } = useParams()
    const employeeSalary = useEmployeeSalary(employeeId!)
    const transactions = useEmployeeSalaryList(employeeId!)
    const employeeDetail = useEmployee(employeeId!)
    const employee = employeeDetail.data

    const [showInviteModal, hideInviteModal] = useModal(employee ? (
        <EmployeeInvite
            employee={employee}
            onCancel={() => hideInviteModal()}
            onSuccess={() => {
                hideInviteModal()
                employeeDetail.refetch()
            }} />
    ) : <Loader />)

    if (!cp('staff.view_employee')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <NavLink className="has-text-black button is-white" to="/staff/employee">
                <i className="icon ion-md-arrow-back is-size-4" />
            </NavLink>

            <span className="is-size-4">
                Информация о сотруднике : {employee?.name}
            </span>

            {!employeeDetail.isLoading ? (
                <div>
                    {cp('staff.add_employeeinvitation') && !employee?.user && !employee?.invited ? (
                        <article className="message is-warning">
                            <div className="message-body">
                                <b>Данный сотрудник не зарегистрирован в системе!</b><br />
                                Что пригласить нажмите на кнопку ниже и введите емейл сотрудника.
                                Мы отправим ему(ей) письмо с ссылкой на приватную страницу быстрой регистрации.<br />
                                <br />

                                <Button
                                    text="Пригласить"
                                    icon="ion-md-person-add"
                                    onClick={showInviteModal}
                                    className="is-primary is-small" />
                            </div>
                        </article>
                    ) : null}

                    <hr />
                    <div className="columns">
                        <b className="column">Ф.И.О: </b>
                        <span className="column">{employee?.name}</span>

                        <b className="column">Доля от продаж: </b>
                        <span className="column">{employee?.sellerShare}%</span>
                    </div>

                    <div className="columns">
                        <b className="column">Дата рождения: </b>
                        <span className="column">{employee?.birthday}</span>

                        <b className="column">Фиксированная зарплата: </b>
                        <span className="column">{employee?.salary} Сум</span>
                    </div>

                    <div className="columns">
                        <b className="column">Системный пользователь: </b>
                        <span className="column">
                            {employee?.user ? employee?.user.email : (
                                <Fragment>
                                    <i className="has-text-grey">
                                        {employee?.invited ? 'Был приглашен' : 'не привязан'}
                                    </i> &nbsp;

                                    {cp('staff.add_employeeinvitation') ? (
                                        <Button
                                            text={employee?.invited ? 'Повторно пригласить' : 'Пригласить'}
                                            icon="ion-md-person-add"
                                            onClick={showInviteModal}
                                            className={cn('is-small', employee?.invited ? 'is-warning' : 'is-link')} />
                                    ) : null}
                                </Fragment>
                            )}
                        </span>

                        <b className="column">Должность: </b>
                        <span className="column">
                            <EmployeePositions positions={employee?.positions} />
                        </span>
                    </div>

                    <span className="is-size-4">История зарплат</span>

                    <Table
                        responsive
                        loading={employeeSalary.isLoading}
                        totalCount={employeeSalary.data?.length || 0}
                        pageSize={employeeSalary.data ? employeeSalary.data.length : 0}
                        items={employeeSalary.data ? employeeSalary.data : []}
                        columns={{ name: 'Месяц', salary: 'Зарплата', actions: '' }}
                        renderItem={(item) => (
                            <EmployeeSalaryItem key={item.month} item={item} employee={employee!}/>
                        )}/>
                </div>

            ) : <Loader large center />}
            <hr />


            <br />

            <span className="is-size-4">
                История выплат
            </span>

            {cp('finance.view_salary') ? (
                <InfiniteTable
                    responsive
                    query={transactions}
                    columns={{ id: '№', amount: 'Сумма', wallet: 'Кошелек', createdAt: 'Дата создания' }}
                    renderItem={(transaction) => (
                        <tr key={transaction.id}>
                            <td data-label="№">{transaction.id}</td>

                            <td data-label="Сумма">
                                <p className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                                    {format(transaction.amount)}
                                </p>
                            </td>

                            <td data-label="Кошелек">{transaction.wallet.name}</td>
                            <td data-label="Дата создания">{moment(transaction.createdAt).calendar()}</td>
                        </tr>
                    )} />
            ) : null}
        </Layout>
    )
}
