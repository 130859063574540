import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import { TimelineItemLesson } from './TimelineItemLesson'
import { day as weekDay } from '../../core/utils/date'
import { GroupType } from '../types'

export type Props = {
    groups: GroupType[]
    day: string
}

export function TimelineItemColumn({ groups, day }: Props) {
    const lessons = groups.filter((group) => find(group.timelines, { day }))
    const sortedLessons = sortBy(lessons, [(group) => find(group.timelines, { day })?.startTime])

    return (
        <td data-label={weekDay(day).title}>
            {lessons.length > 0 ? sortedLessons.map((group) => (
                <span key={group.id}>
                    <TimelineItemLesson group={group} day={day} key={group.id} />
                    <br />
                </span>
            )) : '-'}
        </td>
    )
}
