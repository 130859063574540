import Card from '../../core/components/Card'
import StatisticsAcademyStudentLine from './StatisticsAcademyStudentLine'
import { useEndStudentsStats } from '../hooks/stats'

export type Props = {
    startDate: string
    endDate: string
}

export default function StatisticsAcademyStudent({ startDate, endDate }: Props) {
    const students = useEndStudentsStats(startDate, endDate)

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Статистика Студентов
            </div>

            <StatisticsAcademyStudentLine
                data={students.data || []}
                loading={students.isLoading} />
        </Card>
    )
}
