export const BulmaColors = [
    { key: 'is-black', name: 'Чёрный' },
    { key: 'is-light', name: 'Серый' },
    { key: 'is-dark', name: 'Тёмный' },
    { key: 'is-primary', name: 'Бирюзовый' },
    { key: 'is-link', name: 'Синий' },
    { key: 'is-info', name: 'Голубой' },
    { key: 'is-success', name: 'Зёленый' },
    { key: 'is-warning', name: 'Жёлтый' },
    { key: 'is-danger', name: 'Красный' },
]
