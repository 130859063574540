import { Formik } from 'formik'
import Button from '../../core/components/Button'
import { FormSubmit, Pagination } from '../../core/types'
import { SimpleStudentType, StudentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useStudents } from '../hooks/student'
import Loader from '../../core/components/Loader'
import { css, StyleSheet } from 'aphrodite'
import AsyncSelect from 'react-select/async'
import { loadChildren } from '../hooks/parents'

export type Props = {
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
    students: SimpleStudentType[] | undefined
}

export type FormType = {
    student: string,
}

type OptionType = {
    value: number | undefined,
    label: string | undefined
}

type SetValue = FormikHelpers<FormType>['setFieldValue']

let timeoutId:ReturnType<typeof setTimeout> | null = null

export default function ParentAddChildForm({ onSubmit, onCancel, students, loading }: Props) {
    const children = useStudents({ })
    const childrenIds = students?.map(s => s.student.id) || []
    const childrenOptions = children.data?.results.filter((g) => !childrenIds.includes(g.id)) || []
    const options: OptionType[] = childrenOptions?.map(child =>
        ({ value: child?.id, label: child?.customer?.name })) || []

    async function onChildChange(newValue: OptionType | null, setFieldValue: SetValue) {
        await setFieldValue('student', newValue?.value)
    }

    async function promiseOptions(inputValue: string) {
        return new Promise<OptionType[]>(async (resolve) => {
            if (timeoutId) clearTimeout(timeoutId)
            timeoutId = setTimeout(async () => {
                try {
                    const results: Pagination<StudentType> = await loadChildren(inputValue)
                    const newChildrenIds = students?.map(s => s.student.id) || []
                    const newChildrenOptions = results?.results.filter((g) => !newChildrenIds.includes(g.id)) || []
                    const newOptions: OptionType[] = newChildrenOptions?.map(child =>
                        ({ value: child?.id, label: child?.customer?.name })) || []
                    resolve(newOptions)
                } catch (error) {
                    console.log(error)
                }
            }, 300)
        })
    }

    if (children.isLoading) {
        return (
            <tr>
                <th colSpan={3}>
                    <div className="columns is-centered my-3">
                        <Loader/>
                    </div>
                </th>
            </tr>
        )
    }

    return (
        <Formik initialValues={{} as FormType} enableReinitialize onSubmit={onSubmit}>
            {({ setFieldValue, handleSubmit }) => (
                <tr>
                    <td className={css(styles.select)}>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={options}
                            isLoading={children.isLoading}
                            loadOptions={promiseOptions}
                            onChange={(newValue) => onChildChange(newValue, setFieldValue)}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    width: '100%',
                                }),
                            }}
                        />
                    </td>

                    <td colSpan={2}>
                        <Button
                            loading={loading}
                            onClick={() => handleSubmit()}
                            icon="ion-md-checkmark"
                            className="is-success is-small"/> &nbsp;

                        <Button
                            icon="ion-md-close"
                            disabled={loading}
                            onClick={onCancel}
                            className="is-small"/>
                    </td>
                </tr>
            )}
        </Formik>
    )
}


const styles = StyleSheet.create({
    select: {
        maxWidth: 200,
        width: '50%',
        '@media (max-width: 450px)': {
            maxWidth: 150,
        },
    },
})
