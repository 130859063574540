import { Form, Formik } from 'formik'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import { required } from '../../core/utils/validators'
import ServerError from '../../core/components/ServerError'
import { useMessage } from '../../core/hooks/message'
import { EmployeeType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useEmployeeInvite } from '../hooks/employee'

export type Props = {
    employee: EmployeeType
    onSuccess: () => void
    onCancel: () => void
}

type FormType = { email: string }

export default function EmployeeInvite({ employee, onSuccess, onCancel }: Props) {
    const invite = useEmployeeInvite(employee.id)
    const [showMessage] = useMessage()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const { success } = await invite.mutate({ data })
        if (success) {
            actions.resetForm()
            showMessage('Мы отправили приглашение по электронной почте', 'is-success')
            onSuccess()
        }
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{ email: '' }}>
            <Form>
                <b>Пригласить сотрудника в качестве пользователя</b><br /><br />
                <ServerError error={invite.error} />
                <Input
                    name="email"
                    type="text"
                    placeholder="Электронная почта"
                    validate={required} />

                <Button
                    loading={invite.isLoading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
