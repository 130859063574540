import ColumnForm from './ColumnForm'
import { StatusType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useStatusCreate } from '../../academy/hooks/status'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function ColumnCreate({ onSuccess, onCancel }: Props) {
    const statusCreate = useStatusCreate()

    async function onSubmit(data: StatusType, actions: FormikHelpers<StatusType>) {
        await statusCreate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить этап</b><br /><br />

            <ColumnForm
                loading={statusCreate.isLoading}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}
