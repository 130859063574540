import { Formik, Form } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import { DebtorType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useDebtorUpdate } from '../hooks/debtor'

export type Props = {
    debtor: DebtorType
    onSuccess: (data: DebtorType) => void
}

export default function DebtorUpdate({ debtor, onSuccess }: Props) {
    const discount = useDebtorUpdate(debtor.id)

    async function onSubmit(data: DebtorType, actions: FormikHelpers<DebtorType>) {
        await discount.mutate({ data })
        actions.resetForm()
        onSuccess(data)
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={debtor}>
            <Form>
                <b>Редактировать догл студента &nbsp; {debtor.student.customer?.name }</b><br /><br />

                <Input
                    name="amount"
                    type="number"
                    label="Сумма долга"
                    placeholder="Скидка"
                    validate={required} />

                <div className="columns mb-0">
                    <div className="column">
                        <Input
                            name="startDate"
                            type="date"
                            label="C"
                            placeholder="Скидка"
                            validate={required} />
                    </div>

                    <div className="column">
                        <Input
                            name="endDate"
                            type="date"
                            label="По"
                            placeholder="Скидка"
                            validate={required} />
                    </div>
                </div>

                <Input
                    name="discount"
                    type="number"
                    label="Сумма скидки"
                    placeholder="Скидка"
                    validate={required} />

                <Input
                    name="discountReason"
                    type="text"
                    label="Причина скидки"
                    placeholder="Причина"
                    validate={required} />

                <Input
                    name="deactivationReason"
                    type="text"
                    label="Причина деактивации"
                    placeholder="Причина"
                    validate={required} />

                <Button
                    loading={discount.isLoading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-add"
                    className="is-info" />
            </Form>
        </Formik>
    )
}
