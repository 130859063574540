import { cn } from '../utils/classname'
import InfiniteList, { Props as InfiniteListProps } from './InfiniteList'
import { ModelType, Optional } from '../types'
import TableSkeleton from './TableSkeleton'
import Loader from './Loader'
import { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'

export type Columns<Item> = {
    [key in keyof Partial<Item>]: string
} & {
    actions?: string
}

export type Props<Item extends ModelType> = Optional<InfiniteListProps<Item>, 'renderItem'> & {
    columns: Columns<Item>
    className?: string
    responsive?: boolean
}

export default function InfiniteTable<Item extends ModelType>({
    className,
    responsive,
    columns,
    query,
    renderItem,
}: Props<Item>) {
    const render = renderItem! ? renderItem : (item: Item) => renderItemDefault(item, columns)
    const notMore = <tr><td colSpan={10} className="has-text-centered has-text-grey">Все загружено</td></tr>
    const loader = <TableSkeleton columns={Object.keys(columns).length} rows={5} />

    return (
        <Fragment>
            {query.isFetching && !query.isLoading && (<Loader className={css(styles.loader)} />)}

            <table className={cn(className || 'table is-striped is-fullwidth', { responsive })}>
                <thead>
                    <tr>
                        {Object.entries(columns).map(([key, value]) => (
                            <th key={key}>{value as string}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    <InfiniteList
                        disableObserver
                        query={query}
                        renderItem={render}
                        loader={loader}
                        notMore={notMore} />
                </tbody>
            </table>

            {query.observer}
        </Fragment>
    )
}


function renderItemDefault<Item extends ModelType>(item: Item, columns: Columns<Item>) {
    return (
        <tr key={item.id}>
            {Object.keys(columns).map((key) => (
                <td>{item[key as keyof Item] as unknown as string}</td>
            ))}
        </tr>
    )
}


const styles = StyleSheet.create({
    loader: {
        marginBottom: '-1em',
        float: 'right',
    },
})
