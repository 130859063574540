import { StyleSheet, css } from 'aphrodite'
import { cn } from '../utils/classname'

export type Props = {
    large?: boolean
    center?: boolean
    className?: string
    padded?: boolean
    show?: boolean
    inline?: boolean
    text?: string
}

export default function Loader({ large, center, className, text, padded = false, show = true, inline = false }: Props) {
    if (!show) return null

    const classes = cn(className, 'loader', {
        'is-size-1': large,
        'is-inline-block': inline || text,
        [css(styles.center)]: center,
        [css(styles.padded)]: padded,
    })

    if (text) {
        return (
            <span>
                <i className={classes} />
                {text}
            </span>
        )
    }

    return <i className={classes} />
}

const styles = StyleSheet.create({
    center: { margin: '0 auto' },
    padded: { marginTop: '2rem', marginBottom: '2rem' },
})
