import find from 'lodash/find'
import { Formik, Form } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { FormikHelpers } from 'formik/dist/types'
import { useTransactionCategories } from '../hooks/transactionCategories'
import { useEmployeesList } from '../../staff/hooks/employee'
import { useSalesChannel } from '../../academy/hooks/salesChannel'

export type Props = {
    values?: FormType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
    type: 'income' | 'outcome'
}

export type FormType = {
    amount: string | number
    category: string
    description: string
    advert: string
    employee: string
}

type SetValue = FormikHelpers<FormType>['setFieldValue']

export default function TransactionForm({ loading, onSubmit, onCancel, values, type }: Props) {
    const initialValues = { amount: '', category: '', description: '', advert: '', employee: '', ...values }
    const categories = useTransactionCategories({ type })

    const adverts = useSalesChannel({}, true)
    const employee = useEmployeesList({ size: 1000 }, true)

    const marketingCategory = find(categories.data?.results || [], { systemName: 'advert' })
    const salaryCategory = find(categories.data?.results || [], { systemName: 'salary' })

    function onCategoryChange(categoryId: string, setFieldValue: SetValue) {
        // get category by id
        const category = find(categories.data?.results, { id: parseInt(categoryId, 10) })
        // autofill form values
        if (marketingCategory?.id === Number(categoryId)) adverts.refetch()
        if (salaryCategory?.id === Number(categoryId)) employee.refetch()
        setFieldValue('category', categoryId)
        if (category?.defaultAmount) { setFieldValue('amount', category?.defaultAmount) }
        if (category?.descriptionTemplate) { setFieldValue('description', category?.descriptionTemplate) }
    }

    function handleSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        let amount = Math.abs(Number(data.amount))
        if (type === 'outcome') {
            amount *= -1
        }

        onSubmit({ ...data, amount }, actions)
    }

    function onChangeAdvert(value: string, setFieldValue: SetValue) {
        setFieldValue('advert', value)
        if (value === '') return
        const advert = find(adverts.data?.results || [], { id: Number(value) })
        setFieldValue('description', `Оплата за рекламу, канал: ${advert?.name || ''}.`)
    }

    function onChangeEmployee(value: string, setFieldValue: SetValue) {
        setFieldValue('employee', value)
        if (value === '') return
        const pickedEmployee = find(employee.data?.results || [], { id: Number(value) })
        setFieldValue('description', `Зарплата сотрудника ${pickedEmployee?.name}.`)
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ setFieldValue, values: { category } }) => (
                <Form>
                    <div className="field">
                        <div className="field-body">
                            <Select
                                empty
                                name="category"
                                label="Категория"
                                help="Вы можете добавить новую категорию в настройках"
                                loading={categories.isLoading}
                                options={categories.data ? categories.data.results : []}
                                onChange={({ target }) => onCategoryChange(target.value, setFieldValue)}
                                validate={required} />

                            {marketingCategory && marketingCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="advert"
                                    label="Канал продаж"
                                    loading={adverts.isLoading}
                                    options={adverts.data ? adverts.data.results : []}
                                    help="Канал продаж можно добавить в настройках"
                                    onChange={({ target }) => onChangeAdvert(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}

                            {salaryCategory && salaryCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="employee"
                                    label="Сотрудник"
                                    loading={employee.isLoading}
                                    options={employee.data ? employee.data.results : []}
                                    help="Вы можете добавить нового сотрудника в меню Персонал"
                                    onChange={({ target }) => onChangeEmployee(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}
                        </div>
                    </div>

                    <Input name="amount" type="number" label="Сумма" validate={required} />
                    <Input name="description" component="textarea" label="Описание" validate={required} />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
