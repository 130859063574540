import { cn } from '../utils/classname'
import { ReactNode } from 'react'

export type Props = {
    children?: ReactNode
    className?: string
}

export default function Card({ children, className }: Props) {
    return (
        <div className={cn('card', className)}>
            <div className="card-content">
                {children}
            </div>
        </div>
    )
}
