import { ParentType } from '../types'
import { checkPermission as cp } from '../../users/utils/auth'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import ParentInfo from './ParentInfo'
import ParentUpdate from './ParentUpdate'
import { useParentDelete } from '../hooks/parents'
import { useMessage } from '../../core/hooks/message'
import Loader from '../../core/components/Loader'
import ParentInvite from './ParentInvite'

export type Props = {
    item: ParentType
    onUpdate: (parent: ParentType) => void
    onDelete: (id: number) => void
}

export default function ParentItem({ item, onUpdate, onDelete }: Props) {
    const parentDelete = useParentDelete(item.id)
    const [showMessage] = useMessage()

    const [showInfoModal, hideInfoModal] = useModal(
        <ParentInfo onCancel={() => hideInfoModal()} parent={item} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ParentUpdate
            parent={item}
            onUpdate={(parent) => {
                onUpdate(parent)
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()} />,
    )

    const [showInviteModal, hideInviteModal] = useModal(parent ? (
        <ParentInvite
            itemId={item.id}
            onCancel={() => hideInviteModal()}/>
    ) : <Loader />)

    async function deleteParent() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await parentDelete.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete(item.id)
    }

    return (
        <tr className='has-text-dark'>
            <td data-label="Имя">
                <span
                    onClick={showInfoModal}
                    className='has-text-dark pointer'>
                    {item.name} &nbsp;
                </span>
            </td>

            <td data-label="Номер телефона">
                <span>+{item.phone}</span>
            </td>

            <td data-label="Telegram">
                {item.isRegistered ?
                    <span>
                        <i className="icon ion-md-checkmark-circle has-text-success"/>
                        Подключён
                    </span>
                    :
                    <Button
                        text="Пригласить"
                        icon="ion-md-send"
                        className="is-link is-small is-outlined"
                        onClick={showInviteModal}/>
                }
            </td>

            <td data-label="Действия">
                {cp('academy.delete_group') ? (
                    !parentDelete.isLoading ? (
                        <i onClick={() => deleteParent()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('academy.change_group') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create"/>
                ) : null}
            </td>
        </tr>
    )
}
