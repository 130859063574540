import GroupForm, { FormType } from './GroupForm'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupCreate } from '../hooks/group'
import { GroupType } from '../types'

export type Props = {
    onSuccess: (group: GroupType) => void
    onCancel: () => void
}

export default function GroupCreate({ onCancel, onSuccess }: Props) {
    const groupCreate = useGroupCreate()

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const response = await groupCreate.mutate({ data })
        actions.resetForm()

        if (response.success) {
            onSuccess(response.data)
        }
    }

    return (
        <div>
            <b>Создать Группу</b><br /><br />
            <GroupForm onSubmit={onSubmit} onCancel={onCancel} loading={groupCreate.isLoading} />
        </div>
    )
}
