import TransactionForm, { FormType } from './TransactionForm'
import { TransactionType } from '../types'
import { useTransactionUpdate } from '../hooks/transaction'

export type Props = {
    transaction: TransactionType
    walletId: number | string
    onCancel: () => void
    onSuccess: (item: TransactionType) => void
}

export default function TransactionUpdate({ transaction, walletId, onSuccess, onCancel }: Props) {
    const transactionUpdate = useTransactionUpdate(transaction.id)

    async function onSubmit(data: FormType) {
        const response = await transactionUpdate.mutate({ data: { ...data, wallet: walletId } })

        if (response.success) {
            onSuccess(response.data)
        }
    }

    return (
        <div>
            <b>Изменить {transaction.amount < 0 ? 'расход' : 'приход'}</b><br /><br />

            <TransactionForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                type={transaction.amount < 0 ? 'outcome' : 'income'}
                loading={transactionUpdate.isLoading}
                values={{
                    ...transaction,
                    amount: Math.abs(Number(transaction.amount)),
                } as unknown as FormType} />
        </div>
    )
}
