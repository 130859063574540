import { useLoad, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { VisitType } from '../types'
import { VISIT_DETAIL, VISITS_LIST } from '../urls'

export function useVisits(lesson: number, manual = false) {
    return useLoad<Pagination<VisitType>>(
        { url: VISITS_LIST, params: { lesson } },
        [lesson],
        { manualLoad: manual },
    )
}

export function useVisitUpdate(id: number) {
    return usePutRequest<VisitType>({ url: VISIT_DETAIL.replace('{id}', String(id)) })
}
