import { cn } from '../../core/utils/classname'
import { StyleSheet, css } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import Card from '../../core/components/Card'
import Progress from '../../core/components/Progress'
import Money from '../../core/components/Money'
import { GeneralMonthType } from '../types'

export type Props = {
    item: GeneralMonthType
    active?: boolean
}

export default function DebtorsMonthItem({ item, active = false }: Props) {
    const navigate = useNavigate()
    const total = item.paid + item.debt
    const percent = Math.round((item.paid * 100) / total)
    const percentText = percent >= 15 ? `${percent}%` : undefined
    let color = '#48c78e'
    color = percent <= 75 ? '#ffd257' : color
    color = percent <= 25 ? '#cc0f35' : color

    return (
        <div onClick={() => navigate(`/academy/debtors?month=${item.month}`)} className="pointer">
            <Card className={cn('pointer', active ? css(styles.active) : null)}>
                <b className="is-size-4">
                    {item.monthName}
                    {item.expired ? (
                        <div className="is-pulled-right">
                            <span className="tag is-danger is-rounded">
                                <i className="icon ion-md-alarm" /> &nbsp; {item.expired}
                            </span>
                        </div>
                    ) : null}
                </b>

                <p>
                    Количество: &nbsp;
                    <span className="has-text-info">
                        {item.count} чел.
                    </span>
                </p>

                <p>
                    Долг: &nbsp;
                    <Money value={item.debt || 0} className="has-text-danger" />
                </p><br />

                <Progress value={percent} max={100} text={percentText} background={color} />
            </Card>
        </div>
    )
}


const styles = StyleSheet.create({
    active: {
        padding: '0.2rem',
        margin: '-0.2rem -0.2rem  -0.2rem 0',
        borderBottom: '3px solid #5daa87',
        boxShadow: 'rgb(134 130 130 / 56%) 0px 0.5em 1em -0.125em, rgb(10 10 10 / 2%) 0px 0px 0px 1px',
    },
})
