import { Form, Formik } from 'formik'
import { required, validator, minTime } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import WeekDayPicker from '../../core/components/WeekDayPicker'
import { FormSubmit } from '../../core/types'

export type Props = {
    initialValues?: FormType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}

export type FormType = {
    startTime: string
    endTime: string
    days: string[]
}

export default function GroupTimelineForm({ onSubmit, onCancel, loading, initialValues }: Props) {
    const values = {
        startTime: '12:00',
        endTime: '13:00',
        days: [],
        ...initialValues,
    } as FormType

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            {({ values: { startTime } }) => (
                <Form>
                    <WeekDayPicker label="Расписание занятий" name="days" />

                    <div className="columns">
                        <div className="column is-narrow">
                            <Input label="C" validate={required} name="startTime" type="time" step="600" />
                        </div>

                        <div className="column is-narrow">
                            <Input
                                label="По"
                                validate={validator(required, minTime(startTime))}
                                name="endTime"
                                type="time"
                                step="600" />
                        </div>
                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
