import CourseForm from './CourseForm'
import { CourseType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useCourseUpdate } from '../../academy/hooks/course'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    course: CourseType
}

export default function CourseUpdate({ onSuccess, onCancel, course }: Props) {
    const courseUpdate = useCourseUpdate(course.id)

    async function onSubmit(data: CourseType, actions: FormikHelpers<CourseType>) {
        await courseUpdate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Изменить Курс</b><br /><br />

            <CourseForm
                loading={courseUpdate.isLoading}
                onCancel={onCancel}
                initialValues={course}
                onSubmit={onSubmit} />
        </div>
    )
}
