import { Fragment } from 'react'
import Layout from '../../core/components/Layout'
import AcademyTabs from '../components/AcademyTabs'
import BirthdaysItem from '../components/BirthdaysItem'
import { formatDate } from '../../core/utils/date'
import { useBirthdays } from '../hooks/customer'
import InfiniteTable from '../../core/components/InfiniteTable'

export default function Birthdays() {
    const birthdays = useBirthdays()
    let date: string

    return (
        <Layout>
            <AcademyTabs />

            <InfiniteTable
                responsive
                columns={{ name: '', phone: '', date: '', students: '' }}
                query={birthdays}
                renderItem={(item) => {
                    if (item.date === date) {
                        return <BirthdaysItem key={item.id} item={item} />
                    }
                    date = item.date

                    return (
                        <Fragment key={item.id}>
                            <tr>
                                <th colSpan={100} className="is-size-4">
                                    {formatDate(date)}
                                </th>
                            </tr>

                            <BirthdaysItem item={item} />
                        </Fragment>
                    )
                }} />
        </Layout>
    )
}
