import SalesChannelForm from './SalesChannelForm'
import { AdvertType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useSalesChannelUpdate } from '../../academy/hooks/salesChannel'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    salesChannel: AdvertType
}

export default function SalesChannelUpdate({ onCancel, onSuccess, salesChannel }: Props) {
    const salesChannelUpdate = useSalesChannelUpdate(salesChannel.id)

    async function onSubmit(data: AdvertType, actions: FormikHelpers<AdvertType>) {
        await salesChannelUpdate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Изменить канал</b><br /><br />

            <SalesChannelForm
                initialValues={salesChannel}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={salesChannelUpdate.isLoading} />
        </div>
    )
}
