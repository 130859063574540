import isEmpty from 'lodash/isEmpty'
import { day } from '../../core/utils/date'
import CustomerGroups from './CustomerGroups'
import phoneFormat from '../../core/utils/phones'
import { BirthdayCustomerType, CustomerType } from '../types'

export type Props = {
    item: BirthdayCustomerType
}

export default function BirthdaysItem({ item }: Props) {
    const firstStudent = !isEmpty(item.students) ? item.students[0] : { timeline: [] }

    return (
        <tr>
            <td data-label="Имя">{item.name}</td>

            <td data-label="Телефон" className="pointer">
                <a href={`tel:${item.phone}`}>{phoneFormat(item.phone)}</a><br />
                <a href={`tel:${item.phoneSecond}`}>{phoneFormat(item.phoneSecond)}</a>
            </td>

            <td data-label="Расписание">
                {firstStudent.timeline?.map((timeline) => (
                    <span key={timeline.id}>{day(timeline.day).shortTitle} &nbsp;</span>
                ))}

                {!isEmpty(firstStudent.timeline)
                    ? `в ${firstStudent.timeline[0].startTime.slice(0, 5)}` : '-'}
            </td>

            <td data-label="Группы">
                <CustomerGroups customer={item as unknown as CustomerType} />
            </td>
        </tr>
    )
}
