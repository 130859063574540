import moment from 'moment'
import { format } from '../../core/utils/number'
import { causes } from '../utils/cause'
import { useMessage } from '../../core/hooks/message'
import { checkPermission as cp } from '../../users/utils/auth'
import { FineType } from '../types'
import { useFineDelete } from '../hooks/fine'

export type Props = {
    item: FineType
    onDelete: () => void
}

export default function EmployeeFineItem({ item, onDelete }: Props) {
    const fineDelete = useFineDelete(item.id)
    const [showMessage] = useMessage()

    async function onRemove() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await fineDelete.mutate()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }

            onDelete()
        }
    }

    return (
        <tr key={item.id}>
            <td>{causes[item.cause]}</td>

            <td className="has-text-danger">
                {format(item.amount)}
            </td>

            <td>{moment(item.createdAt).calendar()}</td>

            <td>
                {cp('staff.delete_employee') ? (
                    <span className="ion-md-trash pointer" onClick={onRemove} />
                ) : null}
            </td>
        </tr>
    )
}
