import { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { format } from '../../core/utils/number'
import Select from '../../core/components/Select'
import Input from '../../core/components/Input'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Table from '../../core/components/Table'
import { CustomerType, StudentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useWalletsSimple } from '../../finance/hooks/wallet'
import { useStudentPayments, useStudentPaymentsCreate } from '../hooks/student'

type StudentPaymentValues = { amount: string, wallet: string, student: string }

export type Props = {
    student?: StudentType
    customer?: CustomerType
    onSuccess: () => void
}

export default function StudentPayment({ student, onSuccess, customer }: Props) {
    const [page, setPage] = useState(1)
    const transactionCreate = useStudentPaymentsCreate()
    const wallets = useWalletsSimple()
    const transactions = useStudentPayments({ page, student: student?.id, customer: customer?.id })

    async function onSubmit(data: StudentPaymentValues, actions: FormikHelpers<StudentPaymentValues>) {
        await transactionCreate.mutate({ data: { ...data, student: student ? student.id : data.student } })
        actions.resetForm()
        onSuccess()
    }

    return (
        <Fragment>
            <Formik onSubmit={onSubmit} initialValues={{ amount: '', wallet: '', student: '' }}>
                <Form>
                    <b>Студент &nbsp; {student?.customer?.name || customer?.name}</b><br /><br />

                    <div className="columns">
                        {customer ? (
                            <div className="column">
                                <Select
                                    name="student"
                                    options={customer.students}
                                    optionLabel={(item) => item.group.name}
                                    validate={required} />
                            </div>
                        ) : null}

                        <div className="column">
                            <Input
                                name="amount"
                                type="number"
                                placeholder="Сумма"
                                validate={required} />
                        </div>

                        <div className="column">
                            <Select
                                name="wallet"
                                help="Вы можете добавить новый счёт в настройках"
                                options={wallets.data || []}
                                loading={wallets.isLoading}
                                validate={required} />
                        </div>

                        <div className="column is-narrow">
                            <Button
                                loading={transactionCreate.isLoading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info" />
                        </div>
                    </div>
                </Form>
            </Formik>

            <Table
                totalCount={transactions?.data?.count || 0}
                columns={{
                    amount: 'Сумма',
                    wallet: 'Счет',
                    group: 'Группа',
                    createdAt: 'Дата создания',
                }}
                loading={transactions.isLoading}
                items={transactions?.data?.results}
                onPageChange={setPage}
                activePage={page}
                renderItem={(transaction) => (
                    <tr key={transaction.id}>
                        <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                            {format(transaction.amount)}
                        </td>

                        <td>{transaction.wallet.name}</td>
                        <td>{transaction.student.group.name}</td>
                        <td>{moment(transaction.createdAt).calendar()}</td>
                    </tr>
                )} />
        </Fragment>
    )
}
