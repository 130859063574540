import { useState } from 'react'
import { checkPermission as cp } from '../../users/utils/auth'
import CustomerInfoGroupsForm, { FormType } from './CustomerInfoGroupsForm'
import Loader from '../../core/components/Loader'
import { useMessage } from '../../core/hooks/message'
import { CustomerType, StudentType } from '../types'
import { useStudentDelete, useStudentUpdate } from '../hooks/student'

export type Props = {
    item: StudentType
    customer: CustomerType
    onDelete: (customerId: number, customer: CustomerType) => void
    onUpdate: (customerId: number, customer: CustomerType) => void
}

export default function CustomerInfoGroupsItem({ customer, item, onDelete, onUpdate }: Props) {
    const [showMessage] = useMessage()
    const [edit, setEdit] = useState(false)
    const studentDelete = useStudentDelete(item.id)
    const { mutate, isLoading } = useStudentUpdate(item.id)

    async function onStudentUpdate(values: FormType) {
        const { success, data } = await mutate({ data: { ...values, customer: customer.id } })

        if (success && data) {
            const newCustomer = {
                ...customer,
                students: customer.students?.map((student) => (
                    student.id === data.id ? data : student
                )),
            } as CustomerType
            onUpdate(customer.id, newCustomer)
        }
    }

    async function removeStudent() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await studentDelete.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        const newCustomer = { ...customer, students: customer.students?.filter((student) => student !== item) }
        onDelete(customer.id, newCustomer)
    }

    if (edit) {
        return (
            <CustomerInfoGroupsForm
                onCancel={() => setEdit(false)}
                onSubmit={onStudentUpdate}
                customer={customer}
                loading={isLoading}
                initValues={item} />
        )
    }

    return (
        <tr>
            <td>{item.group.name}</td>
            <td>{item.price}</td>
            <td>
                {cp('academy.change_student') ? (
                    <i onClick={() => setEdit(true)} className="icon pointer ion-md-create" />
                ) : null}

                {cp('academy.delete_student') && !studentDelete.isLoading ? (
                    <i onClick={removeStudent} className="icon pointer ion-md-trash" />
                ) : null}

                <Loader show={studentDelete.isLoading} className="icon" />
            </td>
        </tr>
    )
}
