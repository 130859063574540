import { useNavigate } from 'react-router-dom'
import Layout from '../../core/components/Layout'
import { useQueryParams } from '../../core/hooks/queryString'
import { useModal } from '../../core/hooks/modal'
import EmployeeCreate from '../components/EmployeeCreate'
import Button from '../../core/components/Button'
import EmployeeItem from '../components/EmployeeItem'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { useEmployees } from '../hooks/employee'
import InfiniteTable from '../../core/components/InfiniteTable'

export default function Employee() {
    const params = useQueryParams()
    const employees = useEmployees(params)
    const navigate = useNavigate()

    const [showCreateModal, hideCreateModal] = useModal(
        <EmployeeCreate onSuccess={(employee) => {
            hideCreateModal()
            employees.addItem(employee)
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('staff.view_employee')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className="columns is-mobile">
                <div className="column">
                    <span className="is-size-4">Сотрудники</span>
                </div>

                <div className="column is-narrow">
                    {cp('staff.add_employee') ? (
                        <Button
                            onClick={showCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-link is-outlined" />
                    ) : null}
                </div>
            </div>

            <br />

            <ul>
                <li
                    onClick={() => navigate('/staff/employee?status=all')}
                    className="tag pointer is-light mr-1">
                    {params.status === 'all' ? <i className="icon ion-md-checkmark" /> : null}
                    Все
                </li>&nbsp;

                <li
                    onClick={() => navigate('/staff/employee?status=active')}
                    className="tag pointer is-primary mr-1">
                    {params.status === 'active' ? <i className="icon ion-md-checkmark" /> : null}
                    Активные
                </li>&nbsp;

                <li
                    onClick={() => navigate('/staff/employee?status=teacher')}
                    className="tag pointer is-warning mr-1">
                    {params.status === 'teacher' ? <i className="icon ion-md-checkmark" /> : null}
                    Учителя
                </li>&nbsp;

                <li
                    onClick={() => navigate('/staff/employee?status=fired')}
                    className="tag pointer is-danger mr-1">
                    {params.status === 'fired' ? <i className="icon ion-md-checkmark" /> : null}
                    Уволенные
                </li>
            </ul>

            <br />

            <InfiniteTable
                responsive
                query={employees}
                columns={{
                    name: 'Имя',
                    birthday: 'День рождения',
                    salary: 'Зарплата',
                    positions: 'Должность',
                    actions: '',
                }}
                renderItem={(item) => (
                    <EmployeeItem
                        key={item.id}
                        onDelete={employees.deleteItem}
                        onUpdate={employees.updateItem}
                        item={item} />
                )}/>
        </Layout>
    )
}
