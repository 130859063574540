import { css, StyleSheet } from 'aphrodite'

export type Props = {
    width?: string | number
    height?: string | number
    background?: string
}

export default function SkeletonLine({ width = '100%', height = '1rem', background = '#d0d0d0' }: Props) {
    return (
        <div className={css(styles.skeleton)} style={{ width, height, background }} />
    )
}

const styles = StyleSheet.create({
    skeleton: {
        // animationName: {
        //     '100%': { transform: 'translateX(100%)' },
        // },
        // backgroundImage: 'linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, ' +
        //     'rgba(#fff, 0.5) 60%, rgba(#fff, 0))',
        // animationDuration: '2s',
        // animationDirection: 'alternate',
        // animationIterationCount: 'infinite',
        margin: '0.3rem 0',
        borderRadius: '0.3rem',
    },
})
