import { DAYS } from '../../core/utils/date'
import { TimelineItemColumn } from './TimelineItemColumn'
import { EmployeeTimelineType } from '../types'

export type Props = {
    item: EmployeeTimelineType
}

export default function TimelineItem({ item }: Props) {
    return (
        <tr>
            <td data-label="Учитель">{item.name}</td>
            {DAYS.map((day) => <TimelineItemColumn groups={item.groups} day={day.key} key={day.key} />)}
        </tr>
    )
}
