import StatisticsAcademyCostAdvertBar from './StatisticsAcademyCostAdvertBar'
import Card from '../../core/components/Card'
import { useCostAdvertStats } from '../hooks/stats'

export type Props = {
    startDate: string
    endDate: string
}

export default function StatisticsAcademyCostAdvert({ startDate, endDate }: Props) {
    const costAdvert = useCostAdvertStats(startDate, endDate)

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                CPA(рекламные расходы на одного нового студента) и CPL(рекламные расходы на одну заявку или звонок)
            </div>

            <StatisticsAcademyCostAdvertBar
                data={costAdvert.data || []}
                loading={costAdvert.isLoading} />
        </Card>
    )
}
