export const TRANSACTION_LIST = '/finance/transaction/'
export const TRANSACTION_DETAIL = '/finance/transaction/{id}'
export const TRANSACTION_CATEGORY_LIST = '/finance/transaction-category/'
export const TRANSACTION_CATEGORY_DETAIL = '/finance/transaction-category/{id}'
export const TRANSACTION_CATEGORY_CHANGE_MAIN = '/finance/transaction-category/change_main/{id}'
export const TRANSACTION_TRANSFER = '/finance/transaction-transfer/{id}'

export const WALLET_LIST = '/finance/wallet/'
export const WALLET_SIMPLE_LIST = '/finance/wallet-simple-list/'
export const WALLET_DETAIL = '/finance/wallet/{id}'
export const WALLET_CHANGE_MAIN = '/finance/wallet/change_main/{id}'
