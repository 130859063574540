import CourseForm from './CourseForm'
import { CourseType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useCourseCreate } from '../../academy/hooks/course'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function CourseCreate({ onSuccess, onCancel }: Props) {
    const courseCreate = useCourseCreate()

    async function onSubmit(data: CourseType, actions: FormikHelpers<CourseType>) {
        await courseCreate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить новый курс</b><br /><br />
            <CourseForm loading={courseCreate.isLoading} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
