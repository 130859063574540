import { cn } from '../../core/utils/classname'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { Fragment } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Layout from '../../core/components/Layout'
import TransactionCreate from '../components/TransactionCreate'
import TransactionItem from '../components/TransactionItem'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import { useParams, useQueryParams } from '../../core/hooks/queryString'
import { getMonth } from '../../core/utils/date'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { format } from '../../core/utils/number'
import TransactionSearch from '../components/TransactionSearch'
import TransactionTransfer from '../components/TransactionTransfer'
import { useTransaction } from '../hooks/transaction'
import { useWallet } from '../hooks/wallet'
import InfiniteTable from '../../core/components/InfiniteTable'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import Loader from '../../core/components/Loader'

export default function Transaction() {
    const navigate = useNavigate()
    const { walletId } = useParams()
    const params = useQueryParams()
    const wallet = useWallet(walletId!)
    const transactions = useTransaction({ ...params, wallet: walletId })


    const [showCreateOutcomeModal, hideCreateOutcomeModal] = useModal(
        <TransactionCreate
            type="outcome"
            walletId={walletId!}
            onCancel={() => hideCreateOutcomeModal()}
            onSuccess={(transaction) => {
                hideCreateOutcomeModal()
                transactions.addItem(transaction)
                wallet.refetch()
            }} />,
    )

    const [showCreateTransferModal, hideCreateTransferModal] = useModal(wallet.data ? (
        <TransactionTransfer
            wallet={wallet.data}
            onCancel={() => hideCreateTransferModal()}
            onSuccess={(transaction) => {
                hideCreateOutcomeModal()
                transactions.addItem(transaction)
                wallet.refetch()
            }} />
    ) : <Fragment />)

    // Create new Transaction Modal
    const [showCreateIncomeModal, hideCreateIncomeModal] = useModal(
        <TransactionCreate
            type="income"
            walletId={walletId!}
            onCancel={() => hideCreateIncomeModal()}
            onSuccess={(transaction) => {
                hideCreateIncomeModal()
                transactions.addItem(transaction)
                wallet.refetch()
            }} />,
    )

    if (!cp('finance.view_transaction')) {
        return <PermissionDenied />
    }

    function onSearch(query: string) {
        const search = !isEmpty(query) ? query : undefined
        navigate(`?${queryString.stringify({ search })}`)
    }

    return (
        <Layout>
            <div className="columns">
                <div className="column">
                    <p className="is-size-4">
                        <NavLink className="has-text-black button is-white" to="/finance/wallet">
                            <i className="icon ion-md-arrow-back is-size-4" />
                        </NavLink> &nbsp;

                        {wallet.data?.name}
                    </p>
                </div>

                <div className="column is-narrow">
                    <TransactionSearch onSearch={onSearch} />
                </div>

                <div className="column is-narrow">
                    <div className="field has-addons ">
                        <p className="control">
                            {cp('finance.add_transaction') ? (
                                <Button
                                    text="Добавить расход"
                                    className="is-outlined is-link"
                                    icon="ion-md-add"
                                    onClick={showCreateOutcomeModal} />
                            ) : null}
                        </p>

                        <div className="control">
                            <Dropdown right trigger={
                                <Button
                                    className="button dropdown-trigger is-outlined is-link"
                                    icon="ion-md-arrow-dropdown" />
                            }>
                                {cp('finance.add_transaction') ? (
                                    <DropdownItem
                                        icon="ion-md-add"
                                        text="Добавить приход"
                                        onClick={showCreateIncomeModal} />
                                ) : null}

                                {cp('finance.add_transaction') ? (
                                    <DropdownItem
                                        icon="ion-md-swap"
                                        text="Перевести на другой счет"
                                        onClick={showCreateTransferModal} />
                                ) : null}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            { !transactions.isLoading && !wallet.isLoading && wallet.data ? (
                <div className={cn('columns my-3 is-touch is-justify-content-space-between')}>
                    <div className="column is-narrow">
                        Общая сумма

                        <p className="is-size-4">
                            {format(wallet.data.balance ? wallet.data.balance : 0)}
                            <Loader className="ml-1" show={wallet.isFetching} inline />
                        </p>
                    </div>

                    <div className="column is-narrow">
                        Приход({getMonth()})

                        <p className="is-size-4 has-text-success">
                            {format(wallet.data.income ? wallet.data.income : 0)}
                            <Loader className="ml-1" show={wallet.isFetching} inline />
                        </p>
                    </div>

                    <div className="column is-narrow">
                        Расход({getMonth()})

                        <p className="is-size-4 has-text-danger">
                            {format(wallet.data.outcome ? wallet.data.outcome : 0)}
                            <Loader className="ml-1" show={wallet.isFetching} inline />
                        </p>
                    </div>
                </div>
            ) : null }

            <InfiniteTable
                responsive
                columns={{
                    id: '№',
                    description: 'Описание',
                    amount: 'Сумма',
                    createdBy: 'Кем создана',
                    createdAt: 'Дата',
                    actions: '',
                }}
                query={transactions}
                renderItem={(transaction) => (
                    <TransactionItem
                        onUpdate={(item) => {
                            transactions.updateItem(item)
                            wallet.refetch()
                        }}
                        onDelete={(id) => {
                            transactions.deleteItem(id)
                            wallet.refetch()
                        }}
                        walletId={walletId!}
                        transaction={transaction}
                        key={transaction.id} />
                )} />
        </Layout>
    )
}
