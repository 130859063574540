import { TASK_DETAIL, TASK_LIST } from '../urls'
import { TaskType } from '../types'
import { useInfiniteFetch, useMutate } from '../../core/hooks/request'
import { request } from '../../core/utils/request'
import { FormType } from '../components/TaskForm'

export type TasksParams = {
    search?: string
    assigned?: number
    status?: string
    dueTime?: string
}

export function useTasks({ search, assigned, status, dueTime }: TasksParams = {}) {
    return useInfiniteFetch<TaskType>(
        ['tasks', { assigned, status, search, dueTime }],
        async ({ pageParam }) => request({
            url: TASK_LIST,
            params: { search, page: pageParam, assigned, status, dueTime },
        }),
    )
}

type CreateValues = FormType | { customer: number }
export function useTaskCreate() {
    return useMutate(async (values: CreateValues) => {
        return request({ method: 'post', url: TASK_LIST, data: values })
    })
}

export function useTaskDelete(id: number) {
    const url = TASK_DETAIL.replace('{id}', String(id))
    return useMutate(async () => request({ method: 'delete', url }))
}

type UpdateValues = FormType | { assigned: number, status: string }
export function useTaskUpdate(id: number) {
    const url = TASK_DETAIL.replace('{id}', String(id))
    return useMutate(async (values: UpdateValues) => {
        return request({ method: 'put', url, data: values })
    })
}
