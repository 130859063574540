import { css, StyleSheet } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import ServerError from '../../core/components/ServerError'
import { maxLength, minLength, required, validator } from '../../core/utils/validators'
import { signin } from '../utils/auth'
import invite from '../static/invite.svg'
import brokenInvitationCode from '../static/broken_invitation_code.svg'
import Loader from '../../core/components/Loader'
import { useParams } from '../../core/hooks/queryString'
import { useEmployeeInvitation, useEmployeeSignup } from '../hooks/users'

export default function InvitationSignUp() {
    const navigate = useNavigate()
    const { code } = useParams()
    const invitation = useEmployeeInvitation(code as string)
    const signUp = useEmployeeSignup(code as string)

    async function onSubmit(values: { password: string }) {
        const { data, success } = await signUp.mutate({ data: values })

        if (success) {
            signin(data, navigate)
        }
    }

    if (invitation.isLoading) {
        return (
            <div className={css(styles.container)}>
                <Loader center large />
            </div>
        )
    }

    if (invitation.error && invitation.error.status === 404) {
        return (
            <div className={css(styles.container)}>
                <img src={brokenInvitationCode} alt="Broken invitation link" className={css(styles.image)} />
                <br /><br />
                <h1 className="title has-text-centered is-centered">
                    Ссылка устарела либо не существует
                </h1>
            </div>
        )
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{ password: '' }}>
            <Form className={css(styles.container)}>
                <img src={invite} alt="Invitation" className={css(styles.image)} />
                <div className="columns">
                    <h1 className="column title">Придумайте новый пароль</h1>
                </div>

                <ServerError error={signUp.error} />
                <Input
                    name="password"
                    type="password"
                    validate={validator(required, maxLength(100), minLength(8))}
                    placeholder="*********" />

                <div className="field">
                    <p className="control">
                        <Button loading={signUp.isLoading} className="is-link is-block" text="Войти" type="submit" />
                    </p>
                </div>
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: '10rem auto',
        width: '30rem',
    },
    image: {
        margin: '0 auto',
        width: '25rem',
        display: 'block',
    },
})
