/* eslint-disable react-hooks/exhaustive-deps */
import isEmpty from 'lodash/isEmpty'
import { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { cn } from '../../core/utils/classname'
import { useQueryParams } from '../../core/hooks/queryString'

export type Props = {
    onSearch: (value?: string) => void
    className?: string
}

export default function GroupSearch({ onSearch, className }: Props) {
    const { search } = useQueryParams()
    const [value, setValue] = useState(search || '')

    const onChange = useCallback(debounce(({ target }) => {
        onSearch(target.value)
    }, 500), [])

    return (
        <div className={cn('field', className)}>
            <p className="control has-icons-left">
                <input
                    type="search"
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value)
                        event.persist()
                        onChange(event)
                    }}
                    autoFocus={!isEmpty(value)}
                    className="input is-small"
                    placeholder="Поиск..." />

                <span className="icon is-small is-left">
                    <i className="icon ion-md-search" />
                </span>
            </p>
        </div>
    )
}
