import { useState } from 'react'
import { cn } from '../../core/utils/classname'
import { format } from '../../core/utils/number'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import StudentPayment from './StudentPayment'
import StudentInfo from './StudentInfo'
import Loader from '../../core/components/Loader'
import StudentChangeGroup from './StudentChangeGroup'
import StudentStop from './StudentStop'
import { StudentType } from '../types'
import { useStudentStop } from '../hooks/student'
import StudentUpdatePrice from './StudentUpdatePrice'


export type Props = {
    item: StudentType
    index: number
    onSuccess: () => void
    onPaymentCreate: () => void
}

export default function StudentItem({ item, onPaymentCreate, onSuccess, index }: Props) {
    const studentStop = useStudentStop(item.id)
    const [showDropdown, setShowDropdown] = useState(false)

    const [showPaymentModal, hidePaymentModal] = useModal(
        <StudentPayment
            onSuccess={() => {
                hidePaymentModal()
                onPaymentCreate()
            }}
            student={item} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <StudentInfo onCancel={() => hideInfoModal()} student={item} />,
    )

    const [showGroupModal, hideGroupModal] = useModal(
        <StudentChangeGroup
            onSuccess={() => {
                onSuccess()
                hideGroupModal()
            }}
            student={item} />,
    )

    const [showStopModal, hideStopModal] = useModal(
        <StudentStop
            student={item}
            loading={studentStop.isLoading}
            onCancel={() => hideStopModal()}
            onSubmit={async (data) => {
                await studentStop.mutate({ data })
                onSuccess()
                hideStopModal()
            }} />,
    )

    const [showUpdatePriceModal, hideUpdatePriceModal] = useModal(
        <StudentUpdatePrice
            studentId={item.id}
            price={item.price}
            customerId={item?.customer?.id}
            onCancel={() => hideUpdatePriceModal()}
            onSuccess={() => {
                onSuccess()
                hideUpdatePriceModal()
            }}
        />,
    )

    async function onStatusChange() {
        await studentStop.mutate()
        onSuccess()
    }

    return (
        <tr className={cn({ 'has-text-grey': item.stoppedAt })}>
            <td data-label="№" className="pointer">{index + 1}</td>
            <td data-label="Имя" className="pointer" onClick={showInfoModal}>{item.customer?.name}</td>
            <td data-label="Телефон" className="pointer">{item.customer?.phone}</td>
            <td data-label="Студент ID" className="pointer">{item.id}</td>
            <td data-label="Оплачено" className="pointer">{format(item.totalPaid || 0)}</td>
            <td data-label="Опл. в этом месяце" className="pointer">{format(item.monthPaid || 0)}</td>
            <td data-label="Цена курса" className="pointer">{item.price}</td>

            <td data-label="Действия">
                <div className={cn('dropdown is-right', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <div className="dropdown-trigger">
                        <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                    </div>

                    <div className="dropdown-menu">
                        <div className="dropdown-content">
                            {cp('academy.change_student') ? (
                                <a className="pointer dropdown-item" onClick={showUpdatePriceModal}>
                                    <i className="icon ion-md-create" />
                                    Изменить цену
                                </a>
                            ) : null}

                            {cp('finance.add_student_payment') ? (
                                <a className="pointer dropdown-item" onClick={showPaymentModal}>
                                    <i className="icon ion-md-cash" />
                                    Оплата
                                </a>
                            ) : null}

                            {!studentStop.isLoading ? (
                                <span>
                                    {cp('academy.change_student') && item.stoppedAt ? (

                                        <a className="dropdown-item" onClick={onStatusChange}>
                                            <i className="icon ion-md-checkmark" />
                                            Активирововать
                                        </a>
                                    ) : (

                                        <a onClick={showStopModal} className="dropdown-item">
                                            <i className="icon ion-md-checkmark" />
                                            Деактивирововать
                                        </a>
                                    )}
                                </span>
                            ) : <Loader center />}

                            {cp('academy.change_student') && !item.stoppedAt ? (

                                <a className="dropdown-item" onClick={showGroupModal}>
                                    <i className="icon ion-md-arrow-back" />
                                    Перевести
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
