import { ErrorMessage, ErrorMessageProps } from 'formik'

export type Props = ErrorMessageProps & {
    name: string
}

export default function ValidationErrorMessage({ name, ...attributes }: Props) {
    return (
        <ErrorMessage
            name={name}
            {...attributes}
            render={(msg) => <div className="has-text-danger">{msg}</div>} />
    )
}
