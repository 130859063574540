import find from 'lodash/find'
import { cn } from '../../core/utils/classname'
import { GroupType } from '../types'

export type Props = {
    group: GroupType
    day: string
}

export function TimelineItemLesson({ group, day }: Props) {
    const lesson = find(group.timelines, { day })

    return (
        <span title={group.room.name} className={cn('tag', group.room.color)}>
            <b>{group.name}</b> - {lesson?.startTime.slice(0, 5)}
        </span>
    )
}
