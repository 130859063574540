import DebtorsMonthItem from './DebtorsMonthItem'
import { useDebtorsMonths } from '../hooks/debtor'
import DebtorMothsSkeleton from './DebtorMothsSkeleton'

export type Props = {
    month: string
}

export default function DebtorsMonths({ month }: Props) {
    const general = useDebtorsMonths()

    if (general.isLoading) {
        return <DebtorMothsSkeleton />
    }

    return (
        <div className="columns">
            {general?.data?.map((item) => (
                <div className="column is-3" key={item.month}>
                    <DebtorsMonthItem item={item} active={item.month === month} />
                </div>
            ))}
        </div>
    )
}
