import TransactionForm, { FormType } from './TransactionForm'
import { useTransactionCreate } from '../hooks/transaction'
import { TransactionType } from '../types'

export type Props = {
    type: 'income' | 'outcome'
    walletId: number | string
    onCancel: () => void
    onSuccess: (transaction: TransactionType) => void
}

export default function TransactionCreate({ onCancel, onSuccess, walletId, type }: Props) {
    const transactionCreate = useTransactionCreate()

    async function onSubmit(data: FormType) {
        const response = await transactionCreate.mutate({ data: { ...data, wallet: walletId } })
        if (response.success) {
            onSuccess(response.data)
        }
    }

    return (
        <div>
            <b>Добавить {type === 'outcome' ? 'расход' : 'приход'}</b><br /><br />

            <TransactionForm
                onSubmit={onSubmit}
                type={type}
                onCancel={onCancel}
                loading={transactionCreate.isLoading} />
        </div>
    )
}
