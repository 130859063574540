import GroupTimelineForm, { FormType } from './GroupTimelineForm'
import { GroupType, TimelineType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useTimelineSync } from '../hooks/timeline'

export type Props = {
    group: GroupType
    onCancel: () => void
    onSuccess: (timelines: TimelineType[]) => void
}

export default function GroupTimelineSync({ onCancel, onSuccess, group }: Props) {
    const timelineSync = useTimelineSync()
    const days = group.timelines?.map((timeline) => timeline.day)

    const values = {
        group: group.id,
        days,
        startTime: group.timelines?.[0] ? group.timelines?.[0].startTime : '12:00',
        endTime: group.timelines?.[0] ? group.timelines?.[0].endTime : '13:00',
    } as FormType

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const response = await timelineSync.mutate({
            data: {
                ...data,
                group: group.id,
                timelines: data.days.map((day) => ({
                    day,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    group: group.id,
                })),
            },
        })

        if (response.success) {
            actions.resetForm()
            onSuccess(response?.data?.timelines)
        }
    }

    return (
        <div>
            <b>Выберите учебного времени</b><br /><br />

            <GroupTimelineForm
                onSubmit={onSubmit}
                initialValues={values}
                onCancel={onCancel}
                loading={timelineSync.isLoading} />
        </div>
    )
}
