/* eslint-disable react-hooks/exhaustive-deps */
import { css, StyleSheet } from 'aphrodite'
import { cn } from '../../core/utils/classname'
import debounce from 'lodash/debounce'
import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useQueryParams } from '../../core/hooks/queryString'

export type Props = {
    onSearch: (value: string) => void
}

export default function TransactionSearch({ onSearch }: Props) {
    const { search } = useQueryParams()
    const [value, setValue] = useState(search || '')

    const onChange = useCallback(debounce(({ target }) => {
        onSearch(target.value)
    }, 500), [])

    return (
        <div className="columns column is-mobile has-background-white">
            <input
                type="search"
                autoFocus={!isEmpty(value)}
                value={value}
                onChange={(event) => {
                    setValue(event.target.value)
                    event.persist()
                    onChange(event)
                }}
                className={cn('input', css(styles.input))}
                placeholder="Поиск..." />&nbsp;
        </div>
    )
}

const styles = StyleSheet.create({
    input: {
        minWidth: '6rem',
    },
})
