import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export function usePersistState<S>(key: string, defaultValue?: S): [S, Dispatch<SetStateAction<S>>] {
    const [state, setState] = useState(() => {
        const value = localStorage.getItem(key)

        if (value === null || value === undefined) {
            return defaultValue
        }

        try {
            return JSON.parse(value)
        } catch (e) {
            return value
        }
    })

    useEffect(() => {
        const value = typeof state === 'object' ? JSON.stringify(state) : state
        localStorage.setItem(key, value)
    }, [key, state])

    return [state, setState]
}
