import StudentForm, { FormType } from './StudentForm'
import { CustomerType } from '../types'
import { useStudentCreate } from '../hooks/student'

export type Props = {
    customer: CustomerType
    onCancel: () => void
    onSuccess: () => void
}

export default function StudentCreate({ customer, onSuccess, onCancel }: Props) {
    const createStudent = useStudentCreate()

    async function onSubmit(values: FormType) {
        const { data, success } = await createStudent.mutate({ data: { ...values, customer: customer.id } })

        if (success && data) {
            customer.students?.push(data)
            onSuccess()
        }
    }

    return (
        <div>
            <b>В какую группу вы хотите принять?</b><br /><br />

            <StudentForm
                loading={createStudent.isLoading}
                customer={customer}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
