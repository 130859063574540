import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Select  from '../../core/components/Select'
import { stoppedReasons } from '../utils/constants'
import Input from '../../core/components/Input'
import { FormSubmit } from '../../core/types'
import { StudentType } from '../types'

export type Props = {
    student: StudentType
    onSubmit: FormSubmit<StudentType>
    loading?: boolean
    onCancel: () => void
}

export default function StudentStop({ student, loading, onSubmit, onCancel }: Props) {
    return (
        <Formik initialValues={{ stoppedReason: '', stoppedText: '' } as StudentType} onSubmit={onSubmit}>
            {({ values }) => (
                <Form>
                    <b>Деактивация студента {student.customer?.name}</b><br /><br />

                    <Select
                        empty
                        name="stoppedReason"
                        optionValue="value"
                        optionLabel="label"
                        options={stoppedReasons}
                        label="Причина"
                        validate={required} />

                    {values.stoppedReason === 'other' ? (
                        <Input
                            name="stoppedText"
                            type="text"
                            component="textarea"
                            label="Описание"
                            placeholder="Причина"
                            validate={required} />
                    ) : null}

                    <Button
                        loading={loading}
                        text="Деактивироват"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
