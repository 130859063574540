import { Form, Formik } from 'formik'
import find from 'lodash/find'
import map from 'lodash/map'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { CustomerType, StudentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupsList } from '../hooks/group'

export type Props = {
    customer: CustomerType
    initialValues?: StudentType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}

export type FormType = { price: string, group: string }
type SetValue = FormikHelpers<FormType>['setFieldValue']

export default function StudentForm({ loading, onSubmit, onCancel, customer }: Props) {
    const groups = useGroupsList()
    const groupsIds = map(customer.students, 'group.id')
    const groupOptions = groups.data?.results.filter((group) => !groupsIds.includes(group.id)) || []

    function onGroupChange(groupId: string, setFieldValue: SetValue) {
        const group = find(groups.data?.results, { id: parseInt(groupId, 10) })
        setFieldValue('group', groupId)
        setFieldValue('price', group ? group.price : '')
    }

    return (
        <Formik initialValues={{ price: '', group: '' } as FormType} onSubmit={onSubmit}>
            {({ setFieldValue }) => (
                <Form>
                    <Select
                        empty
                        name="group"
                        options={groupOptions}
                        label="Группа"
                        validate={required}
                        onChange={({ target }) => onGroupChange(target.value, setFieldValue)}
                        loading={groups.isLoading} />

                    <Input
                        name="price"
                        label="Стоимость"
                        placeholder="Стоимость месячного абонемента"
                        disabled={loading}
                        validate={required}
                        type="number" />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
