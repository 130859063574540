import 'bulma/css/bulma.css'
import './core/static/styles.css'
import 'ionicons/dist/css/ionicons.css'
import 'moment/locale/ru'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import moment from 'moment'
import BaseContextWrapper from './core/components/BaseContext'
import AcademyRoutes from './academy/Routes'
import UserRoutes from './users/Routes'
import FinanceRoutes from './finance/Routes'
import SettingsRoutes from './settings/Routes'
import TaskRoutes from './tasks/Routes'
import StaffRoutes from './staff/Routes'
import StatsRoutes from './stats/Routes'
import useEnvironment from './core/hooks/environment'


moment.locale('ru')

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            staleTime: 30000,
            retry: 1,
        },
    },
})

export default function App() {
    useEnvironment()

    return (
        // <StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Routes>
                        <Route path="/*" element={<UserRoutes />} />
                        <Route path="/academy/*" element={<AcademyRoutes />} />
                        <Route path="/finance/*" element={<FinanceRoutes />} />
                        <Route path="/statistics/*" element={<StatsRoutes />} />
                        <Route path="/settings/*" element={<SettingsRoutes />} />
                        <Route path="/task/*" element={<TaskRoutes />} />
                        <Route path="/staff/*" element={<StaffRoutes />} />
                        {/*<Route path="*" element={<NotFound/>} />*/}
                    </Routes>
                </BaseContextWrapper>
            </BrowserRouter>
        </QueryClientProvider>
        // </StrictMode>
    )
}

createRoot(document.getElementById('root')!).render(<App />)
