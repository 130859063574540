import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import Select from '../../core/components/Select'
import { FormSubmit } from '../../core/types'
import { TaskType } from '../types'
import { useUsers } from '../../users/hooks/users'

export type Props = {
    initialValues?: TaskType
    onSubmit: FormSubmit<FormType>
    loading?: boolean
    onCancel: () => void
}

export type FormType = {
    title: string
    assigned: string | number
    dueTime: string | null
    description: string
}

export default function TaskForm({ onSubmit, onCancel, loading, initialValues }: Props) {
    const users = useUsers()

    return (
        <Formik initialValues={{
            title: '',
            ...initialValues,
            assigned: initialValues ? initialValues.assigned.id : '',
            dueTime: initialValues && initialValues.dueTime ? initialValues.dueTime : '',
            description: initialValues ? initialValues.description : '',
        } as FormType} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="title"
                    label="Заголовок"
                    placeholder="Введите заголовок задачи"
                    disabled={loading}
                    validate={required} />

                <Select
                    empty
                    name="assigned"
                    label="Ответственный"
                    validate={required}
                    optionLabel={(item) => `${item.firstName}(${item.email})`}
                    options={users.data?.results} />

                <Input
                    name="dueTime"
                    label="Дату окончание"
                    type="datetime-local"
                    optional
                    disabled={loading} />

                <Input name="description" optional component="textarea" label="Описание" />
                <br />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    disabled={loading}
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
