import { Form, Formik } from 'formik'
import { phone, required, validator } from '../../core/utils/validators'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import { FormSubmit } from '../../core/types'
import { ParentType } from '../types'

export type Props = {
    initialValues?: ParentType
    onSubmit: FormSubmit<ParentType>
    loading?: boolean
    onCancel: () => void
}

export default function ParentForm({ onSubmit, onCancel, loading, initialValues }: Props) {

    const values = {
        name: '',
        phone: '',
        ...initialValues,
    } as ParentType

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    disabled={loading}
                    validate={required}
                    label="Имя"
                    placeholder="Введите Имя родителя" />

                <Input
                    name="phone"
                    disabled={loading}
                    validate={validator(phone, required)}
                    label="Номер телефона"
                    placeholder="Введите номер телефона" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
