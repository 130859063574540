import { tags } from './CustomerType'
import CustomerActions from './CustomerActions'
import { useModal } from '../../core/hooks/modal'
import CustomerUpdate from './CustomerUpdate'
import CustomerInfo from './CustomerInfo'
import CustomerGroups from './CustomerGroups'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import { CUSTOMER_MOVE } from '../urls'
import Loader from '../../core/components/Loader'
import { CustomerType, StatusType } from '../types'
import find from 'lodash/find'
import { useCustomerUpdate } from '../hooks/customer'

export type Props = {
    customer: CustomerType
    statusList: StatusType[]
    showLabels: boolean
    onUpdate: (id: number, data: CustomerType) => void
    onDelete: () => void
}

export default function CustomerTableItem({
    customer,
    statusList,
    showLabels,
    onUpdate,
    onDelete,
}: Props) {
    const customerUpdate = useCustomerUpdate(customer.id)
    const status = find(statusList, { id: customer.state })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <CustomerUpdate
            customer={customer}
            onCancel={() => hideUpdateModal()}
            onSuccess={(values) => {
                onUpdate(customer.id, values)
                hideUpdateModal()
            }} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <CustomerInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            customer={customer}
            onUpdate={(newCustomerId, newCustomer) => {
                // TODO
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }}
            onDelete={(newCustomerId, newCustomer) => {
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }} />,
    )

    async function changeStatus(state: number) {
        console.log('test')
        await customerUpdate.mutate({
            url: CUSTOMER_MOVE.replace('{id}', String(customer.id)),
            data: { state, position: 0 },
        })

        onUpdate(customer.id, { ...customer, state })
    }

    return (
        <tr key={customer.id}>
            <td onClick={showInfoModal} className="pointer" data-label="Имя">
                {customer.name} &nbsp;
                {showLabels ? tags[customer.type] : null}
            </td>

            <td data-label="Состояние">
                {customerUpdate.isLoading && status ? <Loader /> : (
                    <Dropdown trigger={(
                        <div style={{ background: status?.color }} className="tag has-text-white pointer">
                            {status?.title}
                            <i className="icon ion-md-arrow-dropdown" />
                        </div>
                    )}>
                        {statusList.map((item) => (
                            <DropdownItem onClick={() => changeStatus(item.id)} key={item.id} text={item.title} />
                        ))}
                    </Dropdown>
                )}
            </td>

            <td data-label="Группы">
                <CustomerGroups customer={customer} />
            </td>

            <td data-label="Действия">
                <CustomerActions
                    customer={customer}
                    onDelete={onDelete}
                    onTypeChanged={onUpdate}
                    onClickUpdate={showUpdateModal} />
            </td>
        </tr>
    )
}
