import { useEffect } from 'react'
import { getEnv } from '../utils/environment'

export default function useEnvironment() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
        const favicon: HTMLLinkElement | null = document.querySelector("link[rel='shortcut icon']")
        if (!favicon) return
        favicon.href = `/${getEnv()}.favicon.png`
    }, [])
}
