import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ResetPassword from './pages/ResetPassword'
import ResetLink from './pages/ResetLink'
import ConfirmEmail from './pages/ConfirmEmail'
import InvitationSignUp from './pages/InvitationSignUp'
import EmailConfirmationMessage from './pages/EmailConfirmationMessage'
import ResetPasswordMessage from './pages/ResetPasswordMessage'
import NotFound from '../core/pages/NotFound'

export default function UserRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/sign-up" element={<SignUp/>} />
            <Route path="/reset-password/:key" element={<ResetPassword/>} />
            <Route path="/reset-link" element={<ResetLink/>} />
            <Route path="/confirm/:confirmationCode" element={<ConfirmEmail/>} />
            <Route path="/invitation/:code" element={<InvitationSignUp/>} />
            <Route path="/email-confirmation-message" element={<EmailConfirmationMessage/>} />
            <Route path="/reset-password-message" element={<ResetPasswordMessage/>} />
            <Route path="*" element={<NotFound/>} />
        </Routes>
    )
}
