/* eslint-disable no-nested-ternary */
import { cn } from '../../core/utils/classname'
import { useState } from 'react'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import { useMessage } from '../../core/hooks/message'
import Loader from '../../core/components/Loader'
import TransactionCategoryUpdate from './TransactionCategoryUpdate'
import { TransactionCategoryType } from '../../finance/types'
import { TRANSACTION_CATEGORY_CHANGE_MAIN } from '../../finance/urls'
import { useCategoryChangeMainCreate, useTransactionCategoryDelete } from '../../finance/hooks/transactionCategories'

export type Props = {
    category: TransactionCategoryType
    onDelete: () => void
    onUpdate: () => void
}

export default function TransactionCategoryItem({ category, onDelete, onUpdate }: Props) {
    const transactionCategoryDelete = useTransactionCategoryDelete(category.id)
    const categoryChangeMain = useCategoryChangeMainCreate()
    const [showDropdown, setShowDropdown] = useState(false)

    async function changeMain() {
        await categoryChangeMain.mutate({
            url: TRANSACTION_CATEGORY_CHANGE_MAIN.replace('{id}', String(category.id)),
        })
        onUpdate()
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TransactionCategoryUpdate
            category={category}
            onSuccess={() => {
                onUpdate()
                hideUpdateModal()
            }} onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    const [showMessage] = useMessage()

    async function deleteCategory() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await transactionCategoryDelete.mutate()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <tr>
            <td>
                {category.name || <span className="is-italic has-text-grey">не указан</span>} &nbsp;
                {category.systemName ? <i className="has-text-grey">(Системная)</i> : null}
                <span className="is-size-7 has-text-success">{category.isMain ? ' (основной)' : ''}</span>
            </td>

            <td>
                {category.type === 'income' ? (
                    <p className="has-text-success">приход</p>
                ) : (
                    <p className="has-text-danger">расход</p>
                )}
            </td>

            <td width="100px" className="has-text-right">
                {cp('finance.delete_transactioncategory') && !category.systemName ? (
                    !transactionCategoryDelete.isLoading ? (
                        <i onClick={() => deleteCategory()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_transactioncategory') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}

                <div
                    className={cn('dropdown  is-right is-pulled-right', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <div className="dropdown-trigger pointer">
                        <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                    </div>

                    <div className="dropdown-menu">
                        <div className="dropdown-content">
                            {cp('finance.change_wallet') && !category.isMain ? (
                                <a className="dropdown-item" onClick={changeMain}>
                                    <i className="icon ion-md-checkmark" />
                                    Сделать главным
                                </a>
                            ) : (
                                <a className="dropdown-item">
                                    <i className="icon ion-md-checkmark" />
                                    Главный
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
