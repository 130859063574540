import TaskForm, { FormType } from './TaskForm'
import { TaskType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useTaskUpdate } from '../hooks/tasks'

export type Props = {
    onUpdate: (item: TaskType) => void
    onCancel: () => void
    task: TaskType
}

export default function TaskUpdate({ onCancel, onUpdate, task }: Props) {
    const taskUpdate = useTaskUpdate(task.id)

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const response = await taskUpdate.mutateAsync({
            ...data, dueTime: data.dueTime ? data.dueTime : null,
        })
        actions.resetForm()
        onUpdate(response)
    }

    return (
        <div>
            <b>Изменить задачу</b><br /><br />

            <TaskForm
                initialValues={task}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={taskUpdate.isLoading} />
        </div>
    )
}
