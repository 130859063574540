import { Form, Formik } from 'formik'
import { max, min, required, validator } from '../../core/utils/validators'
import { positions } from '../utils/position'
import Input from '../../core/components/Input'
import Button from '../../core/components/Button'
import MultiSelect from '../../core/components/MultiSelect'
import { FormSubmit } from '../../core/types'
import { EmployeeType, PositionsType } from '../types'

export type Props = {
    initialValues?: EmployeeFormType
    onSubmit: FormSubmit<EmployeeFormType>
    loading?: boolean
    onCancel: () => void
}

export type EmployeeFormType = EmployeeType & {
    positions: Record<string, PositionsType>[]
}

export default function EmployeeForm({ onSubmit, onCancel, loading, initialValues }: Props) {
    const options = Object.entries(positions).map(([value, label]) => ({ value, label }))

    return (
        <Formik initialValues={{
            name: '',
            birthday: '',
            salary: 0,
            academyShare: 0,
            sellerShare: 0,
            positions: [],
            ...initialValues,
        } as EmployeeFormType} onSubmit={onSubmit}>
            <Form>
                <MultiSelect
                    validate={required}
                    label="Должность"
                    name="positions"
                    placeholder="Введите должности сотрудника"
                    options={options} />

                <Input
                    label="Имя"
                    name="name"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите имя сотрудника" />

                <Input
                    name="birthday"
                    type="date"
                    label="День рождения"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите день рождение сотрудника" />

                <Input
                    name="sellerShare"
                    label="Доля от продаж"
                    type="number"
                    disabled={loading}
                    validate={validator(required, max(100), min(0))}
                    placeholder="Введите долю продавца" />

                <Input
                    name="salary"
                    label="Фиксированная зарплата"
                    type="number"
                    disabled={loading}
                    validate={validator(required, min(0))}
                    placeholder="Введите зарплату сотрудника" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
