import { CASHBACK_LIST } from '../urls'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'
import { CashbackType } from '../types'
import { usePostRequest } from '../../core/hooks/request.legacy'

export function useCashbacks() {
    return useInfiniteFetch<CashbackType>(
        ['cashbacks'],
        ({ pageParam }) => request({ url: CASHBACK_LIST, params: { page: pageParam } }),
    )
}

export function useCloseCashback() {
    return usePostRequest<CashbackType>({ url: CASHBACK_LIST })
}
