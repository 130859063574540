import { Form, Formik } from 'formik'
import Input from '../../core/components/Input'
import { max, min, required, validator } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Card from '../../core/components/Card'
import Loader from '../../core/components/Loader'
import { useMessage } from '../../core/hooks/message'
import { CompanyType } from '../../core/types'
import { useUpdateCompany } from '../../core/hooks/company'
import { useGeneralInfo } from '../../core/hooks/general'

export default function SettingsTaxesForm() {
    const [showMessage] = useMessage()
    const general = useGeneralInfo()
    const updateCompany = useUpdateCompany()

    async function onSubmit(data: CompanyType) {
        const { success } = await updateCompany.mutate({ data })
        if (!success) return
        general.setData({ company: data })
        showMessage('Данные успешно обновлены!', 'is-success')
    }

    if (general.isLoading || !general.data) {
        return <Loader large center />
    }

    return (
        <Card>
            <div className="is-size-4 mb-3">Налоги</div>

            <Formik initialValues={general.data.company} onSubmit={onSubmit}>
                <Form>
                    <Input
                        name="employeeTax"
                        label="Подоходный налог в процентах"
                        validate={validator(required, max(100), min(0))}
                        placeholder="Введите имя счёта" />

                    <Button
                        loading={updateCompany.isLoading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;
                </Form>
            </Formik>
        </Card>
    )
}
