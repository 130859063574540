import { Form, Formik } from 'formik'
import { cn } from '../../core/utils/classname'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import map from 'lodash/map'
import Input from '../../core/components/Input'
import { required } from '../../core/utils/validators'
import Checkbox from '../../core/components/Checkbox'
import Button from '../../core/components/Button'
import { GroupType, StudentType } from '../types'

export type Props = {
    group: GroupType
    loading?: boolean
    initialValues?: FormType
    students: StudentType[]
    onSubmit: (values: FormType, students: StudentType[]) => void
}

export type FormType = {
    topic: string
    date: string
    visits: string[],
}

export default function LessonForm({ students, initialValues, group, onSubmit, loading }: Props) {
    const todayDate = moment(new Date()).format('YYYY-MM-DD')
    const lessonTime = group.timelines && group.timelines[0] ? group.timelines[0].startTime.slice(0, 5) : '00:00'
    const activeStudents = students.filter((i) => !i.stoppedAt) || []

    const values = {
        topic: '',
        date: `${todayDate}T${lessonTime}`,
        visits: map(activeStudents, (i) => String(i.id)),
        ...initialValues,
    } as FormType

    return (
        <Formik onSubmit={((data) => onSubmit(data, activeStudents))} initialValues={values}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input
                            label="Тема"
                            name="topic"
                            placeholder="Тема"
                            validate={required} />

                    </div>

                    <div className="column">
                        <Input
                            label="Дата"
                            name="date"
                            type="datetime-local"
                            placeholder="Дата"
                            validate={required} />
                    </div>
                </div>

                <div className="columns is-multiline">
                    {activeStudents.map((student) => (
                        <div className={cn('column is-3', css(styles.student))} key={student.id}>
                            <Checkbox
                                id={student.id}
                                value={String(student.id)}
                                label={student.customer?.name}
                                name="visits" />
                        </div>
                    ))}
                </div>

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" />
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    student: {
        width: '50%',
        paddingBottom: 0,
    },
})
