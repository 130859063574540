import { useContext } from 'react'
import { Context } from '../components/BaseContext'

type Returns = [
    (text?: string, className?: string) => void,
    () => void,
]

export function useMessage(): Returns {
    const { setText, setClassName } = useContext(Context)

    return [
        (text, className) => {
            setText(text)
            setClassName(className || 'is-dark')
        },
        () => {
            setText(undefined)
            setClassName('')
        },
    ]
}
