import { useRef, useEffect } from 'react'
// @ts-ignore
import ChartJS, { ChartConfiguration } from 'chart.js'
import 'chartjs-plugin-datalabels'
import Loader from './Loader'

export type Props = ChartConfiguration & {
    loading?: boolean
    height?: string
}

export default function Chart({ loading, type, data, options, height = '50px' }: Props) {
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line no-new
            new ChartJS(ref.current, {
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    ...options,
                },
                type,
                data,
            })
        }
    }, [data, type, options])

    if (loading) {
        return <Loader center large />
    }

    return (
        <div style={{ height }}>
            <canvas ref={ref} />
        </div>
    )
}
