import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Layout from '../../core/components/Layout'
import StatisticsAcademySalesChannel from '../components/StatisticsAcademySalesChannel'
import DateRange from '../../core/components/DateRange'
import { useQueryParams } from '../../core/hooks/queryString'
import { DATE_FORMAT } from '../../core/utils/date'
import StatisticsTabs from '../components/StatisticsTabs'
import StatisticsAcademyCostAdvert from '../components/StatisticsAcademyCostAdvert'
import Card from '../../core/components/Card'
import StatisticsSalesFunnel from '../components/StatisticsSalesFunnel'
import StatisticsAcademyStudent from '../components/StatisticsAcademyStudent'

export default function StatisticsAcademy() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const startDate = params.startDate as string || moment().startOf('month').format(DATE_FORMAT)
    const endDate = params.endDate as string || moment().endOf('month').format(DATE_FORMAT)

    return (
        <Layout className="has-background-light">
            <StatisticsTabs />

            <Card>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(dates) => navigate(`?${queryString.stringify({ ...params, ...dates })}`)} />
            </Card><br />

            <StatisticsAcademyStudent
                startDate={startDate}
                endDate={endDate} /><br />

            <StatisticsAcademySalesChannel
                startDate={startDate}
                endDate={endDate} />

            <StatisticsAcademyCostAdvert
                startDate={startDate}
                endDate={endDate} /><br />

            <StatisticsSalesFunnel
                startDate={startDate}
                endDate={endDate} />

        </Layout>
    )
}
