/* eslint-disable no-nested-ternary */
import { cn } from '../../core/utils/classname'
import { css, StyleSheet } from 'aphrodite'
import Loader from '../../core/components/Loader'
import { checkPermission as cp } from '../../users/utils/auth'
import { VisitType } from '../types'
import { useVisitUpdate } from '../hooks/visit'

export type Props = {
    item: VisitType
    onChange: (visit: VisitType) => void
    isEditable: boolean
}

export default function VisitItem({ item, onChange, isEditable = true }: Props) {
    const changeVisit = useVisitUpdate(item.id)
    const iconClass = item.isVisited ? 'ion-md-checkmark has-text-success' : 'ion-md-close has-text-danger'

    async function handleChange() {
        if (!cp('academy.change_visit') || changeVisit.isLoading || !isEditable) return

        const { data, success } = await changeVisit.mutate({
            data: { isVisited: !item.isVisited, student: item.student.id },
        })

        if (data && success) {
            onChange(data)
        }
    }

    return (
        <div onClick={handleChange} className={cn('column is-3', css(styles.visit))}>
            <span>
                {!changeVisit.isLoading
                    ? (<i className={cn('icon', iconClass)} />)
                    : (<Loader className={css(styles.loader)} />)} &nbsp;
            </span>

            <label htmlFor={`visit${item.id}`} className={isEditable ? 'pointer' : ''}>
                {item.student.customer?.name} &nbsp;
            </label>
        </div>
    )
}

const styles = StyleSheet.create({
    visit: {
        // width: '50%',
        paddingBottom: 0,
    },
    loader: {
        display: 'inline-block',
    },
})
