import { useEffect } from 'react'
import { useMessage } from '../../core/hooks/message'
import Loader from '../../core/components/Loader'
import Button from '../../core/components/Button'
import { botName } from '../utils/getBotname'
import { useParentTelegramInvite } from '../hooks/parents'

export type Props = {
    itemId: number | string
    onCancel: () => void
}

export default function ParentInvite({ itemId, onCancel }: Props) {
    const telegramInvite = useParentTelegramInvite(itemId as number)
    const [showMessage] = useMessage()

    useEffect(() => {
        invite()
        // eslint-disable-next-line
    }, [])

    async function invite() {
        const { error } = await telegramInvite.mutate({ data: { userType: 'parent' } })
        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }
    }

    if (telegramInvite.isLoading) {
        return <Loader large center />
    }

    return (
        <div>
            {telegramInvite.data ? (
                <div>
                    <b>Ссылка для приглашения {telegramInvite.data.parent.name}:</b>

                    <input
                        className="input is-disabled"
                        value={`https://t.me/${botName}?start=${telegramInvite.data.code}`}
                        onFocus={({ target }) => target.select()}
                        readOnly />

                    <br />
                    <br />
                </div>
            ) : <h1>Произошла ошибка!</h1>}

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыт"
                className="is-danger" />
        </div>
    )
}
