import RoomForm from './RoomForm'
import { RoomType } from '../../academy/types'
import { FormikHelpers } from 'formik/dist/types'
import { useRoomUpdate } from '../../academy/hooks/room'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    room: RoomType
}

export default function RoomUpdate({ onSuccess, onCancel, room }: Props) {
    const roomUpdate = useRoomUpdate(room.id)

    async function onSubmit(data:RoomType, actions: FormikHelpers<RoomType>) {
        await roomUpdate.mutate({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Изменить Кабинет</b><br /><br />

            <RoomForm
                loading={roomUpdate.isLoading}
                onCancel={onCancel}
                initialValues={room}
                onSubmit={onSubmit} />
        </div>
    )
}
