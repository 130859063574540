import TransactionCategoryForm from './TransactionCategoryForm'
import { TransactionCategoryType } from '../../finance/types'
import { FormikHelpers } from 'formik/dist/types'
import { useTransactionCategoryUpdate } from '../../finance/hooks/transactionCategories'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    category: TransactionCategoryType
}

export default function TransactionCategoryUpdate({ onCancel, onSuccess, category }: Props) {
    const transactionUpdate = useTransactionCategoryUpdate(category.id)

    async function onSubmit(data: TransactionCategoryType, actions: FormikHelpers<TransactionCategoryType>) {
        const defaultAmount = data.defaultAmount ? data.defaultAmount : null
        await transactionUpdate.mutate({ data: { ...data, defaultAmount } })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Изменить категорию</b><br /><br />

            <TransactionCategoryForm
                initialValues={category}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={transactionUpdate.isLoading} />
        </div>
    )
}
