import StatisticsFinanceAccountantLine from './StatisticsFinanceAccountantLine'
import Card from '../../core/components/Card'
import { useFinanceStats } from '../hooks/stats'

export default function StatisticsFinanceAccountant() {
    const finance = useFinanceStats()

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Статистика финансов
            </div>

            <StatisticsFinanceAccountantLine
                data={finance.data || []}
                loading={finance.isLoading} />
        </Card>
    )
}
