import { Fragment } from 'react'
import { Formik, Form } from 'formik'
import Select from '../../core/components/Select'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import { useMessage } from '../../core/hooks/message'
import { StudentType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useGroupsList } from '../hooks/group'
import { useStudentUpdate } from '../hooks/student'

export type Props = {
    student: StudentType
    onSuccess: () => void
}

export type FormType = {
    group: number
}

export default function StudentChangeGroup({ student, onSuccess }: Props) {
    const studentUpdate = useStudentUpdate(student.id)
    const groups = useGroupsList()
    const [showMessage] = useMessage()
    const groupList = groups.data ? groups.data.results : []

    async function onSubmit(data: FormType, actions: FormikHelpers<FormType>) {
        const { error } = await studentUpdate.mutate({ data: {
            ...student,
            customer: student.customer?.id,
            group: data.group,
        } })

        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }

        actions.resetForm()
        onSuccess()
    }

    return (
        <Fragment>
            <Formik onSubmit={onSubmit} initialValues={{ group: student.group.id }}>
                <Form>
                    <b>Перевод студента: &nbsp; {student.customer?.name}</b><br /><br />

                    <div className="column">
                        <Select
                            name="group"
                            options={groupList}
                            validate={required} />
                    </div>

                    <div className="column is-narrow">
                        <Button
                            loading={studentUpdate.isLoading}
                            text="Перевести"
                            type="submit"
                            icon="ion-md-arrow-back"
                            className="is-info" />
                    </div>
                </Form>
            </Formik>
        </Fragment>
    )
}
