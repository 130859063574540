import { Fragment } from 'react'
import { CustomerType } from '../types'

export type Props = {
    customer: CustomerType
}

export default function CustomerGroups({ customer }: Props) {
    if (!customer.students) {
        return  <Fragment />
    }

    return (
        <p className="has-text-grey">
            {customer.students.length >= 2 ? (
                `${customer.students[0]?.group.name}, ${customer.students[1]?.group.name}`
            ) : null}

            {customer.students.length > 2 ? ', ...' : ''}
            {customer.students.length === 1 ? customer.students[0].group.name : ''}
        </p>
    )
}
