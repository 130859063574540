import sortBy from 'lodash/sortBy'
import Layout from '../../core/components/Layout'
import { STATUS_MOVE } from '../../academy/urls'
import Table from '../../core/components/Table'
import { checkPermission as cp } from '../../users/utils/auth'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import ColumnCreate from '../components/ColumnCreate'
import RoomCreate from '../components/RoomCreate'
import ColumnItem from '../components/ColumnItem'
import RoomItem from '../components/RoomItem'
import SettingsTabs from '../components/SettingsTabs'
import Card from '../../core/components/Card'
import SalesChannelItem from '../components/SalesChannelItem'
import SalesChannelCreate from '../components/SalesChannelCreate'
import CourseCreate from '../components/CourseCreate'
import CourseItem from '../components/CourseItem'
import { StatusType } from '../../academy/types'
import { useStatuses, useStatusMove } from '../../academy/hooks/status'
import { useRooms } from '../../academy/hooks/room'
import { useCourses } from '../../academy/hooks/course'
import { useSalesChannel } from '../../academy/hooks/salesChannel'

export default function SettingsAcademy() {
    const statuses = useStatuses()
    const rooms = useRooms()
    const salesChannels = useSalesChannel({ isArchive:true })
    const courses = useCourses()
    const statusMove = useStatusMove()

    const [showSalesChanelCreateModal, hideSalesChanelCreateModal] = useModal(
        <SalesChannelCreate onCancel={() => {
            hideSalesChanelCreateModal()
        }} onSuccess={() => {
            hideSalesChanelCreateModal()
            salesChannels.refetch()
        }} />,
    )

    const [showColumnCreateModal, hideColumnCreateModal] = useModal(
        <ColumnCreate onSuccess={() => {
            hideColumnCreateModal()
            statuses.refetch()
        }} onCancel={() => hideColumnCreateModal()} />,
    )

    const [showRoomCreateModal, hideRoomCreateModal] = useModal(
        <RoomCreate onSuccess={async () => {
            await rooms.refetch()
            hideRoomCreateModal()
        }} onCancel={() => hideRoomCreateModal()} />,
    )

    const [showCourseCreateModal, hideCourseCreateModal] = useModal(
        <CourseCreate onSuccess={async () => {
            await courses.refetch()
            hideCourseCreateModal()
        }} onCancel={() => hideCourseCreateModal()} />,
    )

    function changePosition(status: StatusType, direction: number) {
        if (!statuses.data) {
            return
        }

        statuses.setData({
            count: statuses.data?.count || 0,
            results: statuses.data?.results.map((column) => {
                if (column.id === status.id) {
                    return { ...column, position: status.position + direction }
                }
                if (column.position === status.position + direction) {
                    return { ...column, position: status.position }
                }
                return column
            }),
        })
        statusMove.mutate({
            url: STATUS_MOVE.replace('{id}', String(status.id)),
            data: { position: status.position + direction },
        })
    }

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Этапы продаж (Воронка)</div>

                            {cp('staff.add_employee') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showColumnCreateModal}
                                        text="Добавить"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        <Table
                            loading={statuses.isLoading}
                            totalCount={statuses.data?.count || 0}
                            items={sortBy(statuses.data?.results, 'position')}
                            renderItem={(status) => (
                                <ColumnItem
                                    key={status.id}
                                    onDelete={statuses.refetch}
                                    onUpdate={statuses.refetch}
                                    status={status}
                                    changePosition={changePosition}
                                    columns={statuses.data?.results} />
                            )} />
                    </Card> <br />

                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Кабинеты</div>

                            {cp('academy.add_room') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showRoomCreateModal}
                                        text="Добавить"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        <Table
                            loading={rooms.isLoading}
                            totalCount={rooms.data?.count || 0}
                            items={rooms.data?.results}
                            renderItem={(room) => (
                                <RoomItem
                                    key={room.id}
                                    room={room}
                                    onUpdate={rooms.refetch}
                                    onDelete={rooms.refetch} />
                            )} />
                    </Card>
                </div>

                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Каналы продаж</div>
                            {cp('academy.add_advert') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showSalesChanelCreateModal}
                                        text="Добавить"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        {cp('academy.view_advert') ? (
                            <Table
                                loading={salesChannels.isLoading}
                                totalCount={salesChannels.data?.count || 0}
                                items={salesChannels.data?.results}
                                renderItem={(item) => (
                                    <SalesChannelItem
                                        key={item.id}
                                        onUpdate={salesChannels.refetch}
                                        onDelete={salesChannels.refetch}
                                        item={item} />
                                )} />
                        ) : null}
                    </Card><br />

                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Курсы</div>

                            {cp('academy.add_course') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showCourseCreateModal}
                                        text="Добавить"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        {cp('academy.view_course') ? (
                            <Table
                                loading={courses.isLoading}
                                totalCount={courses.data?.count || 0}
                                items={sortBy(courses.data?.results || [], 'position')}
                                renderItem={(course) => (
                                    <CourseItem
                                        key={course.id}
                                        onDelete={courses.refetch}
                                        onUpdate={courses.refetch}
                                        course={course} />
                                )} />
                        ) : null}
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
