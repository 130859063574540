import { Formik, Form } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import { useMessage } from '../../core/hooks/message'
import { checkPermission as cp } from '../../users/utils/auth'
import { DebtorType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useDebtorDeactivation, useDebtorDeactivationRequest } from '../hooks/debtor'

export type Props = {
    debtor: DebtorType
    onSuccess: (data: DebtorType) => void
    onDeactivation: () => void
}

export default function DebtorDeactivation({ debtor, onSuccess, onDeactivation }: Props) {
    const initialValues = { ...debtor, deactivationReason: debtor.deactivationReason || '' } as DebtorType
    const deactivationRequest = useDebtorDeactivationRequest(debtor.id)
    const deactivation = useDebtorDeactivation(debtor.id)
    const [showMessage] = useMessage()

    async function onSubmit(data: DebtorType, actions: FormikHelpers<DebtorType>) {
        await deactivationRequest.mutate({ data })
        actions.resetForm()
        onSuccess({ ...data, deactivationAttempt: true })
    }

    async function deactivate() {
        if (!global.confirm('Вы действительно хотите закрыть этот долг?')) return

        const { error } = await deactivation.mutate()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDeactivation()
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                <b>Деактивация студента &nbsp; {debtor.student.customer?.name }</b>

                <Input
                    className="mt-3"
                    name="deactivationReason"
                    component="textarea"
                    placeholder="Причина"
                    validate={required} />

                <Button
                    loading={deactivationRequest.isLoading}
                    text="Оправить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-info mr-2" />

                {cp('academy.delete_debtor') ? (
                    <Button
                        onClick={deactivate}
                        loading={deactivation.isLoading}
                        text="Деактивировать"
                        icon="ion-md-archive"
                        className="is-danger" />
                ) : null}
            </Form>
        </Formik>
    )
}
