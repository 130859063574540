import { cn } from '../../core/utils/classname'
import { NavLink, useLocation } from 'react-router-dom'
import { checkPermission as cp } from '../../users/utils/auth'

export default function AcademyTabs() {
    const { pathname } = useLocation()

    return (
        <div className="tabs">
            <ul>
                {cp('academy.view_customer') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/customers') })}>
                        <NavLink to="/academy/customers">Клиенты</NavLink>
                    </li>
                ) : null}

                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/group') })}>
                        <NavLink to="/academy/group">Группы</NavLink>
                    </li>
                ) : null}

                {cp('academy.view_student') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/debtors') })}>
                        <NavLink to="/academy/debtors">Должники</NavLink>
                    </li>
                ) : null}

                {cp('finance.view_cashback') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/cashback') })}>
                        <NavLink to="/academy/cashback">Кешбек</NavLink>
                    </li>
                ) : null}

                {cp('academy.view_timeline') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/timeline') })}>
                        <NavLink to="/academy/timeline">Расписание</NavLink>
                    </li>
                ) : null}

                {cp('academy.view_customer') ? (
                    <li className={cn({ 'is-active': pathname.startsWith('/academy/birthdays') })}>
                        <NavLink to="/academy/birthdays">Дни рождения</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
