import WalletForm from './WalletForm'
import { WalletType } from '../../finance/types'
import { useWalletUpdate } from '../../finance/hooks/wallet'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
    wallet: WalletType
}

export default function WalletUpdate({  wallet, onSuccess, onCancel }: Props) {
    const walletUpdate = useWalletUpdate(wallet.id)

    async function onSubmit(data: WalletType) {
        await walletUpdate.mutate({ data: { ...data, wallet } })
        onSuccess()
    }

    return (
        <div>
            <b>Изменить счёт</b><br /><br />

            <WalletForm
                onSubmit={onSubmit}
                loading={walletUpdate.isLoading}
                onCancel={onCancel}
                values={wallet} />
        </div>
    )
}
