import { Form, Formik } from 'formik'
import { required } from '../../core/utils/validators'
import Button from '../../core/components/Button'
import Input from '../../core/components/Input'
import ColorPicker from '../../core/components/ColorPicker'
import { FormSubmit } from '../../core/types'
import { StatusType } from '../../academy/types'

export type Props = {
    initialValues?: StatusType
    onSubmit: FormSubmit<StatusType>
    loading?: boolean
    onCancel: () => void
}

export default function ColumnForm({ initialValues, onSubmit, loading, onCancel }: Props) {
    return (
        <Formik initialValues={{ title: '', color: '#3273dc', ...initialValues } as StatusType} onSubmit={onSubmit}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input
                            label="Заголовок"
                            name="title"
                            disabled={loading}
                            validate={required}
                            placeholder="Введите заголовок колонки" />
                    </div>

                    <div className="column">
                        <ColorPicker
                            label="Цвет"
                            validate={required}
                            name="color" />
                    </div>
                </div>

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
