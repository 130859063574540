import TransactionCategoryForm from './TransactionCategoryForm'
import { TransactionCategoryType } from '../../finance/types'
import { useTransactionCategoryCreate } from '../../finance/hooks/transactionCategories'

export type Props = {
    onSuccess: () => void
    onCancel: () => void
}

export default function TransactionCategoryCreate({ onCancel, onSuccess }: Props) {
    const transactionCreate = useTransactionCategoryCreate()

    async function onSubmit(data: TransactionCategoryType) {
        const defaultAmount = data.defaultAmount ? data.defaultAmount : null
        await transactionCreate.mutate({ data: { ...data, defaultAmount } })
        onSuccess()
    }

    return (
        <div>
            <b>Добавить Категорию расходов</b><br /><br />
            <TransactionCategoryForm onSubmit={onSubmit} onCancel={onCancel} loading={transactionCreate.isLoading} />
        </div>
    )
}
