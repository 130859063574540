import { getDateTime } from '../../core/utils/date'
import Button from '../../core/components/Button'
import { getReason } from '../utils/constants'
import { StudentType } from '../types'

export type Props = {
    student: StudentType
    onCancel: () => void
}

export default function StudentInfo({ student, onCancel }: Props) {
    return (
        <div>
            <b>
                Информация о студенте: {student.customer?.name} &nbsp;

                <div className="is-pulled-right">
                    {student.stoppedAt
                        ? <div className="tag is-danger">Не активен</div>
                        : <div className="tag is-success">Активен</div>}
                </div>
            </b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Ф.И.О:</td>
                        <td>{student.customer?.name}</td>
                    </tr>

                    <tr>
                        <td>Номер телефона:</td>
                        <td>{student.customer?.phone}</td>
                    </tr>

                    <tr>
                        <td>Канал продаж:</td>
                        <td>{student.customer?.advert.name}</td>
                    </tr>

                    <tr>
                        <td>Дата добавления клиента:</td>
                        <td>{getDateTime(student.customer?.createdAt)}</td>
                    </tr>

                    <tr>
                        <td>Менеджер продаж:</td>
                        <td>{student.customer?.seller.name}</td>
                    </tr>

                    <tr>
                        <td>В группе с:</td>
                        <td>{getDateTime(student.createdAt)}</td>
                    </tr>

                    {student.stoppedAt ? (
                        <tr>
                            <td>Деактивирован в:</td>
                            <td>{getDateTime(student.stoppedAt)}</td>
                        </tr>
                    ) : null}

                    <tr>
                        <td>Примечание:</td>
                        <td>{student.customer?.description}</td>
                    </tr>

                    {student.stoppedReason ? (
                        <tr>
                            <td>Причина деактивации:</td>
                            <td>{getReason(student.stoppedReason)}</td>
                        </tr>
                    ) : null}

                    {student.stoppedText ? (
                        <tr>
                            <td>Описание деактивации:</td>
                            <td>{student.stoppedText}</td>
                        </tr>
                    ) : null}
                </tbody>
            </table>

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;
        </div>
    )
}
