import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { EmployeeSalaryHistoryType, EmployeeSalaryType, EmployeeType } from '../types'
import { EMPLOYEE_DETAIL, EMPLOYEE_FIRE,
    EMPLOYEE_INVITATION,
    EMPLOYEE_LIST,
    EMPLOYEE_SALARY,
    EMPLOYEE_SALARY_HISTORY, EMPLOYEE_SIMPLE_LIST,
    SALARY_LIST } from '../urls'
import { TransactionType } from '../../finance/types'
import { useFetch, useFetchOne, useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export type EmployeesParams = {
    search?: string
    position?: string
    status?: string
    page?: number
    size?: number
}

export function useEmployeesList({ position, status, page, size }: EmployeesParams, manual = false) {
    return useLoad<Pagination<EmployeeType>>({
        url: EMPLOYEE_LIST,
        params: { position, status, page, size },
    }, [position, status, page, size], { manualLoad: manual })
}

export function useEmployees({ position, status }: EmployeesParams) {
    return useInfiniteFetch<EmployeeType>(
        ['employees', { position, status }],
        async ({ pageParam }) => request({
            url: EMPLOYEE_LIST,
            params: { position, status, page: pageParam, size: 50 },
        }),
    )
}

export function useEmployeesSimple({ position }: EmployeesParams = {}) {
    return useLoad<Pagination<EmployeeType>>({ url: EMPLOYEE_SIMPLE_LIST, params: { position } }, [position])
}

export function useEmployee(id: string) {
    return useFetchOne<EmployeeType>(['employee', id], () => request({ url: EMPLOYEE_DETAIL.replace('{id}', id) }))
}

export function useEmployeeSalary(id: string) {
    return useFetch<EmployeeSalaryType[]>(
        ['employeeSalary', id],
        () => request({ url: EMPLOYEE_SALARY.replace('{id}', id) }),
    )
}

export function useEmployeeSalaryList(id: string) {
    return useInfiniteFetch<TransactionType>(['employeeSalaryList', id], ({ pageParam }) => request({
        url: SALARY_LIST.replace('{employee_id}', id!),
        params: { employee: id, page: pageParam },
    }))
}

export function useEmployeeSalaryHistory(id: number, month: string) {
    return useLoad<EmployeeSalaryHistoryType>({
        url: EMPLOYEE_SALARY_HISTORY.replace('{id}', String(id)),
        params: { date: `${month}-01` },
    })
}

export function useEmployeeCreate() {
    return usePostRequest<EmployeeType>({ url: EMPLOYEE_LIST })
}

export function useEmployeeInvite(id: number) {
    return usePostRequest({ url: EMPLOYEE_INVITATION.replace('{id}', String(id)) })
}

export function useEmployeeUpdate(id: number) {
    return usePutRequest<EmployeeType>({ url: EMPLOYEE_DETAIL.replace('{id}', String(id)) })
}

export function useEmployeeDelete(id: number) {
    return useDeleteRequest({ url: EMPLOYEE_DETAIL.replace('{id}', String(id)) })
}

export function useEmployeeFire(id: number) {
    return usePutRequest<{ isActive: boolean }>({ url: EMPLOYEE_FIRE.replace('{id}', String(id)) })
}
