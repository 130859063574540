export const positions: Record<string, string> = {
    admin: 'Админ / директор',
    accountant: 'Бухгалтер',
    marketing: 'Менеджер продаж',
    seller: 'Продавец',
    teacher: 'Учитель',
}

export const classes: Record<string, string> = {
    admin: 'is-success',
    accountant: 'is-danger',
    marketing: 'is-primary',
    seller: 'is-link',
    teacher: 'is-warning',
}

export function position(name: string) {
    return positions[name]
}

export function positionClass(name: string) {
    return classes[name]
}
