import React, { Fragment } from 'react'
import { AxiosResponse } from 'axios'

export type ItemProps = {
    field: string
    error?: string
}

function ErrorItem({ field, error }: ItemProps) {
    if (field === 'nonFieldErrors' || field === 'detail') {
        return <li><strong>{error}</strong></li>
    }

    return (
        <li>
            <strong>{field}: </strong>
            {error?.[0]}
        </li>
    )
}


export type Props = {
    error?: AxiosResponse<Record<string, string>>
}

export default function ServerError({ error }: Props) {
    if (!error || !error.data) return <Fragment />

    return (
        <div className="message is-danger">
            <div className="message-body">
                <ul>
                    {Object.keys(error.data).map((key) => (
                        <ErrorItem key={key} field={key} error={error?.data[key]} />
                    ))}
                </ul>
            </div>
        </div>
    )
}
