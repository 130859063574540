import { integersOnly } from './number'

export default function phoneFormat(number?: string | null) {
    if (!number) return ''

    const phone = String(integersOnly(number))
    if (phone.length !== 12) return phone

    return `+${phone.substr(0, 3)} 
    ${phone.substr(3, 2)}-${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`
}
