import moment from 'moment'
import Layout from '../../core/components/Layout'
import AcademyTabs from '../components/AcademyTabs'
import DebtorItem from '../components/DebtorItem'
import DebtorsMonths from '../components/DebtorsMonths'
import { useQueryParams } from '../../core/hooks/queryString'
import { DebtorsParams, useDebtors } from '../hooks/debtor'
import InfiniteTable from '../../core/components/InfiniteTable'

export default function Debtors() {
    const params = useQueryParams()
    const month = params.month || moment().format('YYYY-MM')
    const debtors = useDebtors({ month, page: params.page } as DebtorsParams)

    return (
        <Layout>
            <AcademyTabs />
            <DebtorsMonths month={month as string} />

            <InfiniteTable
                responsive
                query={debtors}
                columns={{
                    expiresAt: 'Срок',
                    student: 'Имя',
                    phone: 'Телефон',
                    group: 'Группа',
                    debt: 'Долг',
                    actions: '',
                }}
                renderItem={(item) => (
                    <DebtorItem
                        key={item.id}
                        onPaymentCreate={debtors.refetch}
                        onDelayCreated={(expiresAt) => debtors.updateItem({ ...item, expiresAt })}
                        onUpdate={(data) => debtors.updateItem({ ...item, ...data })}
                        onDeactivation={() => debtors.deleteItem(item.id)}
                        item={item} />
                )} />
        </Layout>
    )
}
