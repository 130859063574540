import { Fragment } from 'react'
import { cn } from '../../core/utils/classname'
import Table from '../../core/components/Table'
import Loader from '../../core/components/Loader'
import CustomerTableItem from './CustomerTableItem'
import Button from '../../core/components/Button'
import { useModal } from '../../core/hooks/modal'
import CustomerCreate from './CustomerCreate'
import { CustomerType, StatusType } from '../types'
import { InfiniteScrollReturns } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'

export type Props = {
    statuses: StatusType[]
    onUpdate: (id: number, data: CustomerType) => void
    type: CustomerType['type'] | ''
    customers: InfiniteScrollReturns<Pagination<CustomerType>, HTMLDivElement>
}

export default function CustomerTable({ statuses, onUpdate, customers, type }: Props) {
    const [showCreateModal, hideCreateModal] = useModal(
        <CustomerCreate
            onCancel={() => hideCreateModal()}
            onSuccess={() => {
                customers.reload()
                hideCreateModal()
            }} />,
    )

    return (
        <Fragment>
            <Button
                text="Добавить"
                onClick={showCreateModal}
                className={cn('is-info is-small mb-2 is-pulled-right')}
                icon="ion-md-add" />

            <div className="is-clearfix" />

            <Table
                responsive
                showEmptyMessage={!customers.isLoading}
                columns={{ name: 'Имя', status: 'Состояние', groups: 'Группы', actions: '' }}
                totalCount={customers.data?.count || 0}
                items={customers.data?.results}
                renderItem={(item) => (
                    <CustomerTableItem
                        key={item.id}
                        customer={item}
                        onDelete={customers.reload}
                        onUpdate={onUpdate}
                        showLabels={type === ''}
                        statusList={statuses} />
                )} />

            <Loader show={customers.isLoading} center large />

            <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                {!customers.hasMore && !customers.isLoading && customers.data?.results.length !== 0
                    ? 'Загрузили всех студентов' : ''}
            </div>
        </Fragment>
    )
}
