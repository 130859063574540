import ProtectedRoute from '../core/components/ProtectedRoute'
import NotFound from '../core/pages/NotFound'
import { Route, Routes } from 'react-router-dom'
import StatisticsAcademy from './pages/StatisticsAcademy'
import StatisticsFinance from './pages/StatisticsFinance'

export default function StatsRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="academy" element={<StatisticsAcademy/>} />
                <Route path="finance" element={<StatisticsFinance/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
