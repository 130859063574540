/* eslint-disable no-nested-ternary */
import { SALES_CHANNEL_CHANGE_MAIN } from '../../academy/urls'
import { useModal } from '../../core/hooks/modal'
import { checkPermission as cp } from '../../users/utils/auth'
import { useMessage } from '../../core/hooks/message'
import Loader from '../../core/components/Loader'
import SalesChannelUpdate from './SalesChannelUpdate'
import { AdvertType } from '../../academy/types'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import
{ useSalesChannelDelete, useSalesChannelMainUpdate, useSalesChannelUpdate } from '../../academy/hooks/salesChannel'

export type Props = {
    item: AdvertType
    onDelete: () => void
    onUpdate: () => void
}

export default function SalesChannelItem({ item, onDelete, onUpdate }: Props) {
    const salesChannelDelete = useSalesChannelDelete(item.id)
    const salesChannelUpdate = useSalesChannelUpdate(item.id)
    const salesChannelChangeMain = useSalesChannelMainUpdate()

    async function changeMain() {
        await salesChannelChangeMain.mutate({ url: SALES_CHANNEL_CHANGE_MAIN.replace('{id}', String(item.id)) })
        onUpdate()
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <SalesChannelUpdate salesChannel={item} onSuccess={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => hideUpdateModal()} />,
    )

    const [showMessage] = useMessage()

    async function deleteSalesChannel() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await salesChannelDelete.mutate()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            await salesChannelDelete.mutate()
            onDelete()
        }
    }

    async function onArchive(isArchive: boolean) {
        if (global.confirm(`Вы действительно хотите ${isArchive ? 'архивирововать' : 'активирововать'}?`)) {
            const { error } = await salesChannelUpdate.mutate({ data: { ...item, isArchive } })

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }

            onUpdate()
        }
    }

    return (
        <tr>
            <td>
                {item.name}<span className="is-size-7 has-text-success">{item.isMain ? ' (основной)' : ''}</span>
            </td>

            <td className="has-text-right">
                {cp('academy.delete_advert') ? (
                    !salesChannelDelete.isLoading ? (
                        <i onClick={deleteSalesChannel} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('academy.change_advert') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}

                {cp('finance.change_advert') && !item.isMain ? (
                    <Dropdown trigger={(<i className="icon ion-md-more" />)}>
                        <DropdownItem text="Сделать главным" icon="ion-md-checkmark" onClick={changeMain} />

                        <DropdownItem
                            text={item.isArchive ? 'Активирововать' : 'Архивирововать'}
                            icon={item.isArchive ? 'ion-md-undo' : 'ion-md-archive'}
                            onClick={() => onArchive(!item.isArchive)} />
                    </Dropdown>
                ) : null}
            </td>
        </tr>
    )
}
