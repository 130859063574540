import { CashbackType } from '../types'
import { checkPermission as cp } from '../../users/utils/auth'
import { useState } from 'react'
import { cn } from '../../core/utils/classname'
import { useModal } from '../../core/hooks/modal'
import CashbackClose from './CashbackClose'
import phoneFormat from '../../core/utils/phones'
import { formatDate } from '../../core/utils/date'

export type Props = {
    item: CashbackType;
    onSuccess: () => void
}

export default function CashbackItem({ item, onSuccess  }: Props) {
    const [showDropdown, setShowDropdown] = useState(false)

    const [showCashbackModal, hideCashbackModal] = useModal(
        <CashbackClose cashback={item} onSuccess={() => {
            onSuccess()
            hideCashbackModal()
        }} onCancel={() => hideCashbackModal()}/>,
    )

    return (
        <tr className={item.paidAt ? 'has-text-grey' : 'has-text-dark'}>
            <td data-label="Имя">
                <span className={!item.paidAt ? 'has-text-dark has-text-weight-semibold' : ''}>
                    {item.student.customer?.name}</span>
            </td>
            <td data-label="Группа">
                <span>{item.group.name}({item.group.teacher?.name})</span>
            </td>
            <td data-label="Телефон">
                <a className={item.paidAt ? 'has-text-grey' : 'has-text-dark'}>
                    {phoneFormat(item.student.customer?.phone)}
                </a><br />
                <a className={item.paidAt ? 'has-text-grey' : 'has-text-dark'}>
                    {phoneFormat(item.student.customer?.phoneSecond)}
                </a><br />
            </td>
            <td data-label="Дата выплаты">
                <span >
                    {item.paidAt ? (
                        formatDate(item.paidAt)
                    ) : (
                        <span className="is-italic has-text-dark">не указан</span>
                    )}
                </span>
            </td>
            <td data-label="Кешбек">
                <span className={item.paidAt ? 'has-text-grey' : 'has-text-success'}>{item.amount} сум</span>
            </td>
            <td data-label="">
                {cp('finance.close_cashback') ? (
                    <div
                        className={cn('dropdown is-right', { 'is-active': showDropdown })}
                        onMouseLeave={() => setShowDropdown(false)}>

                        <div className="dropdown-trigger">
                            {!item.paidAt ? 
                                <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                                : null}
                        </div>

                        <div className="dropdown-menu" id="dropdown-menu">
                            <div className="dropdown-content">
                                {cp('finance.change_cashback') ? (
                                    <a className="dropdown-item" onClick={showCashbackModal}>
                                        <i className="icon ion-md-cash" />
                                        Оплатитъ
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </td>
        </tr>
    )
}
