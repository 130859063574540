import { Fragment } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { DragDropContext, DraggableLocation, DropResult as BaseDropResult } from 'react-beautiful-dnd'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { InfiniteScrollReturns } from '../../core/hooks/request.legacy'
import { CUSTOMER_MOVE } from '../urls'
import CustomerBoardColumn from './CustomerBoardColumn'
import Loader from '../../core/components/Loader'
import { useModal } from '../../core/hooks/modal'
import StudentCreate from './StudentCreate'
import { CustomerType, StatusType } from '../types'
import { Pagination } from '../../core/types'
import { useCustomerMove } from '../hooks/customer'

function changeCustomerPositionAndColumn(
    customers: CustomerType[],
    customerId: string,
    source: DraggableLocation,
    destination?: DraggableLocation,
) {
    const sourcePosition = source.index + 1
    const destinationPosition = (destination?.index || 0) + 1

    return customers.map((customer) => {
        const isStateChanged = String(source.droppableId) !== String(destination?.droppableId)
        const isMovedUp = sourcePosition < destinationPosition && !isStateChanged
        const isMovedDown = sourcePosition > destinationPosition && !isStateChanged && !isMovedUp
        const positionGtOldPosition = customer.position > sourcePosition
        const positionLtOldPosition = customer.position < sourcePosition
        const srcStateChanged = String(customer.state) === source.droppableId
        const destStateChanged = String(customer.state) === destination?.droppableId
        const positionGteNewPosition = customer.position >= destinationPosition
        const positionLteDestPosition = customer.position <= destinationPosition
        const positionGtNewPositionAndStateEState = positionGteNewPosition && destStateChanged

        if (customerId === String(customer.id)) {
            return { ...customer, state: Number(destination?.droppableId), position: destinationPosition }
        }

        if ((isStateChanged && positionGtOldPosition && srcStateChanged)
        || (isMovedUp && positionGtOldPosition && positionLteDestPosition && destStateChanged)) {
            return { ...customer, position: customer.position - 1 }
        }

        if ((isStateChanged && positionGtNewPositionAndStateEState)
        || (isMovedDown && positionLtOldPosition && positionGtNewPositionAndStateEState)) {
            return { ...customer, position: customer.position + 1 }
        }

        return customer
    })
}

export type Props = {
    columns: StatusType[]
    onUpdate: (customerId: number, customer: CustomerType) => void
    onReloadStatues: () => void
    type: CustomerType['type'] | ''
    customers: InfiniteScrollReturns<Pagination<CustomerType>, HTMLDivElement>
}

type DropResult = BaseDropResult & {
    isStudentCreated?: boolean
}

type StudentCreateProps = {
    customer: CustomerType
    source: DraggableLocation,
    destination?: DraggableLocation,
}

export default function CustomerBoard({ columns, onUpdate, onReloadStatues, customers, type }: Props) {
    const moveCustomer = useCustomerMove()
    const acceptedStatus = find(columns, { type: 'accepted' })

    const [showCustomerAcceptModal, hideCustomerAcceptModal] = useModal((
        { customer, source, destination }: StudentCreateProps,
    ) => (
        <StudentCreate
            customer={customer}
            onCancel={() => hideCustomerAcceptModal()}
            onSuccess={async () => {
                await onDragEnd({
                    source: source,
                    destination: destination,
                    draggableId: String(customer.id),
                    isStudentCreated: true,
                } as DropResult)
                hideCustomerAcceptModal()
            }} />
    ))

    async function onDragEnd({ source, destination, draggableId: customerId, isStudentCreated = false }: DropResult) {
        if (!destination) return

        const customer = find(customers.data?.results, { id: Number(customerId) })
        if (destination.droppableId === String(acceptedStatus?.id) && !isStudentCreated
            && String(source.droppableId) !== String(destination.droppableId) && isEmpty(customer?.students)) {
            showCustomerAcceptModal({ customer, source, destination })
        }

        // update status
        const results = changeCustomerPositionAndColumn(
            customers.data?.results || [],
            customerId, source, destination,
        )
        customers.setData({ count: customers.data?.count || 0,  results })
        const data = { position: destination.index + 1, state: Number(destination.droppableId) }
        await moveCustomer.mutate({ url: CUSTOMER_MOVE.replace('{id}', customerId), data })
        onReloadStatues()
    }

    async function onCreate(data: CustomerType) {
        onReloadStatues()
        customers.setData({
            count: (customers.data?.count || 0) + 1,
            results: [data, ...customers.data?.results || []],
        })
    }

    async function onDelete(customerId: number) {
        onReloadStatues()
        const results = customers.data?.results.filter((customer) => customer.id !== customerId) || []
        // eslint-disable-next-line no-param-reassign
        customers.setData({ count: (customers.data?.count || 0) - 1, results })
    }

    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="columns">
                    {columns.map((column) => (
                        <div key={column.id} className="column">
                            <CustomerBoardColumn
                                columnKey={column.id}
                                showLabels={type === ''}
                                customers={sortBy(filter(
                                    customers.data ? customers.data?.results : [],
                                    { state: column.id },
                                ), 'position')}
                                column={column}
                                onDelete={onDelete}
                                onCreate={onCreate}
                                onUpdate={onUpdate}
                                lineColor={column.color} />
                        </div>
                    ))}
                </div>
            </DragDropContext>

            <Loader show={customers.isLoading} center large />

            <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                {!customers.hasMore && !customers.isLoading && customers.data?.results.length !== 0
                    ? 'Загрузили всех клиентов' : ''}
            </div>
        </Fragment>
    )
}
