import { useDeleteRequest, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { TRANSACTION_DETAIL, TRANSACTION_LIST, TRANSACTION_TRANSFER } from '../urls'
import { TransactionType } from '../types'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export type TransactionsParams = {
    search?: string
    wallet?: number | string
    student?: number
}

export function useTransaction({ wallet, search, student }: TransactionsParams) {
    const PAGE_SIZE = 100

    return useInfiniteFetch<TransactionType>(
        ['transactions', { wallet, search, student }],
        ({ pageParam }) => request({
            url: TRANSACTION_LIST,
            params: { search, page: pageParam, wallet, student, size: PAGE_SIZE },
        }), {}, PAGE_SIZE,
    )
}

export function useTransactionCreate() {
    return usePostRequest<TransactionType>({ url: TRANSACTION_LIST })
}

export function useTransactionUpdate(id: number) {
    return usePutRequest<TransactionType>({ url: TRANSACTION_DETAIL.replace('{id}', String(id)) })
}

export function useTransactionDelete(id: number) {
    return useDeleteRequest({ url: TRANSACTION_DETAIL.replace('{id}', String(id)) })
}

export function useTransfer(id: number) {
    return usePostRequest<TransactionType>({ url: TRANSACTION_TRANSFER.replace('{id}', String(id)) })
}
