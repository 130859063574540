import { domain } from '../core/utils/request'

export const TELEGRAM_INVITATION = `${domain}/bot/telegram-invite/{id}`

export const EMPLOYEE_LIST = '/staff/employee/'
export const EMPLOYEE_DETAIL = '/staff/employee/{id}'
export const EMPLOYEE_FIRE = '/staff/employee-fire/{id}'

export const EMPLOYEE_SIMPLE_LIST = '/staff/employee-simple-list/'
export const EMPLOYEE_SALARY = '/staff/employee-salary/{id}'
export const EMPLOYEE_SALARY_HISTORY = '/staff/employee-salary/{id}/history'
export const EMPLOYEE_INVITATION = '/staff/employee/{id}/invite'

export const FINE_LIST = '/staff/fine'
export const FINE_DETAIL = '/staff/fine/{id}'

export const SALARY_LIST = 'finance/salary/{employee_id}'
