import { useDeleteRequest, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { RoomType } from '../types'
import { ROOM_DETAIL, ROOM_LIST } from '../urls'
import { useFetchList } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export function useRooms() {
    return useFetchList<RoomType>(['rooms'], () => request({ url: ROOM_LIST }))
}

export function useRoomCreate() {
    return usePostRequest({ url: ROOM_LIST })
}

export function useRoomUpdate(id: number) {
    return usePutRequest({ url: ROOM_DETAIL.replace('{id}', String(id)) })
}

export function useRoomDelete() {
    return useDeleteRequest()
}

export function useChangeMainRoom() {
    return usePutRequest()
}
