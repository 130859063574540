/* eslint-disable no-nested-ternary */
import { checkPermission as cp } from '../../users/utils/auth'
import { useTaskDelete, useTaskUpdate } from '../hooks/tasks'
import { useModal } from '../../core/hooks/modal'
import { getDateTime } from '../../core/utils/date'
import Loader from '../../core/components/Loader'
import TaskUpdate from './TaskUpdate'
import { TaskType } from '../types'

export type Props = {
    item: TaskType
    onDelete: (id: number) => void
    onUpdate: (item: TaskType) => void
}

export default function TaskItem({ item, onDelete, onUpdate }: Props) {
    const deleteTask = useTaskDelete(item.id)
    const changeStatusTask = useTaskUpdate(item.id)

    async function handleDeleteTask() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await deleteTask.mutateAsync({})
            onDelete(item.id)
        }
    }

    async function changeStatus() {
        if (!global.confirm('Вы действительно хотите изменить статус?')) return
        const response = await changeStatusTask.mutateAsync({
            ...item,
            assigned: item.assigned.id,
            status: item.status === 'waiting' ? 'complete' : 'waiting',
        })
        onUpdate(response)
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TaskUpdate task={item} onUpdate={(task) => {
            onUpdate(task)
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td data-label="Заголовок">
                {item.title || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td data-label="Ответственный">
                {item.assigned.firstName || item.assigned.lastName || item.assigned.email}
            </td>

            <td data-label="Срок">
                {!item.dueTime ? (
                    <span className="is-italic has-text-grey">не указан</span>
                ) : getDateTime(item.dueTime)}
            </td>

            <td data-label="Статус" onClick={changeStatus}>
                {item.status === 'complete' ? (
                    <span className="tag is-success is-rounded pointer">Выполнено</span>
                ) : <span className="tag is-warning pointer is-rounded">Ждёт выполнения</span>}
            </td>

            <td data-label="">
                {cp('task.delete_task') ? ((
                    !deleteTask.isLoading ? (
                        <i onClick={handleDeleteTask} className="icon ion-md-trash pointer" />
                    ) : <Loader className="icon" />
                )) : null}

                {cp('task.change_task') ? (
                    <i onClick={showUpdateModal} className="icon ion-md-create pointer" />
                ) : null}
            </td>
        </tr>
    )
}
