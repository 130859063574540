import map from 'lodash/map'
import { position } from '../utils/position'
import EmployeeForm, { EmployeeFormType } from './EmployeeForm'
import ServerError from '../../core/components/ServerError'
import { EmployeeType } from '../types'
import { FormikHelpers } from 'formik/dist/types'
import { useEmployeeUpdate } from '../hooks/employee'

export type Props = {
    onCancel: () => void
    employee: EmployeeType
    onUpdate: (item: EmployeeType) => void
}

export default function EmployeeUpdate({ onCancel, onUpdate, employee }: Props) {
    const employeeUpdate = useEmployeeUpdate(employee.id)

    async function onSubmit(data: EmployeeFormType, actions: FormikHelpers<EmployeeFormType>) {
        const response = await employeeUpdate.mutate({ data: {
            ...data, positions: map(data.positions, 'value'),
        } })

        if (response.success) {
            actions.resetForm()
            onUpdate(response.data)
        }
    }

    const initialValues = {
        ...employee,
        positions: employee.positions.map((item) => ({ label: position(item), value: item })),
    } as unknown as EmployeeFormType

    console.log(initialValues)

    return (
        <div>
            <b>Редактировать Сотрудника</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <EmployeeForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.isLoading} />
        </div>
    )
}
