import { format } from '../../core/utils/number'
import { getDateTime, getMonth } from '../../core/utils/date'
import Table from '../../core/components/Table'
import Button from '../../core/components/Button'
import { causes } from '../utils/cause'
import { EmployeeSalaryType, EmployeeType } from '../types'
import { useEmployeeSalaryHistory } from '../hooks/employee'

export type Props = {
    onCancel: () => void
    employeeSalary: EmployeeSalaryType
    employeeDetail: EmployeeType
}

export default function EmployeeHistorySalary({ onCancel, employeeSalary, employeeDetail }: Props) {
    const transactions = useEmployeeSalaryHistory(employeeDetail.id, employeeSalary.month)

    return (
        <div>
            <span className="is-size-4 ">
                Доход сотрудника за {getMonth(employeeSalary.month, true)}:&nbsp;
                <span className="has-text-success">{format(employeeSalary.profit || 0)} сум</span>
            </span>

            <br />
            <br />

            <table className="table is-fullwidth">
                <tbody>
                    <tr>
                        <td>Фиксированная зарпалата</td>
                        <td>{format(employeeDetail.salary)} сум</td>
                    </tr>
                </tbody>
            </table>

            <span className="is-size-5">Доля Учителя</span>
            <Table
                loading={transactions.isLoading}
                totalCount={transactions.data?.teacher.length || 0}
                items={transactions.data?.teacher}
                columns={{ customer: 'Студент', date: 'Дата', profit: 'Доход' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            {item.student.customer?.name}
                        </td>

                        <td>{getDateTime(item.createdAt)}</td>
                        <td>{format(item.profit)} Сум</td>
                    </tr>
                )} />

            <br />

            <span className="is-size-5">Доля Менеджера ({employeeDetail.sellerShare}%)</span>

            <Table
                loading={transactions.isLoading}
                totalCount={transactions.data?.seller.length || 0}
                items={transactions.data?.seller}
                columns={{ customer: 'Студент', date: 'Дата', profit: 'Доход' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            {item.student.customer?.name}
                        </td>

                        <td>{getDateTime(item.createdAt)}</td>
                        <td>{format(item.profit)} Сум</td>
                    </tr>
                )} />

            <br />

            <span className="is-size-5">Доля Кассира({employeeDetail.accountantShare || 0}%)</span>
            <Table
                loading={transactions.isLoading}
                totalCount={transactions.data?.accountant.length || 0}
                items={transactions.data ? transactions.data.accountant : []}
                columns={{ customer: 'Студент', date: 'Дата', profit: 'Доход' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>{item.student.customer?.name}</td>
                        <td>{getDateTime(item.createdAt)}</td>
                        <td>{format(item.profit)} Сум</td>
                    </tr>
                )} />

            <br />

            <span className="is-size-5">Штрафы</span>

            <Table
                loading={transactions.isLoading}
                totalCount={transactions.data?.fine.length || 0}
                items={transactions.data ? transactions.data.fine : []}
                columns={{ cause: 'Причина', amount: 'Сумма', createdAt: 'Дата создания' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>{causes[item.cause]}</td>
                        <td>-{format(item.amount)} Сум</td>
                    </tr>
                )} />

            <br />

            <Button
                text="Закрыть"
                type="submit"
                onClick={onCancel}
                className="is-info" />
        </div>
    )
}
