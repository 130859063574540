import { GeneralType } from '../types'
import { GENERAL } from '../urls'
import { useFetchOne } from './request'
import { request } from '../utils/request'

export function useGeneralInfo() {
    return useFetchOne<GeneralType>(['general'], () => request({ url: GENERAL }), {
        staleTime: 10000000,
    })
}
