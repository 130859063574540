import Layout from '../../core/components/Layout'
import { useModal } from '../../core/hooks/modal'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import Button from '../../core/components/Button'
import TaskCreate from '../components/TaskCreate'
import { useQueryParams } from '../../core/hooks/queryString'
import { checkPermission as cp } from '../../users/utils/auth'
import { useTasks } from '../hooks/tasks'
import InfiniteTable from '../../core/components/InfiniteTable'
import TaskItem from '../components/TaskItem'
import TaskFilter from '../components/TaskFilter'

export default function Task() {
    const params = useQueryParams()
    const tasks = useTasks(params)

    const [showCreateModal, hideCreateModal] = useModal(
        <TaskCreate
            onSuccess={(task) => {
                tasks.addItem(task)
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    if (!cp('task.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className="columns mb-0">
                <div className="column">
                    <span className="is-size-4">Задачи</span>&nbsp; &nbsp;
                </div>

                <div className="column is-narrow">
                    {cp('task.add_task') ? (
                        <Button
                            onClick={showCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined" />
                    ) : null}
                </div>
            </div>

            <TaskFilter /><br />

            <InfiniteTable
                responsive
                query={tasks}
                columns={{
                    title: 'Заголовок',
                    assigned: 'Ответственный',
                    dueTime: 'Срок',
                    status: 'Статус',
                    actions: '',
                }}
                renderItem={(item) => (
                    <TaskItem
                        onDelete={tasks.deleteItem}
                        onUpdate={tasks.updateItem}
                        key={item.id}
                        item={item} />
                )}/>

            <br />
        </Layout>
    )
}
