import { usePostRequest } from '../../core/hooks/request.legacy'
import { EmployeeTimelineType, TimelineType } from '../types'
import { TIMELINE, TIMELINE_SYNC } from '../urls'
import { useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export type TimelineParams = {
    room: number | string
}

export function useTimeline({ room }: TimelineParams) {
    return useInfiniteFetch<EmployeeTimelineType>(
        ['timeline', { room }],
        ({ pageParam }) => request({ url: TIMELINE, params: { room, page: pageParam } }),
    )
}

export function useTimelineSync() {
    return usePostRequest<{ timelines: TimelineType[] }>({ url: TIMELINE_SYNC })
}
