export const colors = [
    '#f44336',
    '#8BC34A',
    '#E91E63',
    '#CDDC39',
    '#9C27B0',
    '#FFEB3B',
    '#673AB7',
    '#FFC107',
    '#3F51B5',
    '#FF9800',
    '#2196F3',
    '#FF5722',
    '#03A9F4',
    '#795548',
    '#00BCD4',
    '#9E9E9E',
    '#009688',
    '#607D8B',
    '#4CAF50',

    '#ef5350',
    '#9CCC65',
    '#EC407A',
    '#D4E157',
    '#AB47BC',
    '#FFEE58',
    '#7E57C2',
    '#FFCA28',
    '#5C6BC0',
    '#FFA726',
    '#42A5F5',
    '#FF7043',
    '#29B6F6',
    '#8D6E63',
    '#26C6DA',
    '#BDBDBD',
    '#26A69A',
    '#78909C',
    '#66BB6A',
]
