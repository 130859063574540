import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { SignInType, UserType } from '../types'
import { CHANGE_PASSWORD, CONFIRM,
    EMPLOYEE_INVITATION_SIGNUP,
    RESET_LINK,
    RESET_PASSWORD,
    SIGNIN,
    SIGNOUT,
    SIGNUP, USER_SETTINGS,
    USERS_LIST } from '../urls'

export function useUsers() {
    return useLoad<Pagination<UserType>>({ url: USERS_LIST })
}

export function useUserSettingsUpdate() {
    return usePutRequest({ url: USER_SETTINGS })
}

export function useSignup() {
    return usePostRequest({ url: SIGNUP, headers: {} })
}

export function useSignin() {
    return usePostRequest<SignInType>({ url: SIGNIN, headers: {} })
}

export function useSignout() {
    return useDeleteRequest({ url: SIGNOUT })
}

export function useResetLink() {
    return usePostRequest({ url: RESET_LINK, headers: {} })
}

export function useResetPassword() {
    return usePutRequest<SignInType>({ url: RESET_PASSWORD, headers: {} })
}

export function useChangePassword() {
    return usePutRequest({ url: CHANGE_PASSWORD })
}

export function useEmployeeInvitation(code: string) {
    const url = EMPLOYEE_INVITATION_SIGNUP.replace('{code}', code || '')
    return useLoad({ url, headers: {} })
}

export function useEmployeeSignup(code: string) {
    const url = EMPLOYEE_INVITATION_SIGNUP.replace('{code}', code || '')
    return usePostRequest<SignInType>({ url, headers: {} })
}

export function useConfirm(confirmationCode: string) {
    return useLoad<SignInType>({
        method: 'POST',
        url: CONFIRM.replace('{confirmationCode}', confirmationCode || ''),
        data: { confirmationCode },
        headers: {},
    })
}
