import { useDeleteRequest, useLoad, usePutRequest } from '../../core/hooks/request.legacy'
import { WalletType } from '../types'
import { WALLET_DETAIL, WALLET_LIST, WALLET_SIMPLE_LIST } from '../urls'
import { useFetchList, useFetchOne } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export function useWallets() {
    return useFetchList<WalletType>(['wallets'], () => request({ url: WALLET_LIST }))
}

export function useWalletsSimple() {
    return useLoad<WalletType[]>({ url: WALLET_SIMPLE_LIST })
}

export function useWallet(id: number | string) {
    return useFetchOne<WalletType>(['wallet', id], () => request({ url: WALLET_DETAIL.replace('{id}', String(id)) }))
}

export function useWalletCreate() {
    return usePutRequest({ url: WALLET_LIST })
}

export function useWalletUpdate(id: number) {
    return usePutRequest({ url: WALLET_DETAIL.replace('{id}', String(id)) })
}

export function useWalletDelete(id: number) {
    return useDeleteRequest({ url: WALLET_DETAIL.replace('{id}', String(id)) })
}

export function useWalletMainUpdate() {
    return useDeleteRequest()
}
