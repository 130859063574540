import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Layout from '../../core/components/Layout'
import StatisticsFinanceOutcome from '../components/StatisticsFinanceOutcome'
import DateRange from '../../core/components/DateRange'
import { useQueryParams } from '../../core/hooks/queryString'
import { DATE_FORMAT } from '../../core/utils/date'
import StatisticsTabs from '../components/StatisticsTabs'
import StatisticsFinanceAccountant from '../components/StatisticsFinanceAccountant'
import Card from '../../core/components/Card'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'

export default function StatisticsFinance() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const startDate = params.startDate as string || moment().startOf('month').format(DATE_FORMAT)
    const endDate = params.endDate as string || moment().endOf('month').format(DATE_FORMAT)

    if (!cp('finance.view_finance_statistics') || !cp('finance.view_outcome_statistics')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            <StatisticsTabs />
            <Card>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(dates) => {
                        navigate(`?${queryString.stringify({ ...params, ...dates })}`)
                    }} />
            </Card><br />

            <StatisticsFinanceAccountant /><br />

            <StatisticsFinanceOutcome
                startDate={startDate}
                endDate={endDate} />
        </Layout>
    )
}
