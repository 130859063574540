import Layout from '../../core/components/Layout'
import { useMessage } from '../../core/hooks/message'
import UserForm from '../components/UserForm'
import UserChangePassword, { FormType as PasswordFormType } from '../components/UserChangePassword'
import SettingsTabs from '../components/SettingsTabs'
import Card from '../../core/components/Card'
import { FormikHelpers } from 'formik/dist/types'
import { UserType } from '../../users/types'
import { useChangePassword, useUserSettingsUpdate } from '../../users/hooks/users'

export default function SettingsUser() {
    const updateUserSetting = useUserSettingsUpdate()
    const changePassword = useChangePassword()
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const [showMessage] = useMessage()

    async function onSubmit(data: UserType) {
        await updateUserSetting.mutate({ data })
        localStorage.setItem('user', JSON.stringify(data))
        showMessage('Информация изменен', 'is-success')
    }

    async function updatePassword(data: PasswordFormType, actions: FormikHelpers<PasswordFormType>) {
        if (data.newPassword !== data.confirmPassword) {
            showMessage('Введенные пароли не совпадают', 'is-danger')
            return
        }

        const { error } = await changePassword.mutate({ data })
        if (error) {
            showMessage('Введете старый пароль правилно.', 'is-danger')
        } else {
            showMessage('Пароль изменен', 'is-success')
        }
        actions.resetForm()
    }

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <UserForm user={user} onSubmit={onSubmit} />
                    </Card>
                </div>&nbsp;

                <div className="column">
                    <Card>
                        <UserChangePassword onSubmit={updatePassword} />
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
