import Layout from '../../core/components/Layout'
import AcademyTabs from '../components/AcademyTabs'
import { checkPermission as cp } from '../../users/utils/auth'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import InfiniteTable from '../../core/components/InfiniteTable'
import { useCashbacks } from '../hooks/cashback'
import CashbackItem from '../components/CashbackItem'

export default function Cashback() {
    const cashbacks = useCashbacks()
    
    if (!cp('finance.view_cashback')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <AcademyTabs />

            <div className="is-clearfix" />
            <br />

            <InfiniteTable
                responsive
                columns={{
                    student: 'Имя',
                    group: 'Группа',
                    phone: 'Телефон',
                    paidAt: 'Дата выплаты',
                    amount: 'Кешбек',
                    action: '',
                }}
                query={cashbacks}
                renderItem={(item) => (
                    <CashbackItem
                        onSuccess={cashbacks.refetch}
                        key={item.id}
                        item={item} />
                )} />

        </Layout>
    )
}
