import { useInfiniteFetch } from '../hooks/request'
import { Fragment, ReactNode } from 'react'
import Loader from './Loader'
import { ModelType } from '../types'

export type Props<Item extends ModelType> = {
    query: ReturnType<typeof useInfiniteFetch<Item>>
    renderItem: (item: Item, index: number) => ReactNode
    notMore?: ReactNode
    loader?: ReactNode
    disableObserver?: boolean
}

export default function InfiniteList<Item extends ModelType>({
    query,
    loader,
    notMore,
    renderItem,
    disableObserver = false,
}: Props<Item>) {
    const nothingToFetch = !query.isFetchingNextPage && !query.hasNextPage && !query.isLoading
    const loading = query.isFetchingNextPage || query.isLoading

    return (
        <Fragment>
            {query.data?.pages.map((page) => page.results.map(renderItem))}
            {!disableObserver && query.isError && query.observer}

            {loading && !loader && <Loader center large/>}
            {loading && loader}

            {nothingToFetch && !notMore && 'Все загрузили'}
            {nothingToFetch && notMore}
        </Fragment>
    )
}
