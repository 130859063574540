import { cn } from '../utils/classname'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { signOut } from '../../users/utils/auth'
import { useSignout } from '../../users/hooks/users'
import { useQueryClient } from 'react-query'

export default function AccountPanel() {
    const [showDropdown, setShowDropdown] = useState(false)
    const client = useQueryClient()
    const user = JSON.parse(localStorage.user)
    const navigate = useNavigate()
    const signout = useSignout()

    function logOut() {
        signout.mutate({}, true)
        signOut(navigate, client.invalidateQueries)
    }

    return (
        <div className={cn('dropdown', css(styles.dropdown), { 'is-active': showDropdown })}
            onMouseLeave={() => setShowDropdown(false)}>
            <div>
                <i className={cn('icon ion-md-contact is-size-1', css(styles.iconAccount))} />

                <div className={css(styles.account)} onClick={() => setShowDropdown(!showDropdown)}>
                    <p className={cn('pointer', css(styles.nameAccount))}>
                        {user.firstName || user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : user.email}
                    </p>

                    <i className="icon ion-md-arrow-dropdown" />
                </div>
            </div>

            <div className="dropdown-menu">
                <div className="dropdown-content">
                    <a onClick={() => navigate('/settings/user')} className="dropdown-item pointer">
                        <i className="icon ion-md-settings" />
                        Настройки
                    </a>

                    <a onClick={logOut} className="dropdown-item pointer">
                        <i className="icon ion-md-exit" />
                        Выйти
                    </a>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    iconAccount: {
        width: '2.5rem',
        transform: 'translateX(2.5rem)',
        margin: '0px auto 1rem auto',
    },
    dropdown: {
        marginBottom: '3.1rem',
    },
    nameAccount: {
        maxWidth: '9rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    account: {
        display: 'flex',
        flex: '1 1 0',
    },
})
