import { css, StyleSheet } from 'aphrodite'
import { cn } from '../utils/classname'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from '../hooks/queryString'

import Loader from './Loader'
import Pagination from './Pagination'
import { ReactElement } from 'react'

export type Props<Item> = {
    loading?: boolean
    totalCount?: number
    pageSize?: number
    showEmptyMessage?: boolean
    items?: Item[] | null
    columns?: object
    renderItem: (item: Item, index: number) => ReactElement
    onPageChange?: (page: number) => void
    activePage?: number
    className?: string
    responsive?: boolean
}

export default function Table<T>({
    loading = false,
    totalCount = 0,
    pageSize = 15,
    showEmptyMessage = true,
    items,
    columns,
    renderItem,
    onPageChange,
    activePage,
    className,
    responsive = false,
}: Props<T>) {
    const navigate = useNavigate()
    const params = useQueryParams()

    if (loading) {
        return (
            <div className={css(styles.space)}>
                <Loader large center />
            </div>
        )
    }

    if (isEmpty(items) && showEmptyMessage) {
        return (
            <div className={cn('is-size-3 has-text-centered has-text-grey', css(styles.space))}>
                Пусто
            </div>
        )
    }

    return (
        <div>
            <table className={cn(className || 'table is-striped is-fullwidth', { responsive })}>
                <thead>
                    {columns ? (
                        <tr>
                            {Object.entries(columns).map(([key, value]) => (
                                <th key={key}>{value}</th>
                            ))}
                        </tr>
                    ) : null}
                </thead>

                <tbody>
                    {items?.map(renderItem)}
                </tbody>
            </table>

            <Pagination
                page={activePage || params.page as string}
                onSelect={(page) => {
                    if (typeof onPageChange === 'function') {
                        onPageChange(page)
                        return
                    }
                    navigate(`?${queryString.stringify({ ...params, page })}`)
                }}
                count={totalCount}
                pageSize={pageSize} />
        </div>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
})
