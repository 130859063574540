import find from 'lodash/find'

export const stoppedReasons: { value: string, label: string }[] = [
    { value: 'travel', label: 'Уезжает' },
    { value: 'expensive', label: 'Дорого' },
    { value: 'no_time', label: 'Не успевает' },
    { value: 'dont_like', label: 'Не понравились наши курсы' },
    { value: 'other_courses', label: 'Выбрал другие курсы' },
    { value: 'leave_behind', label: 'Не успевает (Отстает)' },
    { value: 'other', label: 'Другая причина' },
]

export function getReason(value: string) {
    const reason = find(stoppedReasons, { value: value || 'other' })
    return reason?.label
}
